import React from "react"
import { parse, stringify } from "qs"
import { compose } from "ramda"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { Modal } from "partials/Modal"
import { CardBottom, CardBottomHeader, CardBottomFooter } from "partials/Card"
import { Anchor } from "partials/Anchor"
import { ConsignCloudLogo } from "partials/Image"
import { Notification } from "partials/Notification"
import { ExternalLink, KBLink } from "partials/ExternalLink"
import { BoundComponent } from "utils/react"
import { FieldInfo } from "modules/forms"
import { StringInput } from "modules/inputs/StringInput"
import State from "app/main/state"

const { Form, Field, SubmitButton } = State.loginStoreForm.components

const enhance = compose(
  withRouter,
  connect(
    (state, { location }) => {
      return {
        params: parse(location.search.replace("?", "")),
        loginFailed: State.loginStoreForm.select.errors(state)["*"],
        wrongVersion: State.loginStoreForm.select.errors(state).softwareVersion,
      }
    },
    {
      login: State.loginStoreForm.onSubmit,
    },
  ),
)

export default enhance(
  class LoginStore extends BoundComponent {
    componentDidMount() {
      const { params } = this.props

      if (params.demo) {
        this.props.login({
          email: "demo@consigncloud.com",
          password: "demouser",
        })
      }
    }
    render() {
      /* eslint line-comment-position: 0, no-inline-comments: 0 */

      const {
        location: { pathname },
        loginFailed,
        wrongVersion,
        params,
      } = this.props
      const { referral, shop, demo } = params

      // If they were trying to go somewhere specific, keep them going
      // after signup. This is necessary for Shopify installation workflow.
      const next = shop ? `${pathname}?shop=${shop}` : "/"
      const signupLink = `/signup?${stringify({ referral, next })}`
      const kbLink = "articles/3212864-integrating-consigncloud-with-shopify"

      if (demo) {
        return null
      }

      return (
        <Modal isOpen size="xl">
          <Form>
            <CardBottom>
              <CardBottomHeader className="p-8">
                <ConsignCloudLogo />
              </CardBottomHeader>
              {
                // This is lame, but it's required for Shopify apps
                referral === "shopify" && (
                  <Notification className="mb-4">
                    <p className="mb-4">
                      Welcome! Ready to get started using Shopify with ConsignCloud?
                    </p>
                    <ol className="list-decimal pl-4 mb-4">
                      <li>
                        Sign in below, or <Anchor onClick={signupLink}>create an account</Anchor>.
                      </li>
                      <li>
                        Your accounts will auto-link and you'll be asked to activate your
                        subscription.
                      </li>
                      <li>Review the settings page and make any changes.</li>
                      <li>
                        That's it! For more on how to use ConsignCloud with Shopify, check out our{" "}
                        <KBLink path={kbLink}>knowledge base</KBLink>.
                      </li>
                    </ol>
                    <p>
                      And, if you have any questions, don't hesitate to send us a message via chat!
                    </p>
                  </Notification>
                )
              }
              <div className="flex flex-col gap-2">
                <FieldInfo label="Email">
                  <Field
                    autoFocus
                    name="email"
                    Input={StringInput}
                    inputProps={{ className: "w-full" }}
                    placeholder="Enter your store's email"
                    className="col-span-2"
                  />
                </FieldInfo>
                <FieldInfo label="Password">
                  <Field
                    name="password"
                    Input={StringInput}
                    inputProps={{ type: "password", className: "w-full" }}
                    placeholder="Enter your password"
                    className="col-span-2"
                  />
                </FieldInfo>
              </div>
              <small className="block text-right py-2">
                <Anchor onClick="/password/reset/request">Don't remember your password?</Anchor>
              </small>
              {loginFailed && (
                <Notification theme="danger">
                  Invalid email or password, please try again.
                </Notification>
              )}
              {wrongVersion && (
                <Notification theme="danger">
                  You appear to be using a ConsignCloud account that has not been upgraded yet.
                  Please use{" "}
                  <ExternalLink href="https://legacy.consigncloud.com">
                    legacy.consigncloud.com
                  </ExternalLink>{" "}
                  to log into your account. If you would like to upgrade, please contact support.
                </Notification>
              )}
              <CardBottomFooter>
                <Anchor onClick={signupLink}>Create an Account</Anchor>
                <SubmitButton>Login</SubmitButton>
              </CardBottomFooter>
            </CardBottom>
          </Form>
        </Modal>
      )
    }
  },
)
