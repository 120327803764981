import React from "react"
import is from "ramda/src/is"
import { Icon } from "partials/Icon"
import { T, BoundComponent } from "utils/react"
import Label from "modules/forms/Label"

export default class FieldInfo extends BoundComponent {
  render() {
    const { helpText, label, required, icon, outline, children, className } = this.props
    const cols = this.props.cols || (helpText ? 2 : 3)

    return (
      <div className={`grid grid-cols-1 md:grid-cols-${cols} gap-x-4 lg:gap-x-8 ${className}`}>
        <div className="flex justify-start items-center gap-4 h-10">
          {is(String, icon) ? <Icon icon={icon} outline={outline} /> : icon}
          <Label className={helpText && "font-bold"}>
            {label} {required && <span className="opacity-50">(required)</span>}
          </Label>
        </div>
        {helpText && <div className={`col-span-${cols - 1}`} />}
        {helpText && <span className="text-gray-500 pb-2 md:pb-0 md:pl-8">{helpText}</span>}
        <div className={`flex flex-col gap-2 col-span-${cols - 1}`}>{children}</div>
      </div>
    )
  }
}

FieldInfo.propTypes = {
  icon: T.children,
  helpText: T.children,
  label: T.children.isRequired,
  children: T.children.isRequired,
  required: T.bool,
  cols: T.number,
}
