const NO_SYNC = "sync-instances/noSync"

// Updating aggressively cached data when it diverges across browser tabs:
// https://ponyfoo.com/articles/cross-tab-communication
// https://blog.bitsrc.io/4-ways-to-communicate-across-browser-tabs-in-realtime-e4f5f6cbedca

export const syncInstancesMiddleware = ({ actionTypes }) => {
  if (!window.BroadcastChannel) {
    return
  }

  const channel = new BroadcastChannel("consigncloud/sync-instances")

  return store => {
    // Listen for actions to sync and send them to the store
    channel.addEventListener("message", ({ data: action }) =>
      store.dispatch({ ...action, [NO_SYNC]: true }),
    )

    return next => action => {
      next(action)

      // If it's one of the actions we're watching, broadcast it.
      // Avoid infinite loop synchronization by checking NO_SYNC
      if (actionTypes.includes(action.type) && !action[NO_SYNC]) {
        try {
          channel.postMessage(action)
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
}
