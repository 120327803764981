import ellipsize from "ellipsize"
import { prop, fromPairs, values, identity } from "ramda"
import { toTitle, closure, switcher, ucFirst, toPascal } from "hurdak/src/core"
import Percent from "hurdak/src/percent"
import Currency from "hurdak/src/currency"
import { Dt } from "utils/dt"
import { VALUE_TYPE, INVENTORY_TYPE, ITEM_STATUS, ITEM_TERMS } from "modules/domain/types"

const fromValueList = (list, display = identity) =>
  fromPairs(list.map(value => [value, display(value)]))

const Display = {
  boolean: value => (value ? "Yes" : "No"),
  // Entities
  batch: prop("number"),
  contact(account, showCompany = true) {
    if (!account) {
      return ""
    }

    const { number, email, firstName, lastName, company } = account
    const name = [firstName, lastName].filter(identity).join(" ")

    const display =
      (company ? [company, name && `(${name})`].filter(identity).join(" ") : name) || email

    return display ? `${number}. ${display}` : number
  },
  category(category) {
    if (!category) {
      return "Uncategorized"
    }

    return category.name
  },
  shelf(shelf) {
    if (!shelf) {
      return ""
    }

    return shelf.name
  },
  recurringFee(recurringFee) {
    if (!recurringFee) {
      return ""
    }

    const { name } = recurringFee

    return name
  },
  till(till) {
    if (!till) {
      return ""
    }

    return till.name
  },
  tillReport(tillReport) {
    if (!tillReport) {
      return ""
    }

    const { created, till } = tillReport

    return `${Dt.parse(created).displayDate()} @ ${till.name}`
  },
  emailTemplate: ({ name }) => toTitle(name),
  event: ({ name }) => toTitle(name),
  // Hacky way to avoid having to pass config all over the place
  _itemTitleTemplate: null,
  _customFieldNames: null,
  getTitleFieldNames: () =>
    Display._customFieldNames.concat([
      "details",
      "description",
      "brand",
      "color",
      "size",
      "category",
    ]),
  item: item => {
    if (!item) {
      return ""
    }

    const template = Display._itemTitleTemplate.replace(/\{\{([^}]*)\}\}/g, function (match, p1) {
      return "{{" + toPascal(p1.toLowerCase()) + "}}"
    })

    let display =
      item.title ||
      Display.getTitleFieldNames()
        .reduce(
          (t, k) => t.replace(`{{${ucFirst(k)}}}`, prop("name", item[k]) || item[k] || ""),
          template,
        )
        .replace(/\{\{.*\}\}/g, "")
        .replace(/[ ,]+,/g, ",")
        .replace(/[ ,]+$/g, "")
        .replace(/^[ ,]+/g, "")
        .trim()

    if (item.sku) {
      display = display ? `${item.sku}. ${display}` : item.sku
    }

    return display
  },
  note: ({ body }) => ellipsize(body, 30),
  giftCard: ({ barcode }) => `Gift Card #${barcode}`,
  store: prop("name"),
  transaction: sale => {
    if (!sale) {
      return ""
    }

    const { total, number } = sale
    const term = total >= 0 ? "Sale" : "Refund"

    return number ? `${term} #${number}` : `${term} for ${Currency.format(total)}`
  },
  tag: prop("name"),
  location: prop("name"),
  user: user => (user ? user.username : "No User"),
  vendorUser: user => (user ? user.username : "No User"),
  inventoryRule: ({ surcharge, discount, tax, type, priceMin, priceMax }) => {
    const entityType = closure(() => {
      /* eslint curly: 0 */

      if (surcharge) return "surcharge"
      if (discount) return "discount"
      if (tax) return "tax"
    })

    const conditionDisplay = switcher(type, {
      always: "to all items",
      category: "to items with the given category",
      price: `when price is between ${Currency.format(priceMin)} and ${Currency.format(priceMax)}`,
    })

    return `Add ${entityType} ${conditionDisplay}`
  },
  // Basic type lists
  inventoryTypes: fromValueList(values(INVENTORY_TYPE), toTitle),
  itemStatus: fromValueList(values(ITEM_STATUS), toTitle),
  terms: fromValueList(values(ITEM_TERMS), toTitle),
  discount: prop("name"),
  surcharge: prop("name"),
  tax: prop("name"),
  typedValue: ({ valueType, value }) =>
    valueType === VALUE_TYPE.PERCENT
      ? Percent.format(value, { precision: 6 })
      : Currency.format(value),
  label: prop("name"),
  task: ({ type }) => toTitle(type),
}

export default Display
