/* eslint new-cap: 0 */

import filter from "ramda/src/filter"
import merge from "ramda/src/merge"
import prop from "ramda/src/prop"
import contains from "ramda/src/contains"
import identity from "ramda/src/identity"
import { modifyKeysRecursive } from "hurdak/src/core"
import { toSnake } from "utils/misc"
import gtm from "utils/gtm.js"

export const intercomMiddleware =
  ({ appId, source, syncOn, selectPayload }) =>
  store => {
    let lastPath = window.location.pathname

    const notifyIntercom = mode => {
      if (!appId) {
        return
      }

      if (mode === "shutdown") {
        document.cookie = `intercom-session-${appId}=`

        window.Intercom?.(mode)
        window.profitwell("stop")

        return
      }

      const intercomData = merge(selectPayload(store.getState()), { appId, source })

      // Send it off, removing empty values
      window.Intercom?.(mode, filter(identity, modifyKeysRecursive(toSnake, intercomData)))

      // Tell profitwell and google tag manager too
      const { customer, email } = prop("company", intercomData) || {}

      if (customer) {
        window.profitwell("start", { user_id: customer })
      }

      gtm.push({ store_email: email })
    }

    // Make sure to start with a fresh session on page reload
    document.cookie = `intercom-session-${appId}=`

    notifyIntercom("boot")

    return next => action => {
      const result = next(action)

      if (window.Intercom) {
        // Reboot when anything changes
        if (contains(action.type, syncOn)) {
          notifyIntercom("shutdown")

          setTimeout(() => notifyIntercom("update"), 300)
        }

        // Track location changes
        const newPath = window.location.pathname.replace(/[-0-9a-f]{36}/, ":id")
        if (newPath !== lastPath) {
          if (appId) {
            window.Intercom?.("trackEvent", `${newPath} visited`)
          }

          lastPath = newPath
        }
      }

      return result
    }
  }
