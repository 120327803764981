import { connect } from "react-redux"
import { uniq, prop, propEq, identity } from "ramda"
import { closure } from "hurdak/src/core"
import { fuzzy } from "utils/misc"
import { getClient } from "modules/client"
import { mapOptions } from "modules/inputs/SelectInput"
import { FancySelectInput } from "modules/inputs/FancySelectInput"

const enhanceCountryInput = connect(undefined, {
  loadOptions: closure(() => {
    let data = []
    let search = null

    return input => async dispatch => {
      if (!search) {
        /* eslint prefer-destructuring: 0 */
        const res = await dispatch(getClient().post("/country/search/", { body: {} }))

        data = res.data
        search = fuzzy(data, { keys: ["countryName"] })
      }

      // Return exact matches on country code first
      return mapOptions(
        uniq(
          data
            .filter(propEq("countryCode", input.toUpperCase()))
            .concat(search(input))
            .map(prop("countryName")),
        ),
        identity,
        identity,
      )
    }
  }),
})

export const CountryInput = enhanceCountryInput(FancySelectInput)
