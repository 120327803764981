import "resources/css/components/image.css"

import React from "react"
import cx from "classnames"

export const ImageCircle = ({ src, className }) => (
  <div className={cx("image rounded-full", className)} style={{ backgroundImage: `url(${src})` }} />
)

export const ConsignCloudLogo = () => (
  <div className="flex justify-center items-center w-full">
    <img className="h-10" src="/public/wordmark-dark.png" />
  </div>
)
