import "resources/css/components/spaced-group.css"

import React from "react"
import cx from "classnames"

export const Content = ({ className, ...props }) => (
  <div className={cx(className, "w-full flex flex-col gap-4")} {...props} />
)

export const FrontAndCenter = ({ className, ...props }) => (
  <Content className={cx(className, "items-center max-w-xl my-20 mx-auto")} {...props} />
)

export const SpacedGroup = ({ children, direction = "left", ...props }) => (
  <div className="flow-root spaced-group-wrapper" {...props}>
    <div className={`spaced-group spaced-group-${direction}`}>
      {React.Children.map(children, child => (
        <div className="spaced-group-item">{child}</div>
      ))}
    </div>
  </div>
)

// Deprecated, use Content instead
export const Row = ({ className, ...props }) => <div className={cx(className, "pb-4")} {...props} />
