import React from "react"
import invertObj from "ramda/src/invertObj"
import prop from "ramda/src/prop"
import curry from "ramda/src/curry"
import { ExternalLink } from "partials/ExternalLink"

export * from "modules/domain/types"

export const adjTypeMap = { surcharge: "surcharges", discount: "discounts", tax: "taxes" }
export const adjKeyMap = invertObj(adjTypeMap)

export const locationMatches = curry(
  (user, thing) => !user.location || user.location === prop("location", thing),
)
export const payoutIsCheck = payoutType => payoutType.name?.toLowerCase().trim() === "check"

/* eslint max-len: 0 */

export const privacyUrl = "https://www.iubenda.com/privacy-policy/16500065"
export const privacyLink = <ExternalLink href={privacyUrl}>Privacy Policy</ExternalLink>

export const termsUrl =
  "https://docs.google.com/document/u/1/d/e/2PACX-1vTFZO4ZRpACII4blT4mUTf24tFwwVLQfdRYsvx59JaJhLwhNkZ7SUrzwr-b7shH43LleXP5W0-xSs8o/pub"
export const termsLink = <ExternalLink href={termsUrl}>Terms of Service</ExternalLink>
