import React from "react"
import cx from "classnames"
import omit from "ramda/src/omit"
import prop from "ramda/src/prop"
import is from "ramda/src/is"
import { noop } from "hurdak/src/core"
import { T, BoundComponent, wrapperDisplayName } from "utils/react"

export const decorate = (Input, config = {}) => {
  class DecoratedInput extends BoundComponent {
    onChange(value, ...args) {
      /* eslint prefer-destructuring: 0 */

      if (config.dom) {
        value = value.target.value
      }

      if (config.parse) {
        value = config.parse(value)
      }

      this.props.onChange(value, ...args)
    }
    isDisabled() {
      return this.props.loading || this.props.disabled
    }
    getValue() {
      let { value } = this.props

      if (value === null && config.defaultValue !== undefined) {
        value = config.defaultValue
      }

      if (config.format) {
        value = config.format(value)
      }

      return value
    }
    getAddonBefore() {
      return (
        this.props.addonBefore ||
        config.addonBefore ||
        (config.getAddonBefore ? config.getAddonBefore() : null)
      )
    }
    getAddonAfter() {
      return this.props.addonAfter || config.addonAfter
    }
    render() {
      const { flatLeft, flatRight, isShort, ...props } = omit(
        ["loading", "addonAfter", "addonBefore"],
        this.props,
      )

      const addonBefore = this.getAddonBefore()
      const addonAfter = this.getAddonAfter()

      const className = cx("input-with-addons", {
        "input-short": isShort,
        "input-with-addon-before": addonBefore,
        "input-with-addon-before-roomy": is(String, addonBefore) && addonBefore.length > 1,
        "input-with-addon-after": addonAfter,
        "input-with-addon-flat-left": flatLeft,
        "input-with-addon-flat-right": flatRight,
      })

      const addonAfterClassName = cx("addon-after", {
        "pointer-events-none": config.addonAfter === addonAfter,
        "-ml-8": !is(String, addonAfter) || prop("length", addonAfter) === 1,
        "-ml-12": prop("length", addonAfter) === 2,
        "-ml-18": prop("length", addonAfter) === 3,
      })

      return (
        <div className={className}>
          <Input
            {...props}
            value={this.getValue()}
            disabled={this.isDisabled()}
            onChange={this.onChange}
          />
          {addonBefore && (
            <span className="addon-before">
              <span>{addonBefore}</span>
            </span>
          )}
          {addonAfter && (
            <span className={addonAfterClassName}>
              <span>{addonAfter}</span>
            </span>
          )}
        </div>
      )
    }
  }

  DecoratedInput.propTypes = {
    onChange: T.func,
    onFocus: T.func,
    onBlur: T.func,
    value: T.any,
    disabled: T.bool,
    loading: T.bool,
    addonBefore: T.children,
    addonAfter: T.children,
    flatLeft: T.bool,
    flatRight: T.bool,
    size: T.string,
  }

  DecoratedInput.defaultProps = {
    value: null,
    onChange: noop,
    onFocus: noop,
    onBlur: noop,
  }

  DecoratedInput.displayName = wrapperDisplayName("Decorated", Input)

  return DecoratedInput
}
