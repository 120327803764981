import React from "react"
import cx from "classnames"
import { toTitle } from "hurdak/src/core"
import identity from "ramda/src/identity"
import pluck from "ramda/src/pluck"
import omit from "ramda/src/omit"
import contains from "ramda/src/contains"
import is from "ramda/src/is"
import { Icon } from "partials/Icon"
import { T, BoundComponent } from "utils/react"
import { decorate } from "modules/inputs/utils"

class _SelectInput extends BoundComponent {
  onChange(value) {
    if (!this.props.disabled) {
      this.props.onChange(value)
    }
  }
  // we want to make sure we notify the caller of selected values, which
  // includes initial load when we aren't passed a value, or when options
  // change
  componentDidMount() {
    const { value } = this.props

    if (this.getValue() !== value) {
      this.onChange(this.getValue())
    }
  }
  componentDidUpdate(oldProps) {
    const { value, options } = this.props

    if (options !== oldProps.options && value !== oldProps.value && value !== this.getValue()) {
      this.onChange(this.getValue())
    }
  }
  getValue() {
    const { value, options } = this.props

    if (options.length < 1) {
      return
    }

    return contains(value, pluck("value", options)) ? value : options[0].value
  }
  onInputChange(evt) {
    this.onChange(evt.target.value ? JSON.parse(evt.target.value) : null)
  }
  render() {
    const { inputRef, options, className, ...props } = this.props

    return (
      <span className={cx(className, "max-w-full")}>
        <select
          {...omit(["addonAfter", "addonBefore", "dispatch"], props)}
          ref={inputRef}
          className={cx(className, "select-input input-border")}
          onChange={this.onInputChange}
          value={JSON.stringify(this.getValue())}
        >
          {options.map(({ label, value, ...option }) => {
            // Dump to json so we can support non-string values
            const stringValue = JSON.stringify(value)

            return (
              <option {...option} key={stringValue} value={stringValue}>
                {label}
              </option>
            )
          })}
        </select>
      </span>
    )
  }
}

_SelectInput.propTypes = {
  name: T.string,
  value: T.any,
  onChange: T.func.isRequired,
  options: T.arrayOf(
    T.shape({
      value: T.any,
      label: T.string.isRequired,
    }),
  ).isRequired,
  inputRef: T.func,
}

export const mapOptions = (data, getValue = identity, getLabel = toTitle) =>
  data.map(item => ({ value: getValue(item), label: getLabel(item) }))

export const mapOptions2 = (
  xs,
  {
    emptyOptionLabel,
    getValue = x => (is(Object, x) ? x.id : x),
    getLabel = x => (is(Object, x) ? x.name : x),
  } = {},
) => {
  const options = xs.map(x => ({ value: getValue(x), label: getLabel(x) }))

  return emptyOptionLabel ? [{ value: null, label: emptyOptionLabel }].concat(options) : options
}

export const optionsMapper2 = opts => xs => mapOptions2(xs, opts)

export const SelectInput = decorate(_SelectInput, {
  addonAfter: <Icon icon="caret-down" tabIndex={-1} />,
})
