import "resources/css/components/spinner.css"

import React from "react"
import cx from "classnames"
import { T } from "utils/react"

export const Spinner = ({ theme, children }) => (
  <div>
    <span className={cx("spinner", `spinner-${theme}`)} />
    {children}
  </div>
)

Spinner.defaultProps = {
  theme: "dark",
}

Spinner.propTypes = {
  theme: T.oneOf(["dark", "light"]),
  children: T.children,
}

export const AbsSpinner = ({ className, position = "absolute", ...props }) => (
  <div className={cx(className, position, "flex inset-0 justify-center items-center")}>
    <Spinner {...props} />
  </div>
)
