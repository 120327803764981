import React from "react"
import { Anchor } from "partials/Anchor"
import { Modal } from "partials/Modal"
import { CardBottom, CardBottomHeader, CardBottomFooter } from "partials/Card"
import { ConsignCloudLogo } from "partials/Image"
import { BoundComponent } from "utils/react"
import { Label } from "modules/forms"
import { StringInput } from "modules/inputs/StringInput"
import State from "app/main/state"

const { Form, Field, SubmitButton } = State.requestPasswordResetForm.components

export default class RequestPasswordReset extends BoundComponent {
  render() {
    return (
      <Modal isOpen size="xl">
        <Form persistOnUnmount>
          <CardBottom>
            <CardBottomHeader className="p-8">
              <ConsignCloudLogo />
            </CardBottomHeader>
            <div className="grid grid-cols-3 gap-2">
              <Label>Email</Label>
              <Field
                autoFocus
                name="email"
                Input={StringInput}
                inputProps={{ className: "w-full" }}
                placeholder="Enter your store's email"
                className="col-span-2"
              />
            </div>
            <CardBottomFooter>
              <Anchor onClick="/">Back to Login</Anchor>
              <SubmitButton>Reset Password</SubmitButton>
            </CardBottomFooter>
          </CardBottom>
        </Form>
      </Modal>
    )
  }
}
