import getPath from "ramda/src/path"
import pluck from "ramda/src/pluck"
import Shopify from "utils/shopify"
import history from "utils/history"
import Currency from "hurdak/src/currency"
import Display from "modules/domain/display"
import { setRolesGetter, setIsVendorGetter } from "modules/permissions"
import { buildModel } from "modules/domain/model"
import Db from "modules/db/state"
import Core from "app/core/state"

export const coreHackMiddleware = ({ dispatch, getState }) => {
  window.addEventListener("online", () => dispatch(Core.setState({ online: true })))
  window.addEventListener("offline", () => dispatch(Core.setState({ online: false })))

  history.listen(location => Core.setState({ location }))

  setRolesGetter(() => {
    const user = Core.select.user(getState())

    return user ? [user.role] : []
  })

  setIsVendorGetter(() => {
    const user = Core.select.user(getState())

    return user ? user.isVendor : null
  })

  return next => action => {
    if (action.type === "core/setSynced") {
      const { store, db, model } = getPath(["payload", "whoami"], action) || {}

      if (store) {
        Shopify.setShop(store.shopifyShop)
        Currency.setSymbol(store.currencySymbol)
        Display._itemTitleTemplate = store.itemTitleTemplate
      }

      if (model) {
        buildModel(model)
      }

      if (db) {
        dispatch(Db.setState(db))
        Display._customFieldNames = pluck("attrName", Core.select.itemFields(getState()))
      }
    }

    next(action)
  }
}
