import { connect } from "react-redux"
import { Button } from "partials/Button"
import { Anchor } from "partials/Anchor"
import { ConfirmModal } from "partials/Modal"
import { bindleTemplate } from "modules/bindles/template"

export default bindleTemplate(({ opts }) => ({
  initialState: {
    params: null,
    isOpen: false,
    isLoading: false,
  },
  reducers: {
    open: (state, params) => ({ ...state, isOpen: true, params }),
    close: state => ({ ...state, isOpen: false, params: null }),
  },
  createActions: ({ select, actions }) => ({
    confirm:
      (overrides = {}) =>
      async (dispatch, getState) => {
        // Only show loading if confirmation takes time
        let asyncDone = false

        setTimeout(() => asyncDone || dispatch(actions.setState({ isLoading: true })), 10)

        let params = select.params(getState())

        if (overrides.params) {
          params = { ...params, ...overrides.params }
        }

        try {
          await dispatch(opts.confirm(params))

          asyncDone = true
          dispatch(actions.setState({ isLoading: false }))
          dispatch(actions.close())
        } catch (err) {
          dispatch(actions.setState({ isLoading: false }))

          if (opts.onError) {
            await dispatch(opts.onError(err))
          } else {
            throw err
          }
        }
      },
  }),
  createComponents: ({ ns, select, actions }) => {
    const enhanceClickable = connect(undefined, { onClick: actions.open })

    const enhanceModal = connect(select.root, {
      onClose: () => async dispatch => {
        if (opts.onClose) {
          await dispatch(opts.onClose())
        }

        dispatch(actions.close())
      },
      confirm: () => actions.confirm(),
    })

    return {
      ConfirmButton: enhanceClickable(Button),
      ConfirmAnchor: enhanceClickable(Anchor),
      ConfirmModal: enhanceModal(ConfirmModal),
    }
  },
}))
