/* eslint no-undef: 0 */

// We use a cdn to speed up asset delivery and cache old chunks
// We're only using it for assets because switching DNS to the cdn is a huge pain
// https://medium.com/pixel-and-ink/serving-webpack-assets-from-a-different-domain-c649d0925d7a
__webpack_public_path__ =
  process.env.USE_CDN === "true" ? "https://cdn.consigncloud.com/" : window.location.origin + "/"

import "@fortawesome/fontawesome-free/css/all.css"
import "spinkit/css/spinners/7-three-bounce.css"
import "animate.css/animate.min.css"
import "tippy.js/dist/tippy.css"
import "resources/css/tailwind.css"
import "resources/css/style.css"

import React from "react"
import { createRoot } from "react-dom/client"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import { Router } from "react-router-dom"
import { Provider } from "react-redux"
import history from "utils/history"
import { makeStore, getShellReducers } from "app/state"
import "app/core/client"
import Shell from "app/main/Shell"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

posthog.init(process.env.POSTHOG_PROJECT_API_KEY, {
  api_host: "https://app.posthog.com",
})

Bugsnag.start({
  apiKey: "c9535e234cbe361bd2317b6ed826fc99",
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

const container = document.getElementById("root")
const root = createRoot(container)

const queryClient = new QueryClient()

root.render(
  <ErrorBoundary>
    <PostHogProvider client={posthog}>
      <QueryClientProvider client={queryClient}>
        <Provider store={makeStore(getShellReducers())}>
          <Router history={history}>
            <Shell />
          </Router>
        </Provider>
      </QueryClientProvider>
    </PostHogProvider>
  </ErrorBoundary>,
)
