import { ROLES, PERMS } from "modules/permissions/constants"

let getRoles = () => []

export const setRolesGetter = getter => {
  getRoles = getter
}

let _isVendor = () => null

export const setIsVendorGetter = getter => {
  _isVendor = getter
}

export const isVendor = () => _isVendor()

export const hasPerm = action => {
  const roles = getRoles()

  let permitted = roles.includes(ROLES.ADMIN)

  permitted =
    permitted ||
    (roles.includes(ROLES.CLERK) && [PERMS.ADD_TRANSACTION, PERMS.TILL_DETAIL].includes(action))

  permitted =
    permitted ||
    (roles.includes(ROLES.EMPLOYEE) &&
      [
        PERMS.ADD_TRANSACTION,
        PERMS.ADD_ITEM,
        PERMS.ADD_CONTACT,
        PERMS.ITEM_LIST,
        PERMS.ACCOUNT_LIST,
        PERMS.TRANSACTION_LIST,
        PERMS.GIFT_CARD_LIST,
        PERMS.ITEM_DETAIL,
        PERMS.ACCOUNT_DETAIL,
        PERMS.TRANSACTION_DETAIL,
        PERMS.GIFT_CARD_DETAIL,
        PERMS.TILL_DETAIL,
        PERMS.TABLE_ACTIONS,
        // Hack for Ruth Meteer
        PERMS.REPORTS_SALES,
      ].includes(action))

  permitted =
    permitted ||
    (roles.includes(ROLES.ANALYST) &&
      [
        PERMS.DASHBOARD,
        PERMS.REPORTS_NAVIGATION,
        PERMS.REPORTS_SALES,
        PERMS.ITEM_LIST,
        PERMS.ACCOUNT_LIST,
        PERMS.TRANSACTION_LIST,
        PERMS.GIFT_CARD_LIST,
      ].includes(action))

  return permitted
}
