import React from "react"
import { connect } from "react-redux"
import { parse } from "qs"
import { Modal } from "partials/Modal"
import { CardBottom } from "partials/Card"
import { ConsignCloudLogo } from "partials/Image"
import { BoundComponent } from "utils/react"
import Api from "modules/domain/api"
import history from "utils/history"

const enhance = connect(undefined, {
  verifyEmail: values => Api.store.post("email/verify", values),
})

export default enhance(
  class VerifyEmail extends BoundComponent {
    state = {
      loading: true,
      error: false,
    }
    async componentDidMount() {
      const { email, code } = parse(history.location.search.slice(1))

      try {
        await this.props.verifyEmail({ email, code })
      } catch (error) {
        this.setState({ error: true })
      }

      this.setState({ loading: false })
    }
    render() {
      const { loading, error } = this.state

      return (
        <Modal isOpen size="2xl" align="center">
          <CardBottom>
            <div className="bg-gray-100 p-8 border-b border-gray-300 border-solid -m-4">
              <ConsignCloudLogo />
            </div>
            <p className="pt-8 h-32 flex justify-center items-center">
              {loading && "Verifying your email address..."}
              {error && "Email verification failed. Please try again."}
              {!loading &&
                !error &&
                "Your email address has been verified! You can now close this window."}
            </p>
          </CardBottom>
        </Modal>
      )
    }
  },
)
