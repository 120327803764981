import React from "react"
import { stringify } from "qs"
import { T, BoundComponent } from "utils/react"

export class ExternalLink extends BoundComponent {
  onClick(evt) {
    evt.stopPropagation()

    if (this.props.onClick) {
      this.props.onClick(evt)
    }
  }
  render() {
    const { children, sameTab, ...props } = this.props
    const tabProps = sameTab ? {} : { target: "_blank", rel: "noopener noreferrer" }

    return (
      <a {...props} {...tabProps} onClick={this.onClick}>
        {children}
      </a>
    )
  }
}

ExternalLink.propTypes = {
  href: T.string.isRequired,
  children: T.children.isRequired,
}

export const AmazonLink = ({ children, product, linkId }) => {
  const baseUrl = `https://www.amazon.com/gp/product/${product}/ref=as_li_tl`
  const params = stringify({
    ie: "UTF8",
    camp: 1789,
    creative: 9325,
    creativeASIN: product,
    linkCode: "as2",
    tag: "consignclou05-20",
    linkId,
  })

  return <ExternalLink href={`${baseUrl}?${params}`}>{children}</ExternalLink>
}

export const createAmazonLink = (product, linkId) => props => (
  <AmazonLink product={product} linkId={linkId} {...props} />
)

export const KBLink = ({ path = "", ...props }) => (
  <ExternalLink href={`http://help.consigncloud.com/en/${path}`} {...props} />
)

export const MailtoLink = ({ email, ...props }) => (
  <ExternalLink href={`mailto:${email}`} {...props} />
)
