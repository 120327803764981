import React from "react"
import cx from "classnames"
import { BoundComponent } from "utils/react"

export default class Label extends BoundComponent {
  render() {
    const { className, ...props } = this.props

    return <label className={cx(className, "input-label")} {...props} />
  }
}
