import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import autoBind from "react-autobind"
import is from "ramda/src/is"
import isEmpty from "ramda/src/isEmpty"
import merge from "ramda/src/merge"
import reject from "ramda/src/reject"

export const T = {
  ...PropTypes,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
}

export class BoundComponent extends React.PureComponent {
  constructor(props) {
    super(props)

    autoBind(this)
  }
}

export const childIsType = (typeString, element) => {
  if (!element) {
    return false
  }

  const name = getDisplayName(element.type)

  // Handle wrapped components
  return typeString === name || `(${name})`.includes(typeString)
}

export const getDisplayName = InnerComponent => {
  if (!InnerComponent) {
    return "NoComponent"
  }

  // Handle react classes, functions, and strings
  const displayName = InnerComponent.displayName || InnerComponent.name || InnerComponent

  return is(String, displayName) && displayName ? displayName : "Unknown"
}

export const wrapperDisplayName = (displayName, InnerComponent) => {
  const componentName = getDisplayName(InnerComponent)
    .replace(/\w+\(/g, "")
    .replace(/\)/g, "")
    .replace("_", "")

  return `${displayName}(${componentName})`
}

export const withProps = (InnerComponent, props) => {
  if (!props || isEmpty(props)) {
    return InnerComponent
  }

  // Use a real component, so it can take refs and such
  class WithProps extends BoundComponent {
    render() {
      const currentProps = merge(
        props,
        reject(x => x === undefined, this.props),
      )

      return <InnerComponent {...currentProps} />
    }
  }

  WithProps.displayName = wrapperDisplayName("WithProps", InnerComponent)

  return WithProps
}

export const cleanRoute =
  (...args) =>
  InnerComponent => {
    // This is like withKey in that it forces the component to re-render
    // when our route url changes. In addition, it requires initialize
    // and resetModule props in order to manage route lifecycle.
    class CleanRoute extends BoundComponent {
      constructor(props) {
        super(props)

        this.props.initialize(this.props.match.params)
      }
      componentDidUpdate(oldProps) {
        const { url, params } = this.props.match

        if (url !== oldProps.match.url) {
          this.props.resetModule()
          this.props.initialize(params)
        }
      }
      componentWillUnmount() {
        this.props.resetModule()
      }
      render() {
        return <InnerComponent {...this.props} />
      }
    }

    CleanRoute.displayName = wrapperDisplayName("CleanRoute", InnerComponent)

    CleanRoute.defaultProps = {
      initialize: x => null,
    }

    CleanRoute.propTypes = {
      initialize: T.func.isRequired,
      resetModule: T.func.isRequired,
      match: T.object.isRequired,
    }

    return connect(...args)(CleanRoute)
  }

export const highlightSearchTerm = (text, term) => {
  const template = `<span class="font-bold underline">${term}</span>`
  const div = document.createElement("div")

  // This escapes the html
  div.innerText = text

  const html = div.innerHTML.replace(term, template)

  return <span dangerouslySetInnerHTML={{ __html: html }} />
}
