import React from "react"
import ReactDOM from "react-dom"
import cx from "classnames"
import { waitFor, switcher } from "hurdak/src/core"
import { stopPropagation } from "utils/html"
import { Icon } from "partials/Icon"
import { Button } from "partials/Button"
import { CardTop, CardBottom, CardBottomFooter } from "partials/Card"
import { Animate } from "partials/Animate"
import { Escapable } from "partials/Escapable"
import { T, BoundComponent } from "utils/react"

export class Modal extends BoundComponent {
  constructor(props) {
    super(props)

    this.state = { showIcons: false }
  }
  componentDidMount() {
    this.manageExternalState(this.props.isOpen)
    this.manageShowIcons(this.props.isOpen)
  }
  componentDidUpdate(prevProps) {
    // Only do this if it's changed; we may have multiple modals on the page
    if (this.props.isOpen === prevProps.isOpen) {
      return
    }

    this.manageExternalState(this.props.isOpen)
    this.manageShowIcons(this.props.isOpen)
  }
  componentWillUnmount() {
    this._unmounted = true

    // This is a hack to prevent inconsistent state.
    // https://trello.com/c/a0V0XXb0
    if (this.props.isOpen) {
      this.manageExternalState(false)
      this.onClose()
    }
  }
  manageExternalState(isOpen) {
    // Toggle a class for the body to prevent double scrolling
    // See http://stackoverflow.com/a/31063201/1467342 for maybe a better approach
    if (isOpen) {
      document.body.className = "overflow-hidden"
    } else {
      document.body.className = ""
    }
  }
  manageShowIcons(isOpen) {
    // Wait until there's content to show the close icon, since it shows up in a weird
    // place otherwise
    waitFor(() => {
      if (this._unmounted) {
        return true
      }

      const node = ReactDOM.findDOMNode(this)

      if (!node) {
        return true
      }

      if (node.innerText.trim()) {
        this.setState({ showIcons: this.props.isOpen })

        return true
      }
    })
  }
  onClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }
  escape(event) {
    if (this.props.isOpen) {
      this.onClose()
    }
  }
  renderModal() {
    const { showIcons } = this.state
    const { isOpen, onClose, onBack, children, className, size } = this.props
    const _className = cx(
      className,
      "z-modal bg-gray-700 bg-opacity-75 fixed inset-0 overflow-auto flex flex-col justify-center",
      { "cursor-pointer": onClose },
    )

    return (
      <Escapable escape={this.escape} ignoreClicksOutside>
        <Animate
          visible={isOpen}
          onClick={this.onClose}
          className={cx(_className, "cursor-default")}
        >
          <div
            onClick={this.onClose}
            className={`relative cursor-default m-auto max-h-screen w-full sm:max-w-${size}`}
            style={{
              minHeight: switcher(size, { xl: "60vh", "2xl": "60vh", default: "100vh" }),
              cursor: "default",
            }}
          >
            <div
              onKeyDown={evt => evt.key === "Escape" && this.onClose()}
              className="sm:m-4"
              onClick={stopPropagation}
            >
              {isOpen && children}
            </div>
            {onBack && showIcons && (
              <div className="absolute top-0 left-0 m-8 text-white" onClick={onBack}>
                <Icon icon="arrow-left" size="large" />
              </div>
            )}
            {onClose && showIcons && (
              <div className="absolute top-0 right-0 m-8 text-white" onClick={this.onClose}>
                <Icon icon="times" size="large" />
              </div>
            )}
          </div>
        </Animate>
      </Escapable>
    )
  }
  render() {
    return ReactDOM.createPortal(this.renderModal(), document.body)
  }
}

Modal.defaultProps = {
  size: "2xl",
}

Modal.propTypes = {
  isOpen: T.bool.isRequired,
  onClose: T.func,
  children: T.children,
  className: T.string,
  size: T.oneOf(["xl", "2xl", "4xl", "6xl", "7xl"]).isRequired,
}

export const SimpleModal = ({
  onClose,
  closeLabel,
  header,
  footer,
  children,
  onBack,
  ...props
}) => (
  <Modal onClose={onClose} {...props}>
    <CardTop>
      <h1>{header}</h1>
    </CardTop>
    <CardBottom>
      {children}
      {(onClose || footer) && (
        <CardBottomFooter>
          {onClose && <Button onClick={onClose}>{closeLabel}</Button>}
          {footer}
        </CardBottomFooter>
      )}
    </CardBottom>
  </Modal>
)

SimpleModal.defaultProps = {
  closeLabel: "Cancel",
}

SimpleModal.propTypes = {
  header: T.children.isRequired,
  footer: T.children,
  onClose: T.func,
  closeLabel: T.string,
  children: T.children.isRequired,
}

export const ConfirmModal = ({ isLoading, buttonTheme, hideButton, confirm, ...props }) => {
  const button = hideButton ? null : (
    <Button onClick={confirm} theme={buttonTheme} isLoading={isLoading}>
      Confirm
    </Button>
  )

  return <SimpleModal footer={button} {...props} />
}

ConfirmModal.defaultProps = {
  children: "Are you sure?",
  header: "Confirm Action",
  buttonTheme: "primary",
}

ConfirmModal.propTypes = {
  confirm: T.func.isRequired,
  isLoading: T.bool.isRequired,
  onClose: T.func.isRequired,
  buttonTheme: T.string,
  header: T.string.isRequired,
}
