import { defineEnum, definePrefixedEnum } from "utils/misc"

const NS = "permissions"

export const ROLES = defineEnum(["admin", "employee", "clerk", "analyst"])

export const PERMS = definePrefixedEnum(NS, [
  "dashboard",
  "add_item",
  "add_transaction",
  "add_contact",
  "item_list",
  "account_list",
  "gift_card_list",
  "transaction_list",
  "item_detail",
  "till_detail",
  "account_detail",
  "gift_card_detail",
  "transaction_detail",
  "settings",
  "reports_navigation",
  "reports_sales",
  "reports_data_explorer",
  "merge_accounts",
  "edit_balance_history",
  "table_actions",
  "edit_sale",
  "bulk_adjust_balance",
])
