import { uniq, equals, reject } from "ramda"

export default () => {
  return {
    initialState: [],
    reducers: {
      // Disable set state since we're not using an object at the root
      setState: null,
      set: (state, payload) => uniq(payload),
      append: (state, payload) => uniq(state.concat(payload)),
      remove: (state, payload) => reject(equals(payload), state),
    },
  }
}
