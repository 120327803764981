import React from "react"
import bowser from "bowser"
import { Button } from "partials/Button"
import { T, BoundComponent } from "utils/react"

export default class SubmitButton extends BoundComponent {
  onClick(evt) {
    // Handle submit manually only if we're not a child of the form,
    // or we're on mobile, since a touchend event doesn't trigger a submit
    if (evt.type === "touchend" || !evt.target.closest("form")) {
      // ios doesn't blur active inputs, leading to missing data on submit
      if (bowser.ios) {
        document.activeElement?.blur()
      }

      this.props.onSubmit()
    }
  }
  render() {
    return <Button type="submit" theme="primary" onClick={this.onClick} {...this.props} />
  }
}

SubmitButton.propTypes = {
  onSubmit: T.func.isRequired,
  isLoading: T.bool.isRequired,
}
