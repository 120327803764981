import React from "react"
import cx from "classnames"
import { T, BoundComponent } from "utils/react"
import { decorate } from "modules/inputs/utils"

class _StringInput extends BoundComponent {
  render() {
    const { inputRef, ...props } = this.props

    return (
      <input
        {...props}
        autoComplete="off"
        className={cx(props.className, "input-border")}
        ref={inputRef}
      />
    )
  }
}

_StringInput.propTypes = {
  onChange: T.func.isRequired,
  inputRef: T.func,
  onUnlock: T.func,
  onLock: T.func,
}

export const StringInput = decorate(_StringInput, {
  dom: true,
  format: String,
  defaultValue: "",
  withAddons: true,
})
