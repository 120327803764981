import { createSelector } from "reselect"
import { Dt } from "utils/dt"
import { createClient, registerClient } from "modules/client"
import State from "app/core/state"

const currentVersion = Dt.now().subtract(5, "seconds")
let meta = { version: currentVersion.format() }
let lastMetaCheck = currentVersion

const getMeta = async () => {
  const now = Dt.now()

  // Don't gum up the works with unnecessary http requests
  if (now.diff(lastMetaCheck, "seconds") < 5) {
    return meta
  }

  try {
    const res = await fetch("/client-meta", { method: "get" })

    if (res.ok) {
      meta = await res.json()
    }
  } catch (e) {
    // Ignore it, just use current meta
  }

  lastMetaCheck = now

  return meta
}

const Client = createClient({
  postFetch: res => dispatch => {
    const compat = res.headers.get("x-compat")

    // If we've lost backwards compat with the server and the client
    // has deployed an update, refresh the client code. The way to force
    // an upgrade is to make sure the X-Compat header is a timestamp greater
    // than or equal to the client meta timestamp. The process is:
    // - Deploy new client version and wait for it to start
    // - Run ./scripts/update-compat.sh -r production from api repo
    // This can be tested locally using the following process:
    // - `yarn build:dev; yarn server:local`
    // - ./scripts/app/update_compat.sh local
    // - Restart client using `yarn build:dev; yarn server:local`
    if (compat && Dt.parse(compat).diff(currentVersion, "seconds") > 0) {
      getMeta().then(({ version }) => {
        if (Dt.parse(version).diff(currentVersion, "seconds") > 0) {
          dispatch(State.setState({ forceRefresh: true }))
        }
      })
    }

    return res
  },
  errorHandlers: {
    // If we get a 401, log them straight out to avoid getting them stuck on a loading screen
    unauthorized: State.resetAppState,
    appUnavailable: State.showMaintenanceMode,
    featureUnavailable: State.showFeatureUnavailable,
  },
  selectHeaders: createSelector(State.select.sessionId, sessionId =>
    sessionId ? { Authorization: `Bearer ${sessionId}` } : {},
  ),
})

registerClient(Client)
