import "resources/css/components/icon.css"

import React, { forwardRef } from "react"
import cx from "classnames"
import { MaybeLink } from "partials/MaybeLink"
import { T } from "utils/react"

export const Icon = forwardRef(
  (
    {
      icon,
      title,
      ns = "solid",
      size = "medium",
      theme = "default",
      outline = false,
      className,
      isSpinner,
      isDisabled,
      onClick,
      color,
      ...props
    },
    ref,
  ) => {
    const wrapperClassName = cx(`icon icon-${theme} icon-${size}`, className, {
      "icon-disabled": isDisabled,
      "icon-spinner": isSpinner,
      pointer: Boolean(onClick),
    })

    return (
      <span className="inline-block" ref={ref}>
        <MaybeLink onClick={onClick} className={wrapperClassName} {...props}>
          <i
            title={title}
            className={cx(`fa-${icon}`, {
              [`fa-${ns}`]: !outline,
              "fa-regular": outline,
            })}
          />
        </MaybeLink>
      </span>
    )
  },
)

Icon.propTypes = {
  icon: T.string.isRequired,
  outline: T.bool,
  className: T.string,
  title: T.string,
  theme: T.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "warning",
    "danger",
    "info",
    "default",
    "success",
  ]),
  isSpinner: T.bool,
  isDisabled: T.bool,
  onClick: T.oneOfType([T.string, T.func]),
  size: T.oneOf(["small", "medium", "large"]),
}

export const WarningIcon = props => <Icon icon="exclamation-triangle" theme="warning" {...props} />
