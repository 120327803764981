import React from "react"
import { connect } from "react-redux"
import { SimpleModal } from "partials/Modal"
import { Spinner } from "partials/Spinner"
import { BoundComponent } from "utils/react"
import history from "utils/history"
import Core from "app/core/state"
import State from "app/main/state"

const enhance = connect(
  state => ({
    loggedIn: Boolean(Core.select.whoami(state)),
    ...State.select.unsubscribe(state),
  }),
  {
    unsubscribe: State.unsubscribe.load,
  },
)

export default enhance(
  class Unsubscribe extends BoundComponent {
    componentDidMount() {
      const { match, loggedIn } = this.props

      this.props
        .unsubscribe(match.params)
        // If they're logged in for whatever reason, move them on
        .then(() => {
          if (loggedIn) {
            setTimeout(() => history.push("/"), 5000)
          }
        })
    }
    renderContent() {
      const { value, errors } = this.props
      const { email } = this.props.match.params

      // Show success even if we didn't find the email - that means they're
      // unsubscribed, which is what we want anyway
      if (value) {
        return (
          <div className="pb-4">
            Success! {email} has been unsubscribed from ConsignCloud emails.
          </div>
        )
      }

      if (errors) {
        return (
          <div className="pb-4">
            Uh oh! Something went wrong - please try clicking on the unsubscribe link again. If the
            problem persists, contact support.
          </div>
        )
      }

      return (
        <div className="pb-4">
          Confirming unsubscribe request for {email}...
          <Spinner />
        </div>
      )
    }
    render() {
      return (
        <SimpleModal isOpen header="Email Unsubscribe">
          {this.renderContent()}
        </SimpleModal>
      )
    }
  },
)
