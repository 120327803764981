import React from "react"
import ReactDOM from "react-dom"
import { T, BoundComponent } from "utils/react"

export default class Form extends BoundComponent {
  componentDidMount() {
    const { initialized } = this.props

    if (!initialized) {
      this.props.initialize()
    }
  }
  componentDidUpdate(oldProps) {
    // Scroll to first error
    setTimeout(() => {
      if (!this.props.hasError || this._unmounted) {
        return
      }

      const $this = ReactDOM.findDOMNode(this)

      if (!$this) {
        return
      }

      const $error = $this.querySelector(".input-wrapper-error")

      if ($error) {
        $error.closest(".input-wrapper").scrollIntoView()
      }
    })

    if (!oldProps.active && this.props.active) {
      this.props.initialize()
    }
  }
  componentWillUnmount() {
    const { persistOnUnmount } = this.props

    if (!persistOnUnmount) {
      this.props.setInitialized(false)
    }

    this._unmounted = true
  }
  onSubmit(evt) {
    evt.preventDefault()

    this.props.onSubmit()
  }
  render() {
    const { initialized, loader, children, name, className } = this.props

    if (!initialized) {
      return loader
    }

    // Apparently enter to submit doesn't always work if there's not a submit
    // input in the form. We've set up SubmitButton so that it doesn't have to
    // be inside the form to work, so we need this.
    // https://stackoverflow.com/a/35235768/1467342
    // Note that display: none will not do the trick in Safari
    // https://stackoverflow.com/a/59816746/1467342
    return (
      <form onSubmit={this.onSubmit} name={name} className={className}>
        <input className="absolute top-0 left-0" style={{ marginTop: "-10000px" }} type="submit" />
        {children}
      </form>
    )
  }
}

Form.defaultProps = {
  active: true,
  loader: null,
}

Form.propTypes = {
  name: T.string.isRequired,
  loader: T.children,
  active: T.bool.isRequired,
  initialized: T.bool.isRequired,
  initialize: T.func.isRequired,
  setInitialized: T.func.isRequired,
  onSubmit: T.func.isRequired,
  children: T.children.isRequired,
  persistOnUnmount: T.bool,
}
