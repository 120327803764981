import { is } from "ramda"

export const bindleTemplate =
  createBindle =>
  (opts = {}) =>
  ({ name, ns, parent, root }) => {
    if (is(Function, opts)) {
      opts = opts({ name, ns, parent, root })
    }

    return createBindle({ opts, name, ns, parent, root })
  }

export default bindleTemplate
