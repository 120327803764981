import uuid from "uuid/v4"
import { resolveAfter, mergeRight } from "hurdak/src/core"
import { collBindle, compileBindle } from "modules/bindles"

export default compileBindle("toast", {
  messages: collBindle(),
  createActions: ({ select, actions, messages }) => {
    const pop = id => async dispatch => {
      // Start the hide animation
      dispatch(
        messages.update({
          where: { id },
          values: { visible: false },
        }),
      )

      // Wait for the animation to complete
      await resolveAfter(700, null)

      // Remove it
      dispatch(messages.reject({ id }))
    }

    const push = toast => async dispatch => {
      toast = mergeRight(toast, {
        id: uuid(),
        slot: "ephemeral",
        duration: 5000,
      })

      // Remove any pre-existing one
      if (toast.slot) {
        dispatch(messages.reject({ slot: toast.slot }))
      }

      // Add the new one
      dispatch(messages.append({ ...toast, visible: false }))

      // Wait for it to render
      await resolveAfter(10, null)

      // After it's rendered, show it
      dispatch(
        messages.update({
          where: { id: toast.id },
          values: { visible: true },
        }),
      )

      // If it doesn't automatically dismiss, we're done
      if (!toast.duration) {
        return
      }

      // Wait for the dismiss duration
      await resolveAfter(toast.duration, null)

      // Remove it
      dispatch(pop(toast.id))
    }

    const withToast = (toast, thunk) => async dispatch => {
      const result = await dispatch(thunk)

      dispatch(push(toast))

      return result
    }

    const pushSuccess = () => push({ type: "success" })
    const withSuccess = thunk => withToast({ type: "success" }, thunk)

    return { push, pop, withToast, pushSuccess, withSuccess }
  },
})
