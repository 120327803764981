import React from "react"
import cx from "classnames"

/**
 * Shows a small status badge before its children. That status can be success,
 * warning or danger.
 */
const StatusIndicator = ({ status, children }) => {
  return (
    <span className="inline-flex items-center w-auto">
      <span
        className={cx("inline-block rounded-full", {
          "bg-success": status === "success",
          "bg-warning": status === "warning",
          "bg-danger": status === "danger",
        })}
        style={{
          height: 6,
          width: 6,
          marginRight: 6,
        }}
      />
      {children}
    </span>
  )
}

export default StatusIndicator
