import bowser from "bowser"
import identity from "ramda/src/identity"
import { waitFor } from "hurdak/src/core"

export const NBSP = "\u00a0"
export const BULLET = "\u2022"
export const DEG = "\u00b0"
export const EM = "\u2014"

export function preventDefault(evt) {
  evt.preventDefault()
}

export function stopPropagation(evt) {
  evt.stopPropagation()
}

export function killEvent(evt) {
  evt.preventDefault()
  evt.stopPropagation()
}

export function advanceFocus(currentTarget, step = 1) {
  const tags =
    'input:not([type="hidden"],[disabled],[tabindex="-1"]), button, a, area, object, select, textarea, [contenteditable="true"], [tabindex]:not([tabindex="-1"])'

  try {
    const elements = [].slice.apply(document.querySelectorAll(tags))
    const newIdx = elements.indexOf(currentTarget || document.activeElement) + step
    const next = elements[newIdx]

    if (next) {
      if (next.select) {
        next.select()
      }

      next.focus()
    }
  } catch (e) {
    if (!e.toString().includes("valid selector")) {
      throw e
    }
  }
}

export const clickHandler = onClick => {
  if (!onClick) {
    return null
  }

  if (!bowser.ios) {
    return { onClick }
  }

  // Handling click and touch events simultaneously is tricky:
  // https://www.html5rocks.com/en/mobile/touchandmouse/
  // The gist is touch events don't happen on desktop, so if they do, stop the click
  // from happening with preventDefault so we don't double-trigger the action, which
  // can cause fall-through with popover dialogues and such
  let touchStart = 0

  return {
    onClick,
    onTouchStart: evt => {
      evt.preventDefault()

      touchStart = new Date().valueOf()
    },
    onTouchEnd: evt => {
      evt.preventDefault()

      if (new Date().valueOf() - touchStart < 300) {
        onClick(evt)
      }
    },
  }
}

export function createScanListener(fn) {
  let buffer = ""
  let scanStart = null

  return function onScan(evt) {
    const now = new Date().valueOf()

    // Ignore invalid events
    // https://rollbar.com/consigncloud/consigncloud-client/items/3534/
    if (!evt.key) {
      return
    }

    // If it's been a while since the last input character,
    // it's not a scan, clear out the buffer
    if (now - scanStart > 100 * buffer.length + 300) {
      buffer = ""
      scanStart = null
    }

    // If it's an enter, it's the end of the scan, but only
    // treat it like one if we had 2 or more rapidly input characters.
    if (evt.key === "Enter" && buffer.length > 1) {
      fn(buffer.trim())
      buffer = ""
    } else if (evt.key.length === 1) {
      buffer += evt.key
      scanStart = scanStart || now
    }
  }
}

export function loadScript(src) {
  if (document.querySelector(`[src="${src}"]`)) {
    return
  }

  const script = document.createElement("script")

  script.type = "text/javascript"
  script.src = src

  document.body.append(script)
}

export const htmlEncode = html => {
  return document.createElement("a").appendChild(document.createTextNode(html)).parentNode.innerHTML
}

export const htmlDecode = html => {
  const textarea = document.createElement("textarea")

  textarea.innerHTML = html

  return textarea.value
}

export const isEvent = x =>
  x && (x instanceof window.Event || (x.nativeEvent && x.nativeEvent instanceof window.Event))

export const $ = selector => document.querySelector(selector)
export const $$ = selector => document.querySelectorAll(selector)

export function getDataTransferItems(event) {
  let dataTransferItemsList = []
  if (event.dataTransfer) {
    const dt = event.dataTransfer
    if (dt.files && dt.files.length) {
      dataTransferItemsList = dt.files
    } else if (dt.items && dt.items.length) {
      // During the drag event the dataTransfer.files is null
      // but Chrome implements some drag store, which is accesible via dataTransfer.items
      dataTransferItemsList = dt.items
    }
  } else if (event.target && event.target.files) {
    dataTransferItemsList = event.target.files
  }

  // Convert from DataTransferItemsList to the native Array
  return Array.prototype.slice.call(dataTransferItemsList).filter(identity)
}

export const copyToClipboard = text => {
  const { activeElement } = document
  const input = document.createElement("textarea")

  input.innerHTML = text
  document.body.appendChild(input)
  input.select()

  const result = document.execCommand("copy")

  document.body.removeChild(input)
  activeElement.focus()

  return result
}

export const stripExifData = async file => {
  if (window.DataTransferItem && file instanceof DataTransferItem) {
    file = file.getAsFile()
  }

  if (!file) {
    return file
  }

  const { default: Compressor } = await import("compressorjs")

  /* eslint no-new: 0 */

  return new Promise((resolve, _reject) => {
    new Compressor(file, {
      maxWidth: 1080,
      maxHeight: 1080,
      convertSize: 300 * 1024,
      success: resolve,
      error: e => {
        // Non-images break compressor
        if (e.toString().includes("File or Blob")) {
          return resolve(file)
        }

        _reject(e)
      },
    })
  })
}

export const post = (path, params, method = "post") => {
  const form = document.createElement("form")

  form.method = method
  form.action = path

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const field = document.createElement("input")

      field.type = "hidden"
      field.name = key
      field.value = params[key]

      form.appendChild(field)
    }
  }

  document.body.appendChild(form)
  form.submit()
}

export const printHtml = async html => {
  const openInstead = () => {
    const blob = new Blob([html], { type: "text/html" })
    const dataUrl = URL.createObjectURL(blob)

    return openInNewWindow(dataUrl, { revoke: true })
  }

  // Chrome on ios started showing the main window rather than the iframe
  if (bowser.chrome && bowser.ios) {
    return openInstead()
  }

  const iframe = document.createElement("iframe")

  iframe.style = "position: absolute; z-index: -1000; top: 0;"

  document.body.append(iframe)

  iframe.contentWindow.document.body.innerHTML = html

  const image = iframe.contentWindow.document.querySelector("img")

  // Wait for any images to finish loading
  if (image) {
    await waitFor(() => image.complete)
  }

  // Firefox can't deal with this coolness, open in a new tab instead
  try {
    iframe.contentWindow.print()
  } catch (e) {
    await openInstead()
  }

  // Safari makes the user confirm they want to print,
  // give them time before removing the iframe
  setTimeout(() => iframe.remove(), 60000)
}

export const escapeHtml = html => {
  const div = document.createElement("div")

  div.innerText = html

  return div.innerHTML
}

export const downloadContents = (title, dataUrl) => {
  const anchor = document.createElement("a")
  document.body.appendChild(anchor)

  anchor.download = title
  anchor.target = "_blank"
  anchor.href = dataUrl

  anchor.click()
  anchor.remove()
}

export const openInNewWindow = async (url, { print = true, revoke = false } = {}) => {
  // Set up an element so the browser thinks it's a real click
  const anchor = document.createElement("a")
  document.body.appendChild(anchor)

  anchor.target = "_blank"
  anchor.href = "about:blank"

  // url may be a promise that returns a url
  url = await url

  // Make the url match actual destination
  anchor.href = url
  anchor.onclick = () => {
    // Open it manually in another window so we can get the ref
    const other = window.open(anchor.href, "_blank")

    // Sometimes other isn't defined. Just give up at that point
    // Also, Safari gives us a window and catastrophically crashes
    if (other && print && !bowser.safari) {
      setTimeout(() => {
        try {
          other.print()
        } catch (e) {
          // This can happen for so many bad reasons
        }
      }, 1000)
    }

    // Prevent default link behavior
    return false
  }

  anchor.click()
  anchor.remove()

  // ios Chrome has some sort of race condition
  await new Promise(resolve => setTimeout(resolve, 300))

  // Give them a while to use it
  if (revoke) {
    setTimeout(() => URL.revokeObjectURL(url), 5 * 60 * 1000)
  }
}
