/* eslint no-inline-comments: 0 */

import React from "react"
import { connect } from "react-redux"
import { Switch, Route } from "react-router"
import { AbsSpinner } from "partials/Spinner"
import { BoundComponent } from "utils/react"
import Core from "app/core/state"
import { makeRootReducer, getAppReducers } from "app/state"
import ToastContainer from "app/toast/ToastContainer"
import MaintenanceModeModal from "app/main/MaintenanceModeModal"
import ForceRefreshModal from "app/main/ForceRefreshModal"
import Signup from "app/main/Signup"
import Unsubscribe from "app/main/Unsubscribe"
import LoginStore from "app/main/LoginStore"
import LoginVendor from "app/main/LoginVendor"
import RequestPasswordReset from "app/main/RequestPasswordReset"
import ResetPassword from "app/main/ResetPassword"
import VerifyEmail from "app/main/VerifyEmail"
import SendVendorOTP from "app/main/SendVendorOTP"

const enhance = connect(
  state => ({
    hasSession: Boolean(Core.select.sessionId(state)),
    hasStore: Boolean(Core.select.store(state)),
  }),
  {
    loadWhoami: Core.loadWhoami,
  },
)

export default enhance(
  class Shell extends BoundComponent {
    state = {
      App: null,
    }
    componentDidMount() {
      const { hasStore, hasSession } = this.props

      if (hasSession && !hasStore) {
        this.props.loadWhoami()
      }

      Promise.all([import("app/main/App"), getAppReducers()]).then(
        ([{ default: App }, appReducers]) => {
          window.app.store.replaceReducer(makeRootReducer(appReducers))

          this.setState({ App })
        },
      )
    }
    renderAppOrSignup() {
      const { App } = this.state
      const { hasSession, hasStore } = this.props

      if (!hasSession) {
        return (
          <Switch>
            <Route path="/signup" component={Signup} />
            <Route path="/login/vendor/confirm" component={LoginVendor} />
            <Route path="/login/vendor" component={SendVendorOTP} />
            <Route path="*" component={LoginStore} />
          </Switch>
        )
      }

      return hasStore && App ? <App /> : <AbsSpinner />
    }
    render() {
      return (
        <div>
          <div className="fixed inset-0 bg-body print:bg-white" />
          <Switch>
            <Route path="/password/reset/request" component={RequestPasswordReset} />
            <Route path="/password/reset" component={ResetPassword} />
            <Route path="/email/verify" component={VerifyEmail} />
            <Route path="/unsubscribe/:email" component={Unsubscribe} />
            {this.renderAppOrSignup()}
          </Switch>
          <ToastContainer />
          <MaintenanceModeModal />
          <ForceRefreshModal />
        </div>
      )
    }
  },
)
