import identity from "ramda/src/identity"
import { throttle } from "hurdak/src/core"
import Db from "modules/db/state"
import Api from "modules/domain/api"
import history from "utils/history"
import Core from "app/core/state"

const now = () => new Date().valueOf()
const minutes = m => m * 60 * 1000

export const autoLogoutMiddleware = ({ dispatch, getState }) => {
  let channel
  let lastActivity = now()

  // Poll to see if we have passed our auto logout threshhold
  setInterval(() => {
    const store = Core.select.store(getState())

    // If there's no store, we're already logged out
    if (!store) {
      return
    }

    const timeSinceLastActivity = now() - lastActivity

    // We have a hard timeout for store level session length, prefer that to user logout
    if (timeSinceLastActivity > minutes(120)) {
      dispatch(Core.resetAppState())
    } else {
      const users = Db.select.interface(getState()).active("user")
      const user = Core.select.user(getState())
      const shouldLogOutUser =
        user &&
        users.length > 1 &&
        store.autoLogoutMinutes !== null &&
        timeSinceLastActivity > minutes(store.autoLogoutMinutes)

      if (shouldLogOutUser) {
        dispatch(Core.logoutUser())
      }
    }
  }, minutes(1))

  // Synchronize last activity across multiple tabs
  if (window.BroadcastChannel) {
    channel = new BroadcastChannel("consigncloud/auto-logout")

    channel.addEventListener("message", () => {
      lastActivity = now()
    })
  }

  // Every action and mousemove, reset lastActivity and propagate across tabs
  const beat = throttle(() => {
    lastActivity = now()

    if (channel) {
      channel.postMessage("beat")
    }
  }, 1000)

  // Watch for mouse move events to get high granularity for read-only activities
  window.addEventListener("mousemove", beat)

  // Watch actions as well, so we can avoid logging out for idle times with polling
  return next => action => {
    beat()
    next(action)
  }
}

export const impersonateMiddleware = ({ dispatch }) => {
  window.impersonate = (token, store) => {
    // Clear everything out so that intercom shuts down properly
    dispatch(Core.resetAppState())

    // Give intercom time to shut down?
    return new Promise(resolve => {
      setTimeout(async () => {
        const auth = await dispatch(Api.session.post("impersonate", { token, store }))
        await dispatch(Core.setAuth({ ...auth, impersonating: true }))

        history.push("/")
        resolve()
      }, 1000)
    })
  }

  return identity
}
