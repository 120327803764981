import { combineReducers } from "redux"
import {
  mapObjIndexed,
  always,
  path as getPath,
  merge,
  prop,
  identity,
  mergeAll,
  keys,
  pick,
  isEmpty,
  omit,
  is,
} from "ramda"
import { mapKeys } from "hurdak/src/core"

const _getInitialState = ({ getInitialState, initialState }, { children }) => {
  const result = getInitialState ? getInitialState() : initialState

  if (isEmpty(children)) {
    return result
  }

  return merge(mergeAll(mapObjIndexed(prop("initialState"), children)), result || {})
}

const createBindleReducer = ({ children, reducers, initialState }) => {
  const childKeys = keys(children)
  const childReducer = !isEmpty(children)
    ? combineReducers(mapObjIndexed(prop("reducer"), children))
    : null

  return (state, action) => {
    if (reducers && reducers[action.type]) {
      return reducers[action.type](state, action.payload)
    }

    if (childReducer && state) {
      return { ...state, ...childReducer(pick(childKeys, state), action) }
    }

    return state === undefined ? initialState : state
  }
}

const createBindleProxy = bindle => {
  // Proxy the bindle so we can differentiate between children and the
  // bindle's own members, while also having a nice convenient interface
  // to the actions and children.
  return new Proxy(bindle, {
    get(target, name) {
      return (
        target[name] || getPath(["actions", name], target) || getPath(["children", name], target)
      )
    },
  })
}

export const compileBindle = (name, config, parentBindle, rootBindle) => {
  const {
    createSelectors = always({}),
    createActions = always({}),
    createComponents = always({}),
    decorateReducer = identity,
  } = config

  // Child selectors generally won't refer to parent selectors, but
  // child actions often will, so we mutate the bindle rather than
  // copy it so we can have a reference to it in child action creators.
  // We differentiate between the bindle and the root, since we always want
  // the root accessible in any given bindle group.
  const bindle = { name, path: [] }

  parentBindle = parentBindle || bindle
  rootBindle = rootBindle || parentBindle

  // Add to the root path if we're setting up a child here
  bindle.path = parentBindle.path.concat(name)
  bindle.ns = bindle.path.join(".")

  // Little helper for namespacing action constants
  const prependNs = k => `${bindle.ns}/${k}`

  const proxy = createBindleProxy(bindle)
  const parentProxy = createBindleProxy(parentBindle)
  const rootProxy = createBindleProxy(rootBindle)

  // Recur first. Pass a reference to the root bindle so that child actions
  // can dispatch parent actions. Children are anything other than valid config keys
  bindle.children = mapObjIndexed(
    (childConfig, childName) => {
      const childNs = bindle.path.concat(childName).join(".")

      while (is(Function, childConfig)) {
        childConfig = childConfig({
          ns: childNs,
          name: childName,
          parent: proxy,
          root: rootProxy,
        })
      }

      if (childConfig._compiled) {
        throw new Error(`${childNs} is already compiled`)
      }

      return compileBindle(childName, childConfig, bindle, rootBindle)
    },
    omit(
      [
        "initialState",
        "reducers",
        "createActions",
        "createSelectors",
        "createComponents",
        "getInitialState",
        "decorateReducer",
      ],
      config,
    ),
  )

  // If we don't have any children, don't force initialState to be an
  // object, so we don't always have to nest stuff for primitive bindles
  bindle.initialState = _getInitialState(config, bindle)

  // Key/value map of reducers so we can autogenerate actions. Combined later
  const reducers = {
    setState: (state, { type, ...payload }) => ({ ...state, ...payload }),
    reset: () => _getInitialState(config, bindle),
    ...(is(Function, config.reducers) ? config.reducers(bindle.initialState) : config.reducers),
  }

  // Combine child reducer and reducer cases into a single reducer
  bindle.reducer = decorateReducer(
    createBindleReducer({
      children: bindle.children,
      reducers: mapKeys(prependNs, reducers),
      initialState: bindle.initialState,
    }),
  )

  // Start with a root selector, add selectors based on the keys
  // in initialState
  bindle.select = merge(
    { root: getPath(bindle.path) },
    mapObjIndexed((_, k) => getPath(bindle.path.concat(k)), bindle.initialState),
  )

  // Separately merge in the result of createSelectors since they might
  // depend on autocreated selectors. Clone bindle to avoid subtle bugs
  // related to mutation.
  bindle.select = merge(
    bindle.select,
    createSelectors(proxy, {
      parent: parentProxy,
      root: rootProxy,
    }),
  )

  // Autogenerate namespaced action constants for all defined reducers
  bindle.actionConstants = mapObjIndexed((_, k) => prependNs(k), reducers)

  // Autogenerate namespaced action creators. Avoid passing events through as payloads
  bindle.actions = mapObjIndexed(
    type => p => ({ type, payload: p && p.nativeEvent ? null : p }),
    bindle.actionConstants,
  )

  // Separately merge in the result of createActions since they might
  // depend on autocreated actions. Clone bindle to avoid subtle bugs
  // related to mutation.
  bindle.actions = merge(
    bindle.actions,
    createActions(proxy, {
      parent: parentProxy,
      root: rootProxy,
    }),
  )

  // Add in components based on createComponents
  bindle.components = createComponents(proxy, {
    parent: parentProxy,
    root: rootProxy,
  })

  // Mark it as compiled to catch recursion
  bindle._compiled = true

  return proxy
}
