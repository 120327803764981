import "resources/css/components/fancy-select.css"

import React from "react"
import ReactDOM from "react-dom"
import cx from "classnames"
import debounce from "es6-promise-debounce"
import prop from "ramda/src/prop"
import propEq from "ramda/src/propEq"
import reject from "ramda/src/reject"
import equals from "ramda/src/equals"
import find from "ramda/src/find"
import whereEq from "ramda/src/whereEq"
import { noop, stackedPromise, switcherFn } from "hurdak/src/core"
import { escapable } from "partials/Escapable"
import { Tippy } from "partials/Tippy"
import { Icon } from "partials/Icon"
import { NoResults } from "partials/NoResults"
import { Chip } from "partials/Chip"
import { T, BoundComponent } from "utils/react"
import { fuzzy } from "utils/misc"
import { advanceFocus, clickHandler, killEvent } from "utils/html"
import { decorate } from "modules/inputs/utils"
import { sortBy } from "ramda"

// This component does a lot of stuff. Things to check:
//
// - Make sure z-index is correct on FilterBox, GlobalSearch, and in modals
// - Escape should clear the input's value first, then if no value blur it.
// - Ensure text is editable and cursor acts normally, especially on iPad.
// - Hitting enter before loading completes should select the first option
//   (unless initialHighlight is -1)

class DefaultValue extends BoundComponent {
  onRemove() {
    const { idx } = this.props

    this.props.onRemove(idx)
  }
  render() {
    const { children } = this.props

    return (
      <Chip size="tiny" onRemove={this.onRemove}>
        {children}
      </Chip>
    )
  }
}

class DefaultOption extends BoundComponent {
  handlers = clickHandler(this.onClick)
  onClick() {
    const { idx } = this.props

    this.props.selectOption(idx)
  }
  render() {
    const { children, isActive } = this.props
    const className = cx("fancy-select-input__option truncate", {
      "fancy-select-input__option--active": isActive,
    })

    return (
      <div className={className} {...this.handlers}>
        {children}
      </div>
    )
  }
}

class _FancySelectInput extends BoundComponent {
  onChange(value) {
    if (!this.props.disabled) {
      this.props.onChange(value)
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      options: null,
      loading: false,
      highlight: props.initialHighlight,
      isFocused: false,
      blurImmediate: false,
    }

    this._trackPromise = stackedPromise()
    this._loadOptions = this.createLoadOptions()

    // autocomplete="off" gets ignored by Edge
    this._autoComplete = /Edge/.test(navigator.userAgent)
      ? Math.random().toString().slice(2)
      : "off"

    this.resetSearchCache()

    if (props.setInstance) {
      props.setInstance(this)
    }
  }

  // Lifecycle methods

  componentDidMount() {
    const { isMulti, defaultValue, value } = this.props

    if (!value && defaultValue) {
      this.onChange(defaultValue)
    } else if (!value && isMulti) {
      this.onChange([])
    } else if (!isMulti) {
      this.setInputValue(this.displayValue(value))
    }
  }
  componentDidUpdate(old) {
    const { value, options, loadOptions, isMulti, initialHighlight } = this.props
    const { isFocused, highlight, blurImmediate } = this.state

    if (!equals(old.options, options) || old.loadOptions !== loadOptions) {
      this._loadOptions = this.createLoadOptions()
    }

    if (!isMulti && !equals(value, old.value)) {
      this.setInputValue(this.displayValue(value))
    }

    if (initialHighlight > highlight) {
      this.setState({ highlight: initialHighlight })
    }

    if (isFocused && !blurImmediate && this._input && this._input !== document.activeElement) {
      this._input.select()
    }

    // Align the box with the highlight rather than the actual input
    if (isFocused && this._popover) {
      const domNode = ReactDOM.findDOMNode(this)
      const { width } = domNode.getBoundingClientRect()

      this._popover.style = `left: -1px; width: ${width + 2}px;`
    }
  }
  componentWillUnmount() {
    this._unmounted = true

    if (this.tooltip) {
      this.tooltip.hide()
    }
  }

  // State management

  setInputValue(inputValue) {
    // Sync this manually to avoid jitter on keyboard input. We have to
    // avoid setting _input.value in onInputChange because it messes up
    // cursor position in Safari
    this._inputValue = inputValue
    this._input.value = inputValue
    this.forceUpdate()

    if (this.props.onInputChange) {
      this.props.onInputChange(inputValue)
    }
  }
  getInputValue() {
    return this._inputValue || ""
  }
  displayValue(value) {
    const { valueToOption, options, optionToString } = this.props

    if (!value) {
      return ""
    }

    return optionToString(find(whereEq({ value }), options || []) || valueToOption(value))
  }
  resetSearchCache() {
    this._searchCache = {
      term: null,
      valueLength: null,
      options: null,
    }
  }
  createLoadOptions() {
    const { options, loadOptions, isMulti, getSortKey } = this.props

    const _getSortKey = getSortKey || (option => option.label)

    const loader = options
      ? debounce(
          fuzzy(
            sortBy(_getSortKey, options).map(o => ({ ...o, s: o.label })),
            { keys: ["s"] },
          ),
          100,
        )
      : debounce(loadOptions, 300)

    return async term => {
      const { value } = this.props
      const cache = this._searchCache
      const valueLength = isMulti ? value && value.length : null

      // If we have a cache hit, we're good. Delay a bit to make things feel consistent
      if (term === cache.term && valueLength >= cache.valueLength && cache.options) {
        // Ruth was having trouble with this, comment it out and see if it's any better
        //  return resolveAfter(100, cache.options)
      }

      // Clear our cache right away because a reversion to the previous
      // term that happens less than 100ms before the new results resolve
      // will return the results for the current promise, which by that
      // time will be stale.
      Object.assign(cache, { options: null })

      const _options = await this._trackPromise(loader(term))

      Object.assign(cache, { options: _options, term, valueLength })

      return _options
    }
  }
  async loadOptions(term) {
    this.setState({
      loading: true,
      highlight: this.props.initialHighlight,
    })

    const options = await this._loadOptions(term)

    if (this._unmounted) {
      return
    }

    this.setState({ options, loading: false })

    if (this.state.selectAfterLoad) {
      this.selectOption(this.props.initialHighlight)
      this.setState({ selectAfterLoad: false })

      // If we halted blur so we could finish loading, trigger it now.
      if (this.state.blurImmediate) {
        this.blur()
      }
    }
  }
  reloadOptions() {
    this.resetSearchCache()
    this.loadOptions(this.getInputValue())
  }
  getOptions() {
    const { limit, isMulti, isCreatable, value, createLabel } = this.props
    const { options: allOptions } = this.state
    const inputValue = this.getInputValue()

    // If options hasn't been set, we need to know not to render NoResults
    if (!allOptions) {
      return null
    }

    let options = allOptions

    // Remove options already chosen
    if (isMulti) {
      options = reject(({ value: v }) => value.includes(v), options)
    }

    // If it's not creatable, we're done
    if (!isCreatable) {
      return options.slice(0, limit)
    }

    // Add a create option at the end, but only if we have an input value and it's
    // not already one of the options.
    if (!inputValue || allOptions.some(propEq("value", inputValue))) {
      return options.slice(0, limit)
    }

    return [
      ...options.slice(0, limit - 1),
      {
        __isCreateOption: true,
        label: (
          <span>
            <Icon icon="plus" /> {createLabel} {`"${inputValue}"`}
          </span>
        ),
      },
    ]
  }
  highlightOption(highlight) {
    this.setState({ highlight })
  }
  selectOption(idx) {
    const {
      autoAdvance,
      optionToString,
      value,
      isMulti,
      clearOnSelect,
      initialHighlight,
      optionToValue,
    } = this.props
    const { loading } = this.state

    if (loading) {
      this.setState({ selectAfterLoad: true })

      return
    }

    const option = prop(idx, this.getOptions())

    if (!option) {
      return
    }

    if (option.__isCreateOption && isMulti) {
      this.onChange(value.concat(this.getInputValue()))
    } else if (option.__isCreateOption) {
      this.onChange(this.getInputValue())
    } else if (isMulti) {
      this.onChange(value.concat(optionToValue(option)))
    } else {
      this.onChange(optionToValue(option))
    }

    let newInputValue
    if (isMulti || clearOnSelect) {
      newInputValue = ""
    } else if (option.__isCreateOption) {
      newInputValue = this.getInputValue()
    } else {
      newInputValue = optionToString(option)
    }

    this.loadOptions(newInputValue)
    this.setInputValue(newInputValue)

    this.setState({ isFocused: isMulti, highlight: initialHighlight })

    // Allow state changes from the above onChange to happen before advancing
    if (autoAdvance && !this.state.blurImmediate) {
      setTimeout(() => advanceFocus(this._input))
    }
  }
  removeValue(idx) {
    const { value } = this.props

    this.onChange(value.slice(0, idx).concat(value.slice(idx + 1)))
  }
  clearValue(evt) {
    this.onChange(null)
  }

  // Input event handlers

  onInputFocus() {
    if (this.state.isFocused || this.props.disabled) {
      return
    }

    this.loadOptions("")
    this.setState({ isFocused: true })

    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }
  onInputBlur(evt) {
    if (this._unmounted) {
      return
    }

    // At some point, tippy started setting relatedTarget exclusively to tippy-box,
    // so the below contains check no longer seems to work.
    if (evt.relatedTarget && evt.relatedTarget.classList.contains("tippy-box")) {
      evt.preventDefault()

      return
    }

    if (this._popover && this._popover.contains(evt.relatedTarget)) {
      evt.preventDefault()

      return
    }

    this.blur()
  }
  onInputChange(evt) {
    this._inputValue = evt.target.value
    this.loadOptions(evt.target.value)
    this.onInputFocus()

    if (this.props.onInputChange) {
      this.props.onInputChange(evt.target.value)
    }
  }
  onInputKeyDown(evt) {
    const { highlight, isFocused, selectAfterLoad } = this.state
    const { initialHighlight, isMulti, value, onKeyDown, isCreatable } = this.props

    if (selectAfterLoad) {
      evt.preventDefault()

      return
    }

    if (onKeyDown) {
      onKeyDown(evt)
    }

    switcherFn(evt.key, {
      default: noop,
      Enter: () => {
        const options = this.getOptions() || []

        if (isFocused && options) {
          evt.preventDefault()

          if (evt.shiftKey && isCreatable) {
            this.selectOption(options.length - 1)
          } else {
            this.selectOption(highlight)
          }
        }
      },
      // Handle this manually since there's a race condition where two tabs in
      // quick succession puts focus on the body rather than the next input
      Tab: () => {
        if (isFocused) {
          evt.preventDefault()

          advanceFocus(null, evt.shiftKey ? -1 : 1)
        }
      },
      Backspace: () => {
        if (isMulti && !this._inputValue) {
          this.removeValue(value.length - 1)
        }
      },
      ArrowUp: () => {
        evt.preventDefault()

        // If we're deferring highlight and it hasn't been activated yet,
        // don't move it down with the up arrow
        if (highlight === -1) {
          this.setState({ isFocused: true })
        } else {
          this.setState({
            isFocused: true,
            highlight: isFocused ? Math.max(0, highlight - 1) : 0,
          })
        }
      },
      ArrowDown: () => {
        evt.preventDefault()

        const options = this.getOptions() || []

        this.setState({
          isFocused: true,
          highlight: isFocused ? Math.min(options.length - 1, highlight + 1) : initialHighlight,
        })
      },
    })
  }
  blur() {
    this.setState({ blurImmediate: true })

    if (this._tooltip && this._tooltip.popper) {
      this._tooltip.popper.classList.add("opacity-0")
    }

    // Immediately propagate the change so we don't have a race condition with someone
    // pressing the submit button and not getting their most recent change
    if (!this.state.selectAfterLoad) {
      const { isCreatable, isClearable, isMulti, value } = this.props

      if (isClearable && !isMulti && !this.getInputValue()) {
        this.onChange(null)
      } else if (isCreatable && !isMulti && this.getInputValue() !== this.displayValue(value)) {
        this.onChange(this.getInputValue())
      } else {
        this.setInputValue(isMulti ? "" : this.displayValue(value))
      }
    }

    setTimeout(() => {
      if (this._unmounted) {
        return
      }

      if (this._tooltip && this._tooltip.popper) {
        setTimeout(() => this._tooltip.popper.classList.remove("opacity-0"), 300)
      }

      // If we redirected focus back to the input immediately, do nothing
      if (ReactDOM.findDOMNode(this).contains(document.activeElement)) {
        this.setState({ blurImmediate: false })

        return
      }

      this.setState({ isFocused: false, blurImmediate: false })

      if (this.props.onBlur) {
        this.props.onBlur()
      }
    }, 200)
  }
  escape(evt) {
    const { isFocused } = this.state

    if (!isFocused) {
      return
    }

    const { isMulti, isClearable } = this.props

    if (!isMulti && isClearable && this._inputValue && evt.key === "Escape") {
      this.setInputValue("")
      this.loadOptions("")
    } else {
      this._input.blur()
    }
  }

  // Refs

  setInput(el) {
    if (el) {
      this._input = el
    }
  }
  setTooltip(tooltip) {
    this._tooltip = tooltip
  }
  setPopover(popover) {
    if (popover) {
      this._popover = popover.parentNode.parentNode
    }
  }

  // Render helpers

  showPopover() {
    const { showOptions, showNoResults, hideOptionsWhenEmpty } = this.props
    const { isFocused, loading } = this.state
    const options = this.getOptions()

    return Boolean(
      (!hideOptionsWhenEmpty || this._inputValue) &&
        (showNoResults || prop("length", options)) &&
        showOptions &&
        !loading &&
        isFocused &&
        options,
    )
  }
  renderPopover() {
    if (!this.showPopover()) {
      return null
    }

    const { Option, footer } = this.props
    const { highlight } = this.state
    const options = this.getOptions()

    return (
      <div
        ref={this.setPopover}
        onClick={killEvent}
        className="ignore-react-onclickoutside fancy-select-input__popover"
      >
        {options.length === 0 ? (
          <NoResults />
        ) : (
          <div>
            {options.map(({ label }, idx) => (
              <Option
                key={idx}
                idx={idx}
                isActive={idx === highlight}
                highlightOption={this.highlightOption}
                selectOption={this.selectOption}
              >
                {label}
              </Option>
            ))}
          </div>
        )}
        {footer}
      </div>
    )
  }
  render() {
    const {
      name,
      Value,
      isMulti,
      zIndex,
      value,
      placeholder,
      showControls,
      showOptions,
      isExpandable,
      showLoading,
      disabled,
    } = this.props
    const { isFocused, blurImmediate, selectAfterLoad } = this.state

    const loading =
      ((isFocused && !blurImmediate) || selectAfterLoad) &&
      this.state.loading &&
      showOptions &&
      showLoading

    const isClearable = this.props.isClearable && !isMulti
    const className = cx("fancy-select-input ignore-react-onclickoutside", this.props.className, {
      "fancy-select-input--focused": isFocused && !blurImmediate,
      "fancy-select-input--disabled": selectAfterLoad || disabled,
      "input-border": !this.props.hideBorder,
      "input-spacing": this.props.hideBorder,
      "bg-transparent": this.props.hideBorder,
    })

    return (
      <Tippy
        theme="menu"
        zIndex={zIndex}
        instanceRef={this.setTooltip}
        render={this.renderPopover}
        isOpen={this.showPopover()}
        hideOnClick={false}
        className="flex-grow box-border max-w-full"
        placement="bottom-start"
      >
        {isMulti && value && value.length > 0 && (
          <div className="mb-1">
            {value.map((v, idx) => (
              <Value key={idx} idx={idx} onRemove={this.removeValue}>
                {this.displayValue(v)}
              </Value>
            ))}
          </div>
        )}
        <span className={className} onClick={this.onInputFocus}>
          <input
            name={name}
            disabled={disabled}
            ref={el => {
              this.setInput(el)
              if (this.props.inputRef) {
                this.props.inputRef(el)
              }
            }}
            autoComplete={this._autoComplete}
            readOnly={selectAfterLoad}
            className="flex-auto w-full h-8 text-lg"
            style={{ marginTop: "-6px" }}
            placeholder={placeholder}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            onChange={this.onInputChange}
            onKeyDown={this.onInputKeyDown}
          />
          {showControls && (
            <span className="text-gray-500 flex fancy-select-input__controls">
              {loading && <Icon isSpinner icon="circle-notch" className="mx-1" />}
              {isClearable && !loading && (
                <Icon
                  icon="times"
                  tabIndex="-1"
                  isDisabled={!this.getInputValue() || disabled}
                  onClick={this.getInputValue() ? this.clearValue : null}
                  className="mx-1"
                />
              )}
              {isClearable && (
                <span className="border-l border-solid border-gray-400 mx-1" tabIndex="-1" />
              )}
              {isExpandable && (
                <Icon
                  tabIndex="-1"
                  icon="caret-down"
                  className={cx({ "cursor-pointer": !disabled, "mx-1": true })}
                />
              )}
            </span>
          )}
        </span>
      </Tippy>
    )
  }
}

_FancySelectInput.defaultProps = {
  limit: 8,
  isMulti: false,
  showLoading: true,
  showControls: true,
  showOptions: true,
  showNoResults: true,
  hideOptionsWhenEmpty: false,
  isClearable: true,
  isExpandable: true,
  isCreatable: false,
  createLabel: "Create",
  clearOnSelect: false,
  autoAdvance: false,
  initialHighlight: 0,
  valueToOption: value => ({ value, label: value }),
  optionToValue: prop("value"),
  optionToString: prop("label"),
  Option: DefaultOption,
  Value: DefaultValue,
  disabled: false,
  hideBorder: false,
}

_FancySelectInput.propTypes = {
  setInstance: T.func,
  isMulti: T.bool.isRequired,
  initialHighlight: T.oneOf([-1, 0]).isRequired,
  showLoading: T.bool.isRequired,
  showControls: T.bool.isRequired,
  showOptions: T.bool.isRequired,
  showNoResults: T.bool.isRequired,
  hideOptionsWhenEmpty: T.bool.isRequired,
  isClearable: T.bool.isRequired,
  isExpandable: T.bool.isRequired,
  isCreatable: T.bool.isRequired,
  createLabel: T.string.isRequired,
  clearOnSelect: T.bool.isRequired,
  autoAdvance: T.bool.isRequired,
  valueToOption: T.func.isRequired,
  optionToString: T.func.isRequired,
  loadOptions: T.func,
  getSortKey: T.func,
  options: T.array,
  Option: T.component.isRequired,
  Value: T.component.isRequired,
  onChange: T.func.isRequired,
  onInputChange: T.func,
  onKeyDown: T.func,
  onFocus: T.func,
  onBlur: T.func,
  zIndex: T.number,
  footer: T.children,
  disabled: T.bool,
  inputRef: T.any,
  style: T.any,
  hideBorder: T.bool,
}

export const FancySelectInput = decorate(escapable(_FancySelectInput), {})
