import React from "react"
import { connect } from "react-redux"
import { Content } from "partials/Layout"
import { Modal } from "partials/Modal"
import { CardTop, CardBottom, CardBottomFooter } from "partials/Card"
import { Button } from "partials/Button"
import { Progress } from "partials/Progress"
import { Anchor } from "partials/Anchor"
import { BoundComponent } from "utils/react"
import Logger from "modules/logging"
import { privacyLink, termsLink } from "modules/domain"
import { getClient } from "modules/client"
import { FieldInfo } from "modules/forms"
import { CountryInput } from "modules/inputs/CountryInput"
import { TimezoneInput } from "modules/inputs/TimezoneInput"
import { StringInput } from "modules/inputs/StringInput"
import { CheckboxInput } from "modules/inputs/CheckboxInput"
import history from "utils/history"
import Core from "app/core/state"
import { default as State, signupFormStages } from "app/main/state"

const { Form, Field, SubmitButton } = State.signupForm.components

const enhance = connect(
  state => ({
    loggedIn: Boolean(Core.select.whoami(state)),
    errors: State.signupForm.select.errors(state),
    stage: State.select.signupFormStage(state),
  }),
  {
    advance: State.advanceSignupForm,
    guessCountry: () => async dispatch => {
      try {
        const {
          data: { countryName },
        } = await dispatch(getClient().post("/country/guess/", { body: {} }))

        dispatch(State.signupForm.changeField("country", countryName))
      } catch (err) {
        Logger.error({ err })
      }
    },
  },
)

export default enhance(
  class Signup extends BoundComponent {
    componentDidMount() {
      // Don't let them see this page if they're logged in
      if (this.props.loggedIn) {
        return history.push("/")
      }

      this.props.guessCountry()
    }
    prevStage() {
      this.props.advance("prev")
    }
    nextStage() {
      this.props.advance("next")
    }
    renderStoreStage() {
      return (
        <Content>
          <FieldInfo
            label="Store Name *"
            icon="university"
            helpText="What's the name of your shop?"
          >
            <Field autoFocus name="name" Input={StringInput} placeholder="Your store name..." />
          </FieldInfo>
          <FieldInfo
            label="Email Address *"
            icon="at"
            helpText="This will be used as your username when logging in and for for sensitive
                  communications like password resets."
          >
            <Field name="email" Input={StringInput} placeholder="Your email address..." />
          </FieldInfo>
          <FieldInfo
            label="Your Name *"
            icon="circle-user"
            helpText="We'll personalize your account, and any product-related communications
                  we send you."
          >
            <Field name="username" Input={StringInput} placeholder="Your name..." />
          </FieldInfo>
          <FieldInfo
            label="Password *"
            icon="asterisk"
            helpText="Your password. We require a minimum of 12 characters, but longer is better."
          >
            <Field
              name="password"
              Input={StringInput}
              inputProps={{ type: "password" }}
              placeholder="Your password..."
            />
          </FieldInfo>
        </Content>
      )
    }
    renderLocationStage() {
      return (
        <Content>
          <FieldInfo
            label="Country *"
            icon="globe"
            helpText="The country you'll be doing business in."
          >
            <Field autoFocus name="country" Input={CountryInput} />
          </FieldInfo>
          <FieldInfo
            label="Time Zone *"
            icon="clock"
            helpText="Your time zone will be used to personalize the software."
          >
            <Field autoFocus name="timezone" Input={TimezoneInput} />
          </FieldInfo>
          <FieldInfo
            label="Phone Number"
            icon="phone"
            helpText="We will only get in touch for customer support purposes, or in case
                  of emergency. You may also leave this blank."
          >
            <Field name="phone" Input={StringInput} placeholder="Your phone number..." />
          </FieldInfo>
          <FieldInfo
            label="Coupon"
            icon="credit-card"
            helpText="A referral code or promotional coupon code."
          >
            <Field autoFocus name="coupon" Input={StringInput} />
          </FieldInfo>
        </Content>
      )
    }
    renderPolicyStage() {
      const { errors } = this.props

      return (
        <div className="flex flex-col gap-8">
          <h2 className="mt-4">Just one more thing!</h2>
          <div className="grid grid-cols-3 gap-x-8 gap-y-2">
            <label className="col-span-2">
              Have you read and do you consent to our {privacyLink}?
            </label>
            <Field name="acceptedPrivacy" Input={CheckboxInput} noError />
            {errors.acceptedPrivacy && (
              <span className="text-danger col-span-3">{errors.acceptedPrivacy}</span>
            )}
            <label className="col-span-2">Have you read and agree with our {termsLink}?</label>
            <Field name="acceptedTerms" Input={CheckboxInput} noError />
            {errors.acceptedTerms && (
              <span className="text-danger col-span-3">{errors.acceptedTerms}</span>
            )}
          </div>
        </div>
      )
    }
    renderButtons() {
      const { stage } = this.props
      const login = (
        <Anchor key="login" onClick="/login">
          I already have an account
        </Anchor>
      )
      const back = (
        <Button key="back" onClick={this.prevStage}>
          Back
        </Button>
      )
      const next = (
        <Button key="next" onClick={this.nextStage}>
          Next
        </Button>
      )
      const submit = <SubmitButton key="submit">Get Started</SubmitButton>

      if (stage === "store") {
        return [login, next]
      } else if (stage === "location") {
        return [back, next]
      } else if (stage === "policy") {
        return [back, submit]
      }
    }
    render() {
      const { stage } = this.props
      const progress = signupFormStages.indexOf(stage) / signupFormStages.length

      return (
        <Modal isOpen size="4xl">
          <Form>
            <CardTop>
              <h1>Welcome to ConsignCloud!</h1>
            </CardTop>
            <Progress theme="primary" value={progress} />
            <CardBottom>
              {stage === "store" && this.renderStoreStage()}
              {stage === "location" && this.renderLocationStage()}
              {stage === "policy" && this.renderPolicyStage()}
              <CardBottomFooter>{this.renderButtons()}</CardBottomFooter>
            </CardBottom>
          </Form>
        </Modal>
      )
    }
  },
)
