import "resources/css/components/button.css"

import React from "react"
import cx from "classnames"
import { MaybeLink } from "partials/MaybeLink"
import { Icon } from "partials/Icon"
import { T } from "utils/react"

export const Button = ({
  theme = "default",
  size = "medium",
  isFullWidth,
  isCentered,
  isLoading,
  isDisabled,
  isActive,
  tabIndex,
  onClick,
  children,
  type = "button",
  className,
  ...props
}) => {
  className = cx(`button button-${theme} button-${size}`, className, {
    "button-fullwidth": isFullWidth,
    "button-centered": isCentered,
    "button-disabled": isDisabled,
    "button-active": isActive,
    "button-loading": isLoading,
  })

  if (isLoading) {
    children = (
      <span>
        {children} <Icon icon="circle-notch" isSpinner />
      </span>
    )
  }

  if (isLoading || isDisabled) {
    onClick = undefined

    // To disable submit action if type = submit
    type = "button"
  }

  return (
    <MaybeLink
      Component="button"
      className={className}
      tabIndex={tabIndex}
      onClick={onClick}
      type={type}
      {...props}
    >
      {children}
    </MaybeLink>
  )
}

Button.propTypes = {
  theme: T.oneOf(["primary", "secondary", "tertiary", "default", "faded", "danger"]),
  size: T.oneOf(["small", "medium", "large"]),
  isFullWidth: T.bool,
  isCentered: T.bool,
  isLoading: T.bool,
  isDisabled: T.bool,
  isActive: T.bool,
  tabIndex: T.number,
  onClick: T.oneOfType([T.string, T.func]),
  children: T.children,
  type: T.oneOf(["submit"]),
}

export const SubmitButton = props => <Button type="submit" theme="primary" {...props} />

export const IconButton = ({ before, after, children, ...props }) => {
  return (
    <Button {...props}>
      {before && <Icon icon={before} />}
      <span>{children}</span>
      {after && <Icon icon={after} />}
    </Button>
  )
}

IconButton.propTypes = {
  before: T.children,
  after: T.children,
  children: T.children,
}
