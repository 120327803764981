import copyOfMomentForReactDates from "moment"
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
import enAU from "utils/dt/en-au"
import enCA from "utils/dt/en-ca"
import enGB from "utils/dt/en-gb"
import always from "ramda/src/always"
import { MethodMissingClass } from "unmiss"
import { quantify } from "hurdak/src/core"

copyOfMomentForReactDates.defineLocale("en-au", enAU)
copyOfMomentForReactDates.defineLocale("en-ca", enCA)
copyOfMomentForReactDates.defineLocale("en-gb", enGB)

moment.defineLocale("en-au", enAU)
moment.defineLocale("en-ca", enCA)
moment.defineLocale("en-gb", enGB)

TimeAgo.addLocale(en)

// Utils

export { moment }
export const guessTimezone = always(moment.tz.guess())
export const guessLocale = always("en_US")

export const formatDuration = ms => {
  const msPerMinute = 60_000
  const msPerHour = 60 * msPerMinute

  if (ms < 1000) return quantify(ms, "millisecond")
  if (ms < msPerMinute) return quantify(Math.round(ms / 1000), "second")
  if (ms < msPerHour) return quantify(Math.round(ms / msPerMinute), "minute")

  const hours = Math.round(ms / msPerHour)
  const minutes = Math.round((ms % msPerHour) / msPerMinute)

  let result = quantify(hours, "hour")

  if (minutes) {
    result = `${result} and ${quantify(minutes, "minute")}`
  }

  return result
}

export const getRelativeTime = date => {
  const _date = new Date(date)
  const timeAgo = new TimeAgo("en-US")
  const relativeTime = timeAgo.format(_date)

  return relativeTime
}

export const dtCtx = {
  getTimezone: guessTimezone,
  getLocale: guessLocale,
}

export const ALL_TIMEZONES = moment.tz.names()

const createMoment = (source, { format, timezone }) => {
  if (source instanceof Dt) {
    return source.m.clone()
  }

  if (source && source._isAMomentObject) {
    return source.clone()
  }

  // We need to set this globally for react-dates
  // https://github.com/airbnb/react-dates#localization
  moment.locale(dtCtx.getLocale())
  copyOfMomentForReactDates.locale(dtCtx.getLocale())

  // Default to local timezone, not utc
  timezone = timezone || dtCtx.getTimezone()

  const m = source ? moment.tz(source, format, timezone) : moment()

  if (!m.isValid || !m.isValid()) {
    throw new Error(`"${source}" (${format}, ${timezone}) created an invalid moment`)
  }

  // Use local timezone, since it's more common, and since we want to
  // proxy moment's datetime manipulation methods is the user's timezone
  // e.g., startOf('day') should be in local time
  return m.tz(dtCtx.getTimezone())
}

const methodMissing = (dt, name, ...args) => {
  if (!dt.m[name]) {
    throw new Error(`Invalid moment method ${name.toString()}`)
  }

  // Make sure everything is a moment
  args = args.map(v => (v && v.m && v.m._isAMomentObject ? v.m : v))

  const ret = dt.m.clone()[name](...args)

  // Re-wrap moments in a Dt object
  return ret._isAMomentObject ? new dt.constructor(ret) : ret
}

// We have some composite locale formats, hence the hackery
const normalizeLocaleFmt = fmt => {
  const localeData = moment.localeData(dtCtx.getLocale())

  return fmt
    .split(" ")
    .map(part => localeData.longDateFormat(part))
    .join(" ")
}

// Formats

export const DATE_FORMAT = "l"
export const DATE_FORMAT_LONG = "LL"
export const DATETIME_FORMAT = "l [at] LT"
export const TIME_FORMAT = "LT"
export const CC_DATE_FORMAT = "YYYY-MM-DD"
export const CC_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
export const CC_TIME_FORMAT = "HH:mm:ss"

// Dates

export class Dt extends MethodMissingClass {
  constructor(source, opts = {}, attrs = {}) {
    super()

    Object.assign(this, { isDate: false }, attrs, { m: createMoment(source, opts) })
  }
  methodMissing(...args) {
    return methodMissing(this, ...args)
  }
  [Symbol.toPrimitive]() {
    return this.m.valueOf()
  }
  format(...args) {
    // Don't completely override moment's format method, but do
    // ensure that utc, cc datetime format is the default. It's less
    // bad to show weird data than to corrupt the app with timezone-aware
    // datetimes
    if (args.length > 0) {
      return this.m.format(...args)
    }

    return this.formatDatetime()
  }
  formatDate() {
    // Dates are always local, not UTC
    return this.m.format(CC_DATE_FORMAT)
  }
  formatDatetime() {
    // Datetimes are always in utc
    return this.m.utc().format(CC_DATETIME_FORMAT)
  }
  formatUTCTime() {
    return this.m.utc().format(CC_TIME_FORMAT)
  }
  formatLocalTime() {
    return this.m.format(CC_TIME_FORMAT)
  }
  display() {
    return this.isDate ? this.displayDate() : this.displayDatetime()
  }
  displayDate() {
    return this.m.format(DATE_FORMAT)
  }
  displayLongDate() {
    return this.m.format(DATE_FORMAT_LONG)
  }
  displayDatetime() {
    return this.m.format(DATETIME_FORMAT)
  }
  displayTime() {
    return this.m.format(TIME_FORMAT)
  }
}

Dt.now = () => new Dt()

Dt.parse = source => {
  // Date strings are always localized
  if (typeof source === "string" && source.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return new Dt(source, {}, { isDate: true })
  }

  // Datetimes are always in UTC
  return new Dt(source, { timezone: "UTC" })
}

Dt.parseLocal = source => new Dt(source)

Dt.parseLocalDate = source => new Dt(source, { format: normalizeLocaleFmt(DATE_FORMAT) })

Dt.parseLocalDateLong = source => new Dt(source, { format: normalizeLocaleFmt(DATE_FORMAT_LONG) })

Dt.parseLocalDatetime = source => new Dt(source, { format: normalizeLocaleFmt(DATETIME_FORMAT) })

Dt.max = (a, b) => (a.isAfter(b) ? a : b)

Dt.min = (a, b) => (a.isBefore(b) ? a : b)

Dt.duration = (a, b) => moment.duration(b.diff(a.m))

if (typeof window !== "undefined") {
  window.Dt = Dt
}

export default Dt
