import "resources/css/components/tippy.css"

import React from "react"
import ReactDOM from "react-dom"
import tippy from "tippy.js"
import omit from "ramda/src/omit"
import is from "ramda/src/is"
import { T, BoundComponent } from "utils/react"

export class Tippy extends BoundComponent {
  state = {
    tooltip: null,
  }
  setup(el) {
    if (!el) {
      return
    }

    const { selector, isOpen } = this.props

    if (selector) {
      el = el.querySelector(selector)
    }

    const { instanceRef, ...opts } = omit(
      ["children", "render", "selector", "isOpen", "className", "style"],
      this.props,
    )

    const $modal = document.querySelector(".z-modal")
    const inModal = $modal && $modal.contains(el)

    if (inModal && !opts.zIndex) {
      opts.zIndex = 9999
    } else if (this.props.theme === "menu" && !opts.zIndex) {
      opts.zIndex = 5
    }

    const tooltip = tippy(el, {
      ...opts,
      appendTo: opts.appendTo || (() => document.body),
      triggerTarget: is(Boolean, isOpen) ? [] : opts.triggerTarget,
      interactive: true,
      ignoreAttributes: true,
      // Prevent it from hiding automatically; let the parent component manage this
      onHide: instance => {
        if (this.props.isOpen) {
          return false
        }
      },
    })

    this.setState({ tooltip })

    if (instanceRef) {
      instanceRef(tooltip)
    }
  }
  componentDidUpdate() {
    const { tooltip } = this.state
    const { isOpen } = this.props

    if (!tooltip) {
      return
    }

    if (isOpen === true) {
      tooltip.show()
    }

    if (isOpen === false) {
      tooltip.hide()
    }
  }
  componentWillUnmount() {
    const { tooltip } = this.state

    if (tooltip) {
      tooltip.destroy()
    }
  }
  render() {
    const { tooltip } = this.state
    const { className, style } = this.props

    return (
      <span className={className} ref={this.setup} style={style}>
        {this.props.children}
        {tooltip &&
          ReactDOM.createPortal(
            <div className="print:hidden ignore-react-onclickoutside">{this.props.render()}</div>,
            tooltip.popper.querySelector(".tippy-content"),
          )}
      </span>
    )
  }
}

Tippy.defaultProps = {
  theme: "default",
}

Tippy.propTypes = {
  isOpen: T.bool,
  selector: T.string,
  children: T.children.isRequired,
  render: T.func.isRequired,
  placement: T.string,
  instanceRef: T.func,
}
