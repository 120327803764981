import React from "react"
import { connect } from "react-redux"
import { SimpleModal } from "partials/Modal"
import { Button } from "partials/Button"
import { T, BoundComponent } from "utils/react"
import Core from "app/core/state"

const enhanceForceRefreshModal = connect(state => ({
  isOpen: Core.select.forceRefresh(state),
}))

export class ForceRefreshModal extends BoundComponent {
  reload() {
    window.location.reload()
  }
  render() {
    const { isOpen } = this.props

    return (
      <SimpleModal
        size="2xl"
        isOpen={isOpen}
        header="Update Available"
        footer={
          <Button theme="primary" onClick={this.reload}>
            Reload
          </Button>
        }
      >
        A new version of ConsignCloud is available. Please refresh the page or click below to get up
        to date.
      </SimpleModal>
    )
  }
}

ForceRefreshModal.propTypes = {
  isOpen: T.bool.isRequired,
}

export default enhanceForceRefreshModal(ForceRefreshModal)
