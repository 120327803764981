import { mergeAll, pipe } from "ramda"
import { createMapOf } from "hurdak/src/core"
import Db from "modules/db/state"
import Core from "app/core/state"
import Main from "app/main/state"
import Toast from "app/toast/state"

export const combine = pipe(createMapOf("ns", "reducer"), mergeAll)

export const getShellReducers = () => combine([Db, Core, Main, Toast])

export const getAppReducers = async () => {
  const {
    default: [ChecksEditor, Templatizer, ...reducers],
  } = await import("app/state/app-reducers")

  return mergeAll([
    getShellReducers(),
    combine(reducers),
    ChecksEditor.reducers,
    Templatizer.reducers,
  ])
}
