import { createStore, combineReducers } from "redux"
import logSlowReducers from "redux-log-slow-reducers"
import { CLEAR_ROOT_STATE } from "app/core/constants"
import enhance from "app/state/enhancers"

export { getShellReducers, getAppReducers } from "app/state/reducers"

export const makeRootReducer = reducers => {
  const combinedReducers = combineReducers(logSlowReducers(reducers))

  // Add a reducer to clear *all* app state
  // https://stackoverflow.com/a/35641992/1467342
  return (state, action) => {
    if (action.type === CLEAR_ROOT_STATE) {
      return combinedReducers({}, {})
    }

    return combinedReducers(state, action)
  }
}

export const makeStore = reducers => {
  const store = enhance(createStore)(makeRootReducer(reducers))

  window.app = window.app || {}
  window.app.store = store

  return store
}
