import React from "react"
import cx from "classnames"
import { Icon } from "partials/Icon"
import { Tippy } from "partials/Tippy"
import { Escapable } from "partials/Escapable"
import { T, BoundComponent } from "utils/react"

export default class Field extends BoundComponent {
  state = { showError: Boolean(this.props.error) }
  componentDidUpdate(oldProps) {
    if (oldProps.error !== this.props.error) {
      this.setState({ showError: true })
    }
  }
  onChange(value) {
    this.props.onChange(this.props.name, value)
  }
  hideError() {
    this.setState({ showError: false })
  }
  renderError() {
    const { error } = this.props

    return (
      <Escapable escape={this.hideError}>
        <Icon theme="warning" icon="warning" /> {error}
      </Escapable>
    )
  }
  render() {
    const { Input, inputRef, className, inputProps, noError, name, error, ...props } = this.props
    const { showError } = this.state

    return (
      <div className={cx(className)}>
        <Tippy
          isOpen={Boolean(error) && !noError && showError}
          render={this.renderError}
          placement="top-start"
        >
          <Input
            {...props}
            {...inputProps}
            ref={inputRef}
            onChange={this.onChange}
            name={Array.isArray(name) ? name.join(",") : name}
          />
        </Tippy>
      </div>
    )
  }
}

Field.defaultProps = {
  value: null,
}

Field.propTypes = {
  name: T.oneOfType([T.array, T.string]).isRequired,
  value: T.any,
  Input: T.component.isRequired,
  inputRef: T.any,
  noError: T.bool,
  className: T.string,
  error: T.string,
  inputProps: T.object,
  onChange: T.func.isRequired,
}
