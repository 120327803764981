import "resources/css/components/notification.css"

import React from "react"
import cx from "classnames"
import { Icon } from "partials/Icon"
import { T } from "utils/react"

export class Notification extends React.Component {
  render() {
    const { children, theme = "default", vCenter, onClose, ...props } = this.props

    const className = cx("notification", `notification-${theme}`, props.className, {
      "notification-vcenter": vCenter,
      "notification-has-actions": onClose,
    })

    return (
      <div {...props} className={className}>
        <div className="notification-body">{children}</div>
        {onClose && (
          <div className="notification-action">
            <Icon icon="times" onClick={onClose} />
          </div>
        )}
      </div>
    )
  }
}

Notification.propTypes = {
  children: T.children.isRequired,
  theme: T.oneOf(["warning", "danger", "success", "inset-default", "kinda-dark"]),
  vCenter: T.bool,
  onClose: T.func,
}

export class Notification2 extends React.Component {
  render() {
    const { theme = "default", ...props } = this.props
    const className = cx(props.className, `pl-4 border-l-4 border-solid border-${theme}`)

    return <div {...props} className={className} />
  }
}

Notification2.defaultProps = {
  theme: "gray-400",
}

Notification2.propTypes = {
  children: T.children.isRequired,
  theme: T.string.isRequired,
}
