import { isEmpty, keys, always, is, equals, path as getPath, assocPath } from "ramda"
import { ensurePlural, randomId } from "hurdak/src/core"
import { connect } from "react-redux"
import { Button } from "partials/Button"
import { Anchor } from "partials/Anchor"
import { bindleTemplate } from "modules/bindles"
import { getFormErrors } from "modules/forms/utils"
import SubmitButton from "modules/forms/SubmitButton"
import Form from "modules/forms/Form"
import Field from "modules/forms/Field"

const initialState = {
  key: null,
  initialized: false,
  isDirty: false,
  submitting: false,
  initialValues: {},
  values: {},
  errors: {},
  dirty: [],
}

export default bindleTemplate(({ ns, opts }) => {
  const createComponents = opts.createComponents || always({})
  const getInitialValues = opts.getInitialValues || always(always({}))
  const errorConfig = opts.errorConfig || { matchAll: true }
  const getErrors = opts.getFormErrors || (errors => getFormErrors(errorConfig, errors))
  const resetErrorsOnChange =
    opts.resetErrorsOnChange === undefined ? true : opts.resetErrorsOnChange

  return {
    initialState,
    reducers: {
      changeField: ({ values, errors, dirty, ...state }, { name, value }) => ({
        ...state,
        values: assocPath(ensurePlural(name), value, values),
        errors: resetErrorsOnChange ? {} : errors,
        dirty: dirty.concat([name]),
        isDirty: true,
      }),
      changeFields: ({ values, errors, dirty, ...state }, payload) => ({
        ...state,
        values: { ...values, ...payload },
        errors: resetErrorsOnChange ? {} : errors,
        dirty: [...dirty, ...keys(payload)],
        isDirty: true,
      }),
      failSubmission: (state, { errors }) => {
        return { ...state, submitting: false, errors }
      },
    },
    createActions: ({ select, actions }) => {
      const changeField = (name, value) => actions.changeField({ name, value })

      const initialize = () => async (dispatch, getState) => {
        // Only show an uninitialized state if initialization takes time
        let asyncDone = false

        setTimeout(() => asyncDone || dispatch(actions.setState({ initialized: false })), 10)

        const values = await dispatch(getInitialValues())

        asyncDone = true

        dispatch(
          actions.setState({
            values,
            dirty: [],
            errors: {},
            submitting: false,
            initialValues: values,
            initialized: true,
            isDirty: false,
            key: randomId(),
          }),
        )
      }

      const setErrors = errors => async dispatch => {
        dispatch(
          actions.setState({
            errors,
          }),
        )
      }

      const onChange = (name, value) => (dispatch, getState) => {
        const currentValue = getPath(ensurePlural(name), select.values(getState()))

        // Avoid unnecessary re-renders; some inputs return non-primitives,
        // which can differ by identity but not value.
        if (equals(value, currentValue)) {
          return
        }

        dispatch(changeField(name, value))

        if (opts.onChange) {
          dispatch(opts.onChange(name, value))
        }
      }

      const onSubmit = overrides => async (dispatch, getState) => {
        dispatch(actions.setState({ submitting: true, errors: {} }))

        // Even though this is in a try-catch, don't throw from onSubmit,
        // since our error handler middleware will catch that. Use
        // Promise.reject instead
        try {
          await dispatch(opts.onSubmit({ ...select.values(getState()), ...overrides }))
        } catch (e) {
          if (is(Array, e)) {
            return dispatch(actions.failSubmission({ errors: getErrors(e) }))
          }

          if (e.code) {
            return dispatch(actions.failSubmission({ errors: getErrors([e]) }))
          }

          throw e
        } finally {
          dispatch(actions.setState({ submitting: false, isDirty: false }))
        }
      }

      return { changeField, initialize, onChange, onSubmit, setErrors }
    },
    createComponents: ({ select, actions }) => {
      const enhanceForm = connect(
        state => ({
          name: ns,
          persistOnUnmount: opts.persistOnUnmount,
          initialized: select.initialized(state),
          hasError: !isEmpty(select.errors(state)),
        }),
        {
          setInitialized: initialized => actions.setState({ initialized }),
          initialize: actions.initialize,
          onSubmit: actions.onSubmit,
        },
      )

      const enhanceField = connect(
        (state, { name }) => ({
          value: getPath(ensurePlural(name), select.values(state)),
          error: getPath(ensurePlural(name), select.errors(state)),
        }),
        {
          onChange: actions.onChange,
        },
      )

      const enhanceSubmitButton = connect(
        state => ({
          isLoading: select.submitting(state),
        }),
        {
          onSubmit: actions.onSubmit,
        },
      )

      const enhanceFormButton = connect(
        state => ({
          isLoading: select.submitting(state),
        }),
        {},
      )

      const enhanceResetButton = connect(
        (state, { children }) => ({
          isDisabled: !select.isDirty(state) || select.submitting(state),
          children: children || "Clear Changes",
        }),
        {
          onClick: actions.initialize,
        },
      )

      const components = {
        Form: enhanceForm(Form),
        Field: enhanceField(Field),
        FormButton: enhanceFormButton(Button),
        SubmitButton: enhanceSubmitButton(SubmitButton),
        ResetButton: enhanceResetButton(Anchor),
      }

      return {
        ...components,
        ...createComponents({ select, actions, components }),
      }
    },
  }
})
