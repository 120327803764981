import { filter, equals, reject, whereEq, when } from "ramda"
import { mergeRight } from "hurdak/src/core"

export default () => {
  return {
    initialState: [],
    reducers: {
      // Disable set state since we're not using an object at the root
      setState: null,
      set: (state, payload) => payload,
      append: (state, payload) => state.concat(payload),
      filter: (state, payload) => filter(whereEq(payload), state),
      reject: (state, payload) => reject(whereEq(payload), state),
      remove: (state, payload) => reject(equals(payload), state),
      update: (state, { where, values }) => state.map(when(whereEq(where), mergeRight(values))),
    },
  }
}
