/* eslint no-console: 0, prefer-destructuring: 0 */

import Bugsnag from "@bugsnag/js"
import { contains } from "ramda"

const logLevels = ["debug", "info", "warning", "error", "critical"]
const LOG_LEVEL = process.env.LOG_LEVEL || "error"

const levelGte = (threshold, level) => logLevels.indexOf(level) >= logLevels.indexOf(threshold)

// Logger functions

const Logger = {
  log: (level, { message = "unknown error", err = null, payload = {} }) => {
    // Log to console
    if (levelGte(LOG_LEVEL, level)) {
      const errString = err instanceof Error ? err.toString() : JSON.stringify(err)

      if (level === "warning") {
        level = "warn"
      } else if (level === "debug") {
        level = "info"
      }

      console[level](message, errString, payload)
    }

    if (err) {
      Bugsnag.notify(err)
    }
  },
}

// Quick proxy handler to add debug/info etc methods

export default new Proxy(Logger, {
  get(target, name) {
    if (contains(name, logLevels)) {
      return data => target.log(name, data)
    }

    return target[name]
  },
})
