import { when, contains, omit, partial, always, filter, identity, merge, is, test } from "ramda"
import { modifyKeysRecursive, mergeRight, toCamel, noopPromise } from "hurdak/src/core"
import { toSnake } from "utils/misc"
import { stringify } from "qs"

const baseUrl = process.env.CCAPI_URL

const isCamel = test(/^[a-zA-Z0-9|]+$/)
const isSnake = test(/^[a-z0-9_|]+$/)

const jsonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

// Helpers

const prepBody = (headers, text) => {
  switch (headers["Content-Type"]) {
    case jsonHeaders["Content-Type"]:
      return JSON.stringify(text)
    default:
      return text
  }
}

const parseText = (headers, parseResponse, text) => {
  const _parseResponse = parseResponse || partial(modifyKeysRecursive, [when(isSnake, toCamel)])
  switch (headers.Accept) {
    case jsonHeaders.Accept:
      return _parseResponse(JSON.parse(text))
    default:
      return text
  }
}

// ============================================================================
// Request methods

export const createClient = config => {
  config = mergeRight(config, {
    errorHandlers: {},
    postFetch: res => () => res,
    selectHeaders: always({}),
  })

  const buildUrl = (path, params) => {
    const paramString = params ? `?${stringify(params, { arrayFormat: "brackets" })}` : ""

    return `${baseUrl}${path}${paramString}`
  }

  // fetch is a promise-returning method, which acts as the basic api for
  // normalized http requests - it's a very thin layer around window.fetch,
  // with query string building, body preparation based on content type headers,
  // and rejection on all non-ok responses

  const fetch = (url, { params, headers, body, onSuccess, onError, ...opts }) => {
    const fullUrl = buildUrl(url, params)

    opts = {
      ...opts,
      // Remove empty headers, default headers to empty object
      headers: filter(identity, headers || {}),
      // Convert body data to text
      body: prepBody(headers, body),
    }

    // Don't include body for head/get
    if (contains(opts.method, ["HEAD", "GET"])) {
      opts = omit(["body"], opts)
    }

    // wrap fetch in a promise so we have utils/promise all the way down
    return (
      Promise.resolve(window.fetch(fullUrl, opts))
        // Fetch only rejects on a network error; we want to reject on all non-200s
        .then(res => (res.ok ? res : Promise.reject(res)))
    )
  }

  // fetchWithConfig is a thunk that pulls in headers and error handlers

  const fetchWithConfig = (url, opts) => (dispatch, getState) => {
    // Add in configurable headers
    const headers = merge(config.selectHeaders(getState()), opts.headers || {})

    return fetch(url, { ...opts, headers })
      .then(res => dispatch(config.postFetch(res)))
      .catch(err => {
        if (err.status === 401) {
          dispatch(config.errorHandlers.unauthorized(err))
        } else if (err.status === 503 && err.headers.get("x-reason") === "feature_unavailable") {
          dispatch(config.errorHandlers.featureUnavailable(err))
        } else if (err.status === 503) {
          dispatch(config.errorHandlers.appUnavailable(err))
        } else if (err.status > 499 && config.errorHandlers.server) {
          dispatch(config.errorHandlers.server(err))
        } else if (!err.status && config.errorHandlers.failure) {
          dispatch(config.errorHandlers.failure(err))
        } else {
          throw err
        }

        return noopPromise
      })
  }

  // req is a thunk that handles casing conventions and some shortcuts for error/
  // success handlers

  const req =
    (url, { headers, body, onSuccess, onError, ...opts } = {}) =>
    dispatch => {
      // Merge in default headers; remove empty ones if overridden
      headers = merge(jsonHeaders, headers || {})

      // Take care of casing conventions
      if (opts.modifyBody) {
        body = opts.modifyBody(body)
      } else if (is(Object, body)) {
        body = modifyKeysRecursive(when(isCamel, toSnake), body)
      }

      // Allow overriding res -> data conversion
      onSuccess =
        onSuccess ||
        (res => {
          return res.text().then(partial(parseText, [headers, opts.parseResponse]))
        })

      // Allow overriding res -> error conversion
      onError =
        onError ||
        (res => {
          // Treat it like a response object with a bad code, but if it's an actual
          // error re-throw it

          try {
            return res
              .text()
              .then(JSON.parse)
              .then(data => Promise.reject(data))
          } catch (err) {
            throw res
          }
        })

      return dispatch(fetchWithConfig(url, { ...opts, headers, body }))
        .then(onSuccess)
        .catch(onError)
    }

  const get = (url, opts = {}) => req(url, { ...opts, method: "GET" })
  const post = (url, opts = {}) => req(url, { ...opts, method: "POST" })
  const put = (url, opts = {}) => req(url, { ...opts, method: "PUT" })
  const patch = (url, opts = {}) => req(url, { ...opts, method: "PATCH" })
  const del = (url, opts = {}) => req(url, { ...opts, method: "DELETE" })

  return { buildUrl, fetch, fetchWithConfig, req, get, post, put, patch, del }
}
