import { last, is } from "ramda"
import { ucFirst, toCamel, ensurePlural } from "hurdak/src/core"
import Logger from "modules/logging"

function getErrorPath({ path, key }) {
  return (path ? path.filter(is(String)).join(".") : key) || "*"
}

function matchError({ matchCode, matchPath, matchAll }, error) {
  if (matchAll) {
    return true
  }

  if (matchCode && matchCode === error.code) {
    return true
  }

  if (matchPath && matchPath === getErrorPath(error)) {
    return true
  }

  return false
}

function getErrorKey({ key, getKey }, error) {
  const path = getErrorPath(error)

  return getKey ? getKey(path) : toCamel(key || error.key || last(path.split(".")))
}

export function getErrorMessage({ message, getMessage }, error) {
  if (message) {
    return message
  }

  if (getMessage) {
    return getMessage(error)
  }

  return ucFirst(error.message || error.description)
}

export function getFormErrors(strategies, errors) {
  const result = {}

  // Iterate through the error handling strategies in order, filtering
  // out all errors that have been handled as we go and adding to result
  ensurePlural(strategies).forEach(strategy => {
    errors = errors.filter(error => {
      // If the error didn't match, keep it in the array as un-handled
      if (!matchError(strategy, error)) {
        return true
      }

      // Add it to our result
      result[getErrorKey(strategy, error)] = getErrorMessage(strategy, error)

      // Remove it from the errors array
      return false
    })
  })

  // If we have any errors left, we should know
  if (errors.length) {
    Logger.warning({ message: `Unhandled form errors`, payload: { errors } })
  }

  return result
}
