import "resources/css/components/toast.css"

import React from "react"
import cx from "classnames"
import { Link } from "react-router-dom"
import { switcherFn } from "hurdak/src/core"
import { Icon } from "partials/Icon"
import { ExternalLink } from "partials/ExternalLink"
import { Anchor } from "partials/Anchor"
import { BoundComponent } from "utils/react"
import { NBSP } from "utils/html"
import storage from "hurdak/src/storage"
import { getEntityPath } from "modules/domain/model"

const resetSession = () => {
  storage.clear()

  window.location.reload()
}

let failures = 0

const renderToast = ({ props: { id, type, message, downloadLink, sku }, close }) => {
  if (type !== "failure") {
    failures = 0
  }

  return switcherFn(type, {
    default: () => message,
    success: () => message || "Your changes have been saved!",
    failure() {
      if (message) {
        return message
      }

      failures += 1

      return (
        <span>
          Oops, looks like something went wrong - please try again!
          {failures > 2 && (
            <span>
              {" "}
              If the problem persists,{" "}
              <Anchor onClick={resetSession}>try clearing your session</Anchor>.
            </span>
          )}
        </span>
      )
    },
    exportReady() {
      return (
        <span>
          Your export is ready, please click
          {NBSP}
          <ExternalLink href={downloadLink} onClick={close}>
            here
          </ExternalLink>{" "}
          to download.
        </span>
      )
    },
    itemEntrySuccess() {
      return (
        <span>
          Item <Link to={getEntityPath("item", "detail", id)}>#{sku}</Link> has been successfully
          created.
        </span>
      )
    },
    passwordResetFailure() {
      return (
        <span>
          It looks like that reset code has expired. Please click{" "}
          <Anchor onClick="/password/reset/request">here</Anchor> to try again.
        </span>
      )
    },
    labelSizeWarning() {
      return (
        <span>
          An image in your label is too large. Please remove it and upload a smaller version.
        </span>
      )
    },
  })
}

export default class Toast extends BoundComponent {
  close() {
    const { id } = this.props

    this.props.pop(id)
  }
  render() {
    const { type, theme, visible } = this.props
    const className = cx(`toast toast-${theme || type} print:hidden`, { "toast-visible": visible })

    return (
      <div className={className}>
        <div className="toast-message">{renderToast(this)}</div>
        <div className="toast-actions ignore-react-onclickoutside">
          <Icon icon="times" onClick={this.close} />
        </div>
      </div>
    )
  }
}
