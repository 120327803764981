import React from "react"
import cx from "classnames"
import { CSSTransition } from "react-transition-group"
import { T, BoundComponent } from "utils/react"

export class Animate extends BoundComponent {
  render() {
    const { children, className, transitionName, visible, onClick } = this.props

    return (
      <CSSTransition
        className={cx(className, `animation-${transitionName}`)}
        classNames={`animation-${transitionName}`}
        in={visible}
        onClick={onClick}
        timeout={200}
        unmountOnExit
      >
        <div className={className}>{children}</div>
      </CSSTransition>
    )
  }
}

Animate.defaultProps = {
  transitionName: "fade",
}

Animate.propTypes = {
  children: T.children,
  visible: T.any.isRequired,
  transitionName: T.string,
  className: T.string,
  onClick: T.func,
}
