import { last } from "ramda"

let shop = null

const getShop = () => shop

const setShop = s => {
  shop = s
}

const parseGid = id => (id ? last(id.split("/")) : null)

const buildUrl = (path, gid) =>
  shop && gid ? `https://${shop}/admin/${path}/${parseGid(gid)}` : null

export default { getShop, setShop, parseGid, buildUrl }
