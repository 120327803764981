import React from "react"
import { connect } from "react-redux"
import { Anchor } from "partials/Anchor"
import { Modal } from "partials/Modal"
import { CardBottom, CardBottomHeader, CardBottomFooter } from "partials/Card"
import { ConsignCloudLogo } from "partials/Image"
import { Notification } from "partials/Notification"
import { BoundComponent } from "utils/react"
import { StringInput } from "modules/inputs/StringInput"
import State from "app/main/state"

const { Form, Field, SubmitButton } = State.resetPasswordForm.components

const enhance = connect(state => ({
  email: State.resetPasswordForm.select.values(state).email,
}))

export default enhance(
  class ResetPassword extends BoundComponent {
    render() {
      const { email } = this.props

      return (
        <Modal isOpen size="xl">
          <Form>
            <CardBottom>
              <CardBottomHeader className="p-8">
                <ConsignCloudLogo />
              </CardBottomHeader>
              <Notification theme="inset-default" className="mb-4">
                Enter a new password for {email} below.
              </Notification>
              <Field
                autoFocus
                name="password"
                Input={StringInput}
                inputProps={{ type: "password", className: "w-full" }}
                placeholder="Enter your new password"
              />
              <CardBottomFooter>
                <Anchor onClick="/">Back to Login</Anchor>
                <SubmitButton>Reset Password</SubmitButton>
              </CardBottomFooter>
            </CardBottom>
          </Form>
        </Modal>
      )
    }
  },
)
