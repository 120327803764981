import React from "react"
import onClickOutside from "react-onclickoutside"
import { BoundComponent, wrapperDisplayName } from "utils/react"

export const escapable = InnerComponent => {
  class OnEscape extends BoundComponent {
    componentDidMount() {
      window.addEventListener("keydown", this.onEsc)
    }
    componentWillUnmount() {
      window.removeEventListener("keydown", this.onEsc)
    }
    handleClickOutside(evt) {
      !this.props.ignoreClicksOutside && this.child.escape(evt)
    }
    onEsc(evt) {
      if (evt.key === "Escape") {
        this.child.escape(evt)
      }
    }
    setChild(child) {
      if (child) {
        this.child = child
      }
    }
    render() {
      return <InnerComponent ref={this.setChild} {...this.props} />
    }
  }

  OnEscape.displayName = wrapperDisplayName("OnEscape", InnerComponent)

  return onClickOutside(OnEscape)
}

export const Escapable = escapable(
  class Escapable extends BoundComponent {
    escape() {
      this.props.escape()
    }
    render() {
      return this.props.children
    }
  },
)
