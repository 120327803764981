import "resources/css/components/chip.css"

import React from "react"
import cx from "classnames"
import { Icon } from "partials/Icon"
import { MaybeLink } from "partials/MaybeLink"
import { T, BoundComponent } from "utils/react"

export class Chip extends BoundComponent {
  onRemove(evt) {
    evt.stopPropagation()

    const { value } = this.props

    this.props.onRemove(value)
  }
  render() {
    const { theme, size, onClick, onRemove, children, className } = this.props
    const _className = cx(`chip chip-${theme} chip-${size}`, className, { pointer: onClick })

    return (
      <MaybeLink onClick={onClick} className={_className}>
        {onRemove ? (
          <div className="flex">
            <span>{children}</span>
            <span className="chip-remove" onClick={this.onRemove}>
              <Icon icon="times" />
            </span>
          </div>
        ) : (
          children
        )}
      </MaybeLink>
    )
  }
}

Chip.defaultProps = {
  theme: "default",
  size: "medium",
}

Chip.propTypes = {
  theme: T.oneOf(["default", "dark", "faded", "faded-light"]),
  size: T.oneOf(["tiny", "small", "medium", "large"]),
  onClick: T.any,
  onRemove: T.func,
  children: T.children,
  value: T.any,
}
