/* eslint no-console: 0 */

import is from "ramda/src/is"
import { offlineErrors } from "utils/misc"
import Bugsnag from "@bugsnag/js"
import Logger from "modules/logging/utils"

export const loggerMiddleware =
  ({ selectMeta, notifyUser }) =>
  store => {
    window.addEventListener("unhandledrejection", evt => {
      const err = evt.reason

      // If it's a 500, give them a generic error, but don't log it - we'll get a
      // better error from the backend
      if (is(Array, err) && err[0].code === "500_catchall") {
        evt.preventDefault()

        return store.dispatch(
          notifyUser({
            message: "Something went wrong - please wait a few minutes and try again.",
          }),
        )
      }

      // If they're offline, tell them and we're done
      if (offlineErrors.includes(err?.message)) {
        evt.preventDefault()

        return store.dispatch(
          notifyUser({
            message: `
            It looks like you may be offline - please check your internet connection
            and try again.`,
          }),
        )
      }

      // Give the user a generic error message
      store.dispatch(notifyUser())
    })

    // Notify bugsnag
    Bugsnag.addOnError(event => {
      if (process.env.NODE_ENV !== "production") {
        return false
      }

      const meta = selectMeta(store.getState())

      if (meta.store) {
        event.addMetadata("store", {
          id: meta.store.id,
          name: meta.store.name,
          email: meta.store.email,
        })
      }

      if (meta.user) {
        event.setUser(meta.user.id, meta.user.email, meta.user.username)
      }
    })

    return next => action => {
      // If it's a thunk, we're done here
      if (is(Function, action)) {
        return next(action)
      }

      // Catch dom events that sneak into redux
      if (!action || !action.type) {
        return Logger.error({ message: "invalid action dispatched", payload: action })
      }

      // Tell users about errors
      let result
      try {
        result = next(action)
      } catch (err) {
        store.dispatch(notifyUser(err))

        throw err
      }

      // Log actions/state
      if (process.env.LOG_REDUX_ACTIONS === "true" && !is(Function, action)) {
        console.groupCollapsed(action.type)
        console.log(action.payload)
        console.log(store.getState())
        console.groupEnd()
      }

      return result
    }
  }
