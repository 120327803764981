import { identity } from "ramda"
import { fuzzy } from "utils/misc"
import { ALL_TIMEZONES } from "utils/dt"
import { withProps } from "utils/react"
import { mapOptions } from "modules/inputs/SelectInput"
import { FancySelectInput } from "modules/inputs/FancySelectInput"

const timezoneOptions = mapOptions(ALL_TIMEZONES, identity, identity)

export const TimezoneInput = withProps(FancySelectInput, {
  loadOptions: fuzzy(timezoneOptions, { keys: ["value"] }),
})
