import { defineEnum } from "utils/misc"

export const LOCALE = defineEnum(["en", "en-gb", "en-ca", "en-au", "fr-ca"])
export const COUNTRY = defineEnum(["US", "GB", "CA", "AU"])
export const CURRENCY = defineEnum(["$", "£", "€"])
export const ROLE = defineEnum(["admin", "employee", "clerk"])
export const EMAIL_TEMPLATE_TYPE = defineEnum([
  "welcome",
  "receipt",
  "items_received",
  "items_returned",
  "items_sold",
])
export const INVENTORY_TYPE = defineEnum(["consignment", "buy_outright", "retail"])
export const WEBHOOK_TOPIC = defineEnum([
  "item.sold",
  "item.stocked",
  "item.created",
  "item.updated",
  "batch.changes_requested",
  "purchase_order.created",
])
export const ITEM_STATUS = defineEnum([
  "active",
  "lost",
  "damaged",
  "donated",
  "stolen",
  "sold",
  "parked",
  "to_be_returned",
  "returned_to_owner",
  "sold_on_shopify",
  "sold_on_square",
])
export const ITEM_TERMS = defineEnum(["return_to_consignor", "donate"])
export const VALUE_TYPE = defineEnum(["percent", "fixed"])
export const PAYMENT_TYPE = defineEnum([
  "credit_card",
  "cash",
  "store_credit",
  "check",
  "manual",
  "gift_card",
])
export const SALE_STATUS = defineEnum(["finalized", "parked", "voided"])
export const LINE_ITEM_LEVEL = defineEnum(["global", "local"])
export const SURCHARGE_TYPE = defineEnum(["consignor", "customer", "credit_card"])
export const DISCOUNT_TYPE = defineEnum(["store", "customer", "consignor"])
export const TAX_TYPE = defineEnum(["inclusive", "exclusive"])
export const ITEM_PRICE_FIELDS = defineEnum(["split_price", "tag_price"])
export const WEIGHT_UNIT = defineEnum(["kilograms", "grams", "pounds", "ounces"])
export const QUANTITY_CHANGE_REASON = defineEnum([
  "stocked",
  "recounted",
  "lost",
  "damaged",
  "donated",
  "stolen",
  "sold",
  "parked",
  "unparked",
  "sale_voided",
  "returned",
  "return_voided",
  "sold_on_shopify",
  "sold_on_square",
  "returned_on_shopify",
  "status_changed",
  "to_be_returned",
  "returned_to_owner",
])
export const CASH_ADJUSTMENT_TYPES = defineEnum(["cash_in", "cash_out"])
export const TILL_REPORT_ENTRY_TYPE = defineEnum([
  "manual_adjustment",
  "cash_payment",
  "payment_canceled",
  "balance_settled",
  "change",
  "till_opened",
])

export const discountTypeOptions = [
  { value: DISCOUNT_TYPE.CUSTOMER, label: "Shared between store and consignor portion" },
  { value: DISCOUNT_TYPE.STORE, label: "Taken from store portion only" },
  { value: DISCOUNT_TYPE.CONSIGNOR, label: "Taken from consignor portion only" },
]

export const taxTypeOptions = [
  { value: TAX_TYPE.EXCLUSIVE, label: "Add to Tag Price" },
  { value: TAX_TYPE.INCLUSIVE, label: "Include in Tag Price" },
]

export const surchargeTypeOptions = [
  { value: SURCHARGE_TYPE.CUSTOMER, label: "Buyer's Fee" },
  { value: SURCHARGE_TYPE.CONSIGNOR, label: "Consignor" },
  { value: SURCHARGE_TYPE.CREDIT_CARD, label: "Credit Card" },
]
