import qs from "qs"
import { createBrowserHistory } from "history"

const history = createBrowserHistory()

history.getQueryString = () => qs.parse(history.location.search.slice(1))

history.pushWithSearch = (path, search) => history.push(`${path}?${qs.stringify(search)}`)

// Expose history for integration tests
window.app = window.app || {}
window.app.history = history

export default history
