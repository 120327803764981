import React from "react"
import { Link } from "react-router-dom"
import cx from "classnames"
import is from "ramda/src/is"
import startsWith from "ramda/src/startsWith"
import { clickHandler } from "utils/html"
import { ExternalLink } from "partials/ExternalLink"

export const MaybeLink = ({ Component = "span", onClick, ...props }) => {
  if (!onClick) {
    return <Component tabIndex={-1} {...props} />
  }

  if (!is(String, onClick)) {
    return <Component {...props} {...clickHandler(onClick)} />
  }

  const className = cx(props.className, "anchor-unstyled")

  if (startsWith("http", onClick)) {
    return <ExternalLink {...props} href={onClick} className={className} />
  }

  return <Link {...props} to={onClick} className={className} />
}
