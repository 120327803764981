import React from "react"
import { connect } from "react-redux"
import { SimpleModal } from "partials/Modal"
import { BoundComponent } from "utils/react"
import Core from "app/core/state"

const enhance = connect(state => ({
  isOpen: Core.select.maintenanceMode(state),
}))

export default enhance(
  class MaintenanceModeModal extends BoundComponent {
    render() {
      const { isOpen } = this.props

      return (
        <SimpleModal size="xl" isOpen={isOpen} header="Maintenance Mode" footer=" ">
          ConsignCloud is currently undergoing maintenance. Sit tight, we should be back in just a
          few minutes!
        </SimpleModal>
      )
    }
  },
)
