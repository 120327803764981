import posthog from "posthog-js"
import { createSelector } from "reselect"
import { pathEq, sortBy, omit, prop, path, pipe } from "ramda"
import { first, toCamel, updateIn } from "hurdak/src/core"
import storage from "hurdak/src/storage"
import { fuzzy } from "utils/misc"
import { Dt, guessTimezone, guessLocale } from "utils/dt"
import Api from "modules/domain/api"
import { locationMatches } from "modules/domain"
import { attrExists } from "modules/domain/model"
import { ITEM_TERMS } from "modules/domain/types"
import { compileBindle } from "modules/bindles"
import Db from "modules/db/state"
import history from "utils/history"
import { CLEAR_ROOT_STATE } from "app/core/constants"
import Toast from "app/toast/state"

let maintenanceModeTimeout = null

const authStorage = storage.cursor("core/auth", {
  sessionId: null,
  impersonation: null,
})

const deviceStorage = storage.cursor("core/device", {
  tillId: null,
})

const getInitialState = () => ({
  online: true,
  whoami: null,
  location: history.location,
  maintenanceMode: false,
  forceRefresh: false,
  lastStoreId: null,
  auth: authStorage.get(),
  device: deviceStorage.get(),
})

export default compileBindle("core", {
  initialState: getInitialState(),
  reducers: {
    // Same as setState, but used in middleware
    setSynced: (state, payload) => ({ ...state, ...payload }),
  },
  createSelectors: ({ select }) => {
    const selectStore = pipe(select.whoami, path(["store"]))
    const selectModel = pipe(select.whoami, path(["model"]))
    const selectSquare = pipe(select.whoami, path(["square"]))
    const selectDocusign = pipe(select.whoami, path(["docusign"]))
    const selectShopify = pipe(select.whoami, path(["shopify"]))
    const selectBilling = pipe(select.whoami, path(["billing"]))
    const selectUserId = pipe(select.whoami, path(["session", "user"]))
    const selectVendor = pipe(select.whoami, path(["vendor"]))

    const selectSessionId = pipe(select.auth, path(["sessionId"]))
    const selectImpersonating = pipe(select.auth, path(["impersonating"]))

    const selectUser = createSelector(selectUserId, Db.select.interface, (userId, db) =>
      db.oneActive(userId),
    )

    const selectDefaultLabel = createSelector(
      selectStore,
      Db.select.interface,
      ({ defaultLabel }, db) => defaultLabel || prop("id", first(db.all("label"))),
    )

    const selectLocationTills = createSelector(Db.select.interface, selectUser, (db, user) =>
      db.active("till").filter(locationMatches(user)),
    )

    const selectSearchLocationTills = createSelector(selectLocationTills, tills =>
      fuzzy(tills, { keys: ["name"], threshold: 0.3 }),
    )

    const selectLocationShelves = createSelector(Db.select.interface, selectUser, (db, user) =>
      db.active("shelf").filter(locationMatches(user)),
    )

    const selectSearchLocationShelves = createSelector(selectLocationShelves, shelves =>
      fuzzy(shelves, { keys: ["name"], threshold: 0.3 }),
    )

    const selectLocale = state => prop("locale", selectStore(state)) || guessLocale()
    const selectTimezone = state => prop("timezone", selectStore(state)) || guessTimezone()
    const selectCountry = state => prop("country", selectStore(state)) || "USA"
    const selectCurrencySymbol = state => prop("currencySymbol", selectStore(state)) || "$"

    const selectIsUS = createSelector(
      selectCountry,
      selectCurrencySymbol,
      (country, currencySymbol) =>
        currencySymbol === "$" &&
        ["us", "usa", "united states", "united states of america"].includes(country.toLowerCase()),
    )

    const selectIsCA = createSelector(selectCountry, country =>
      ["canada"].includes(country.toLowerCase()),
    )

    const selectIsDemo = createSelector(
      selectStore,
      ({ email }) =>
        email === "demo@consigncloud.com" && window.location.host === "app.consigncloud.com",
    )

    const selectIsRecentSignup = createSelector(selectStore, ({ created }) =>
      Dt.parse(created).isAfter(Dt.now().subtract(30, "days")),
    )

    const selectIsFree = createSelector(selectStore, store => prop("distro", store) === "free")

    const selectPayoutOptions = createSelector(selectStore, ({ payoutTypes, checkbookLinked }) => {
      const options = [{ label: "Select one", value: { name: null } }]

      if (checkbookLinked) {
        options.push({
          label: "Integrated ACH with Checkbook",
          value: {
            type: "integrated",
            name: "checkbook",
          },
        })
      }

      for (const payoutType of payoutTypes) {
        options.push({
          label: payoutType,
          value: {
            type: "custom",
            name: payoutType,
          },
        })
      }

      return options
    })

    // Db and model get out of sync briefly when reloading whoami
    const selectItemFields = createSelector(Db.select.interface, db =>
      sortBy(
        prop("idx"),
        db
          .active("customField")
          .map(x => ({ ...x, attrName: toCamel(x.slug) }))
          .filter(
            ({ attrName, entityType }) => entityType === "item" && attrExists("item", attrName),
          ),
      ),
    )

    const selectAccountFields = createSelector(Db.select.interface, db =>
      sortBy(
        prop("idx"),
        db
          .active("customField")
          .map(x => ({ ...x, attrName: toCamel(x.slug) }))
          .filter(
            ({ attrName, entityType }) =>
              entityType === "contact" && attrExists("contact", attrName),
          ),
      ),
    )

    return {
      store: selectStore,
      model: selectModel,
      square: selectSquare,
      docusign: selectDocusign,
      shopify: selectShopify,
      billing: selectBilling,
      sessionId: selectSessionId,
      impersonating: selectImpersonating,
      user: selectUser,
      vendor: selectVendor,
      defaultLabel: selectDefaultLabel,
      locationTills: selectLocationTills,
      searchLocationTills: selectSearchLocationTills,
      locationShelves: selectLocationShelves,
      searchLocationShelves: selectSearchLocationShelves,
      locale: selectLocale,
      country: selectCountry,
      timezone: selectTimezone,
      currencySymbol: selectCurrencySymbol,
      till: createSelector(select.device, Db.select.interface, ({ tillId }, db) =>
        db.oneActive(tillId),
      ),
      isUS: selectIsUS,
      isCA: selectIsCA,
      isDemo: selectIsDemo,
      isRecentSignup: selectIsRecentSignup,
      isLite: selectIsFree,
      printnodeKey: pipe(selectStore, prop("printnodeKey")),
      useEmergepay: createSelector(selectStore, ({ creditCardImplementation, emergepayConfig }) =>
        Boolean(creditCardImplementation === "emergepay" && emergepayConfig.length),
      ),
      getUserSetting: createSelector(
        Db.select.interface,
        selectUser,
        (db, user) =>
          ({ ns, ...info }, defaultValue) =>
            db.setting({ ns: `${ns}/${user.id}`, ...info }, defaultValue),
      ),
      payoutOptions: selectPayoutOptions,
      itemFields: selectItemFields,
      accountFields: selectAccountFields,
    }
  },
  createActions: ({ select, actions: { setSynced, setState } }) => {
    const loadWhoami = () => async (dispatch, getState) => {
      const { lastStoreId, device } = select.root(getState())
      const whoami = await dispatch(Api.store.get("whoami"))

      // If they're logging in with a different store, they lose all their saved
      // data from last session
      const resetDevice = lastStoreId && whoami.store.id !== lastStoreId
      const newDevice = resetDevice ? {} : device

      const user = whoami.session.user
        ? whoami.db.user.find(user => user.id === whoami.session.user)
        : null

      const auth = select.auth(getState())

      if (
        user?.email &&
        whoami.store.id !== "29a36875-6918-4a79-a970-c5ca855deb73" &&
        !auth?.impersonating
      ) {
        posthog.identify(user.email, {
          store_id: whoami.store.id,
          id: user.id,
          role: user.role,
          created: user.created,
          is_vendor: user.isVendor,
          is_owner: user.isOwner,
        })
        posthog.group("store", whoami.store.id, {
          distro: whoami.store.distro,
          modules: whoami.store.modules,
          number_of_modules: whoami.store.modules.length,
          created: whoami.store.created,
          name: whoami.store.name,
          email: whoami.store.email,
          country: whoami.store.country,
          currency_code: whoami.store.currencyCode,
        })
      }

      // Set whoami last, since App.js transitions to another screen that needs the
      // above info once userId is set.
      dispatch(setSynced({ whoami, device: newDevice, lastStoreId: whoami.store.id }))
    }

    const setAuth = auth => async (dispatch, getState) => {
      const newAuth = { ...select.auth(getState()), ...auth }

      authStorage.set(newAuth)

      dispatch(setSynced({ auth: newAuth }))

      await dispatch(loadWhoami())
    }

    const saveToDevice = device => (dispatch, getState) => {
      const newDevice = { ...select.device(getState()), ...device }

      deviceStorage.set(newDevice)

      dispatch(setSynced({ device: newDevice }))
    }

    const loginUser = params => async dispatch => {
      await dispatch(Api.session.post("user", params))
      await dispatch(loadWhoami())
    }

    const sendVendorOTP = params => async dispatch => {
      const { passwordIsSet } = await dispatch(Api.session.post("vendor/otp", params))

      history.push(
        `/login/vendor/confirm?storeSlug=${params.storeSlug}&email=${params.email}&passwordIsSet=${passwordIsSet}`,
      )
    }

    const loginVendor = params => async dispatch => {
      await dispatch(setAuth(await dispatch(Api.session.post("vendor", params))))
      await dispatch(loadWhoami())
    }

    const logoutUser = () => async (dispatch, getState) => {
      const state = omit(["user"], select.root(getState()))

      // Hacky, but we want the logout to happen immediately
      if (state.whoami) {
        dispatch(setSynced(updateIn("whoami", omit(["session"]), state)))
      }

      posthog.reset()

      await dispatch(Api.session.delete("user"))
      await dispatch(loadWhoami())
    }

    const putSetting =
      ({ ns, key, value }) =>
      async (dispatch, getState) => {
        const setting = Db.select.interface(getState()).setting({ ns, key })

        const { id } = setting.id
          ? await dispatch(Api.setting.update(setting.id, { value }))
          : await dispatch(Api.setting.create({ ...setting, value }))

        // Patch it into our settings rather than reload whoami
        dispatch(Db.put("setting", { ...setting, id, value }))
      }

    const putUserSetting =
      ({ ns, key, value }) =>
      (dispatch, getState) => {
        const user = select.user(getState())

        // We delay this by 3 seconds, so avoid race conditions on user logout
        if (user) {
          return dispatch(putSetting({ ns: `${ns}/${user.id}`, key, value }))
        }
      }

    const createAccount = accountData => async (dispatch, getState) => {
      const { defaultSplit, inventoryType, country } = select.store(getState())
      const { nextNumber } = await dispatch(Api.account.get("available_numbers"))

      return prop(
        "id",
        await dispatch(
          Api.account.create({
            ...accountData,
            number: nextNumber,
            split: defaultSplit,
            terms: ITEM_TERMS.RETURN_TO_CONSIGNOR,
            notifications: true,
            inventoryType,
            country,
          }),
        ),
      )
    }

    const createAccountFromName = name => async (dispatch, getState) => {
      const user = select.user(getState())

      const data = name.includes("@")
        ? { email: name }
        : {
            firstName: first(name.trim().split(" ")),
            lastName: name.trim().split(" ").slice(1).join(" "),
          }

      if (user.location) {
        data.locations = [user.location]
      }

      return dispatch(createAccount(data))
    }

    return {
      loadWhoami,
      setAuth,
      saveToDevice,
      logoutUser,
      loginUser,
      loginVendor,
      sendVendorOTP,
      putSetting,
      putUserSetting,
      createAccount,
      createAccountFromName,
      resetAppState: () => (dispatch, getState) => {
        const device = select.device(getState())
        const store = select.store(getState())
        const lastStoreId = prop("id", store)
        const user = select.user(getState())

        // Delete the session on the server
        dispatch(Api.session.delete())
        // Demo store isn't relevant to posthog tracking
        if (lastStoreId !== "29a36875-6918-4a79-a970-c5ca855deb73") {
          posthog.reset()
        }

        // Clear out everything, not just the core ns
        dispatch({ type: CLEAR_ROOT_STATE })

        // Clear localstorage; there are user settings we want to survive
        // across sessions however.
        storage.clear({
          keep: storage
            .keys()
            .filter(
              key =>
                key === "core/device" ||
                key === "emergepay-device" ||
                key.includes("visibleColumns") ||
                key.includes("modules/printing") ||
                key.includes("browserUpgradeModalOpen"),
            ),
        })

        // Keep device settings so we remember their printer etc but clear out auth information
        dispatch(setSynced({ ...getInitialState(), device, lastStoreId }))

        if (user && user.isVendor && store) {
          history.push(`/login/vendor?storeSlug=${store.slug}`)
        }
      },
      onboard: (ns, name, state) => async (dispatch, getState) => {
        const { onboarding } = select.store(getState())

        // If nothing is changing, don't send off unnecessary requests
        if (pathEq([ns, name], state, onboarding)) {
          return
        }

        await dispatch(Api.store.post("onboard", { ns, name, state }))
        await dispatch(loadWhoami())
      },
      showMaintenanceMode: () => (dispatch, getState) => {
        dispatch(setState({ maintenanceMode: true }))

        async function check() {
          maintenanceModeTimeout = null

          await dispatch(loadWhoami())

          dispatch(setState({ maintenanceMode: false }))

          // don't set another timeout; that'll be handled by the loadWhoami request failing
        }

        // If we already have a timeout, no need to set another
        if (!maintenanceModeTimeout) {
          maintenanceModeTimeout = setTimeout(check, 30 * 1000)
        }
      },
      showFeatureUnavailable: () =>
        Toast.push({
          type: "failure",
          message:
            "The system is currently under load, some features will be temporarily unavailable.",
        }),
      reloadingAppState: thunk => async dispatch => {
        const result = await dispatch(thunk)

        await dispatch(loadWhoami())

        return result
      },
    }
  },
})
