import { equals, mapObjIndexed, is } from "ramda"
import { mergeRight, stackedPromise } from "hurdak/src/core"
import { offlineErrors } from "utils/misc"
import Logger from "modules/logging"

export default (opts = {}) =>
  ({ ns, parent, root }) => {
    if (is(Function, opts)) {
      opts = opts({ ns, parent, root })
    }

    return {
      initialState: mergeRight(opts.initialState, {
        initialized: false,
        loading: false,
        errors: null,
        value: null,
        meta: null,
      }),
      reducers: {
        stop: state => ({ ...state, loading: false }),
        start: state => ({ ...state, loading: true, initialized: true }),
        setErrors: (state, errors) => ({ ...state, errors, loading: false }),
        setValue: (state, value) => ({ ...state, value, errors: null, loading: false }),
        setMeta: (state, meta) => ({ ...state, meta: { ...state.meta, ...meta } }),
      },
      createActions: ({ actions, select }) => {
        const trackStacked = stackedPromise()

        const track =
          (promise, kw = {}) =>
          async (dispatch, getState) => {
            dispatch(actions.start())

            try {
              const value = await trackStacked(promise)

              // If the state tree got reset, don't set value
              if (select.initialized(getState())) {
                dispatch(actions.setValue(value))
              }
            } catch (err) {
              if (opts.propagateErrors) {
                throw err
              }

              if (is(Array, err)) {
                dispatch(actions.setErrors(err))
              } else if (offlineErrors.includes(err.toString())) {
                dispatch(actions.setErrors([{ code: "network_error" }]))
              } else {
                Logger.error({ message: `error caught in "${ns}"`, payload: err })

                dispatch(actions.setErrors([{ code: "unknown_error" }]))
              }
            }

            // return fresh data in the promise
            return select.root(getState())
          }

        const forceLoad =
          (kw = {}) =>
          (dispatch, getState) => {
            if (!is(Function, opts.load)) {
              throw new Error(`load opt not provided for ${ns}`)
            }

            const meta = select.meta(getState())
            const promise = Promise.resolve(dispatch(opts.load(meta)))

            return dispatch(track(promise, kw))
          }

        const load =
          (newMeta = {}, kw = {}) =>
          (dispatch, getState) => {
            const rootState = select.root(getState())

            // Make sure meta is an object for comparison
            const meta = { ...rootState.meta, ...newMeta }

            // If nothing changed, don't do anything. If meta is null, this is
            // our first time loading, so go ahead.
            if (!kw.forceReload && rootState.meta && equals(rootState.meta, meta)) {
              return Promise.resolve(rootState)
            }

            // Make sure our meta is up to date
            dispatch(actions.setState({ meta }))

            return dispatch(forceLoad(kw))
          }

        const wrapThunk =
          thunk =>
          (...args) =>
          async (dispatch, getState) => {
            // Start loading immediately
            dispatch(actions.start())

            try {
              await dispatch(thunk(select.meta(getState()), ...args))
            } catch (e) {
              // Make sure to stop loading even if we get an error
              dispatch(actions.stop())

              throw e
            }

            await dispatch(forceLoad())
          }

        const thunks = mapObjIndexed(wrapThunk, opts.thunks || {})

        return { track, forceLoad, reload: forceLoad, load, ...thunks }
      },
    }
  }
