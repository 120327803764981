import Fuse from "fuse.js"
import equals from "ramda/src/equals"
import getPath from "ramda/src/path"

export const fuzzy = (data, { returnPath = ["item"], ...opts } = {}) => {
  const fuse = new Fuse(data, opts)

  // Slice pattern because the docs warn that it'll crash if too long
  return pattern => {
    const results = pattern
      ? fuse.search(pattern.slice(0, 32))
      : data.map((item, refIndex) => ({ item, refIndex }))

    return results.map(getPath(returnPath))
  }
}

export const memoizeActionCreator = fn => {
  let key, value

  return (...args) => {
    const newKey = JSON.stringify(args)

    if (!equals(key, newKey)) {
      key = newKey

      return dispatch => {
        value = dispatch(fn(...args))

        return value
      }
    }

    return dispatch => value
  }
}

export const throwIf = err => {
  if (err) {
    throw err
  }
}

export const offlineErrors = [
  "Failed to fetch",
  "TypeError: Failed to fetch",
  "The request timed out.",
  "The network connection was lost.",
  "Could not connect to the server.",
]

export const poll = ({ makeRequest, isComplete, isError }) => {
  return new Promise((resolve, reject) => {
    let nErrors = 0

    ;(function check() {
      setTimeout(async () => {
        try {
          const res = await makeRequest()

          if (isComplete(res)) {
            resolve(res)
          } else if (isError && isError(res)) {
            reject(res)
          } else {
            check()
          }
        } catch (e) {
          // If it's a network error, keep trying
          if (offlineErrors.includes(e.message) && nErrors < 20) {
            check()

            nErrors += 1

            return
          }

          throw e
        }
      }, 2000)
    })()
  })
}

export const resolve = x => Promise.resolve(x)
export const reject = x => Promise.reject(x)

export const cancelableThunk = (cachedResult, thunk) => {
  if (!window.AbortController) {
    return thunk
  }

  let ctrl = new AbortController()

  const f =
    (...args) =>
    async dispatch => {
      f.abort()

      let result
      try {
        result = await dispatch(thunk(...args, ctrl.signal))
      } catch (e) {
        result = cachedResult
      }

      cachedResult = result

      return result
    }

  f.abort = () => {
    ctrl.abort()
    ctrl = new AbortController()
  }

  return f
}

export const debouncedThunk = (timeout, thunk) => {
  let sentinel, promise

  return (...args) =>
    dispatch => {
      const currentSentinel = Math.random()

      sentinel = currentSentinel
      promise = new Promise(resolve => {
        setTimeout(() => {
          if (currentSentinel === sentinel) {
            resolve(dispatch(thunk(...args)))
          } else {
            resolve(promise)
          }
        }, timeout)
      })

      return promise
    }
}

export const showLoading = async ({ load, start }) => {
  let asyncDone = false

  setTimeout(() => asyncDone || start(), 10)

  let result, error
  try {
    result = await load()
  } catch (err) {
    error = err
  }

  asyncDone = true

  if (error) {
    throw error
  }

  return result
}

export const noopThunk = () => () => null

export const cond = conditions => {
  for (const [k, v] of conditions) {
    if (k) {
      return v
    }
  }
}

export const condFn = conditions => cond(conditions)()

// Deprecated

export const defineEnum = xs => {
  xs.forEach(x => {
    xs[x.toUpperCase()] = x
  })

  return xs
}

export const definePrefixedEnum = (prefix, xs) => {
  xs.forEach(x => {
    xs[x.toUpperCase()] = `${prefix}${x}`
  })

  return xs
}

export const dataURItoBlob = dataURI => {
  const byteString = window.atob(dataURI.split(",")[1])
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const displayBytes = (bytes, decimals = 2) => {
  if (!+bytes) {
    return "0 Bytes"
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

// Hurdak's toSnake is not symmetric with toCamel
export const toSnake = x =>
  x
    .replace(/[-_ ]+/g, "_")
    .replace(/(^_)_*([A-Z][a-z]+)/g, "$1_$2")
    .replace(/([A-Z])/g, "_$1")
    .toLowerCase()

// Display a number ordinally.
// i.e. 1st, 2nd, 3rd, 4th, 5th, etc.
export const ordinalize = n => {
  const s = ["th", "st", "nd", "rd"]
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}
