import { values, prop, propEq, reject, concat, find, whereEq, path } from "ramda"
import { createMap, doPipe, first } from "hurdak/src/core"
import { createSelector } from "reselect"
import { fuzzy } from "utils/misc"
import { compileBindle } from "modules/bindles"
import { getSearchKeys } from "modules/domain/model"

export default compileBindle("db", {
  initialState: {},
  createSelectors: ({ select }) => ({
    interface: createSelector(select.root, db => {
      const idMap = createMap("id", values(db).reduce(concat, []))
      const all = (k, where = {}) => values(db[k]).filter(whereEq(where))
      const active = (k, where = {}) => reject(prop("deleted"), all(k, where))
      const hasActive = (k, where = {}) => reject(prop("deleted"), all(k, where)).length > 0
      const one = id => idMap[id]
      const oneActive = id => (idMap[id] && idMap[id].deleted ? null : idMap[id])

      const settings = info => all("setting").filter(whereEq(info)) || []
      const setting = (info, value) => first(settings(info)) || { ...info, version: 1, value }

      return {
        all,
        one,
        active,
        oneActive,
        hasActive,
        setting,
        settings,
        makeSearch: entityType =>
          fuzzy(active(entityType), { keys: getSearchKeys(entityType), threshold: 0.3 }),
        hasSchedules: () =>
          Boolean(
            find(path(["schedule", "length"]), active("discount").concat(active("surcharge"))),
          ),
        accountStatusEmailEnabled: () =>
          Boolean(find(whereEq({ name: "account_status", enabled: true }), all("emailTemplate"))),
        payoutReceiptEmailEnabled: () =>
          Boolean(find(whereEq({ name: "payout_receipt", enabled: true }), all("emailTemplate"))),
        receiptEmailEnabled: () =>
          Boolean(find(whereEq({ name: "receipt", enabled: true }), all("emailTemplate"))),
        returnItemsEmailEnabled: () =>
          Boolean(find(whereEq({ name: "items_returned", enabled: true }), all("emailTemplate"))),
        portalInviteEmailEnabled: () =>
          Boolean(find(whereEq({ name: "portal_invite", enabled: true }), all("emailTemplate"))),
      }
    }),
  }),
  createActions: ({ select, actions }) => ({
    put: (entityType, row) => (dispatch, getState) => {
      const db = select.interface(getState())

      dispatch(
        actions.setState({
          [entityType]: doPipe(db.all(entityType), [reject(propEq("id", row.id)), concat([row])]),
        }),
      )
    },
  }),
})
