import "resources/css/components/progress.css"

import React from "react"
import cx from "classnames"
import { T } from "utils/react"

export const Progress = ({ theme, value }) => {
  const width = `${Math.round(value * 100)}%`

  return (
    <div className={cx("progress", `progress-${theme}`)}>
      <div className="progress-inner" style={{ width }} />
    </div>
  )
}

Progress.defaultProps = {
  theme: "primary",
}

Progress.propTypes = {
  theme: T.oneOf(["primary"]),
  value: T.number.isRequired,
}

export const LabeledProgress = ({ children, ...props }) => (
  <div>
    <div className="pb-4">
      <Progress {...props} />
    </div>
    <div className="pb-4 flex justify-center">{children}</div>
  </div>
)

LabeledProgress.propTypes = {
  value: T.number.isRequired,
  children: T.children.isRequired,
}

export const RadialProgress = ({ theme, value, size, children }) => {
  const className = cx("radial-progress", `radial-progress-${theme}`, `radial-progress-${size}`)

  // https://daverupert.com/2018/03/animated-svg-radial-progress-bars/
  return (
    <div className={className} style={{ transform: "scale(0.7)" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <path className="radial-progress-bg" d="M41 149.5a77 77 0 1 1 117.93 0" />
        <path
          className="radial-progress-meter"
          d="M41 149.5a77 77 0 1 1 117.93 0"
          strokeDasharray="350"
          strokeDashoffset={350 - value * 350}
        />
      </svg>
      <div className="radial-progress-children">{children}</div>
    </div>
  )
}

RadialProgress.defaultProps = {
  theme: "primary",
  size: "medium",
}

RadialProgress.propTypes = {
  theme: T.oneOf(["primary"]),
  size: T.oneOf(["small", "medium"]),
  value: T.number.isRequired,
  children: T.children,
}
