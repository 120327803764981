import { path, curryN, is, last, partial } from "ramda"
import { randomId, isUrl } from "hurdak/src/core"
import { Dt } from "utils/dt"

const _cache = {}

const get = (ns, key) => {
  // If it's an upload metadata object
  if (is(Object, key)) {
    return { url: key.url, name: key.originalName }
  }

  // If it's a url
  if (isUrl(key)) {
    return { url: key, name: last(key.split("/")) }
  }

  // If it's a key to an uploaded file
  return path([ns, key], _cache)
}

const getFile = curryN(2, (ns, key) => get(ns, key).file)

const set = (ns, key, file) => {
  if (window.DataTransferItem && file instanceof DataTransferItem) {
    file = file.getAsFile()
  }

  if (!file) {
    return null
  }

  const created = Dt.now()
  const url = URL.createObjectURL(file)
  const name = file.name || `Screenshot on ${created.display()}`

  _cache[ns] = _cache[ns] || {}
  _cache[ns][key] = { file, name, created, url }

  return _cache[ns][key]
}

const add = (ns, file) => {
  const key = randomId()

  return set(ns, key, file) ? key : null
}

const remove = (ns, key) => {
  const file = path([ns, key], _cache)

  if (file) {
    URL.revokeObjectURL(file.url)
    delete _cache[ns][key]
  }
}

const clean = ns => {
  Object.keys(_cache[ns] || {}).forEach(partial(remove, [ns]))
}

export default { _cache, get, set, add, remove, clean, getFile }
