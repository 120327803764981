import "resources/css/components/card.css"

import React from "react"
import cx from "classnames"
import { T, BoundComponent } from "utils/react"
import { Escapable } from "partials/Escapable"
import { Content } from "partials/Layout"
import { Icon } from "partials/Icon"
import { Tippy } from "partials/Tippy"

export const Card = ({ children, className, ...props }) => (
  <div {...props} className={cx("shadow-card rounded-lg overflow-hidden", className)}>
    {children}
  </div>
)

export const CardTop = ({ children, className, minHeight = "8rem" }) => (
  <div
    style={{ minHeight }}
    data-cy="cardTop"
    className={cx(
      "card-top relative bg-gray-900 text-white flex flex-col justify-center items-center",
      className,
    )}
  >
    {children}
  </div>
)

export const CardTopHeader = ({ children }) => (
  <div className="flex flex-col justify-center items-center text-center py-12">{children}</div>
)

export const CardTopTabs = ({ children, className }) => (
  <ul
    className={cx(
      "flex flex-grow w-full text-gray-200 text-lg bg-gray-900 overflow-auto",
      className,
    )}
  >
    {children}
  </ul>
)

export const CardTopTab = ({ children, onClick, isActive, isDisabled, className }) => {
  return (
    <li
      onClick={onClick}
      className={cx(
        className,
        "flex flex-col justify-end items-center cursor-pointer gap-6 p-6 card-tab text-lg",
        { "pointer-events-none opacity-50": isDisabled, "card-tab-active": isActive },
      )}
    >
      {children}
    </li>
  )
}

export const CardTopContent = ({ className, ...props }) => (
  <Content className={cx("p-4", className)} {...props} />
)

export class CardTopMenu extends BoundComponent {
  onIconClick() {
    const { isOpen } = this.props

    if (!isOpen) {
      this.props.open()
    }
  }
  renderMenu() {
    const { children, close } = this.props

    return (
      <Escapable escape={close}>
        <div onClick={close}>{children}</div>
      </Escapable>
    )
  }
  render() {
    const { tour, isOpen, size, theme } = this.props
    const iconSize = size ? size : "large"

    return (
      <Tippy
        theme="menu"
        isOpen={isOpen}
        render={this.renderMenu}
        placement="bottom-end"
        className="absolute top-0 right-0 p-4 cursor-pointer"
      >
        <div data-tour={tour} className="print:hidden" onClick={this.onIconClick}>
          <Icon icon="ellipsis-v" size={iconSize} theme={theme} />
        </div>
      </Tippy>
    )
  }
}

CardTopMenu.propTypes = {
  tour: T.string,
  isOpen: T.bool.isRequired,
  close: T.func.isRequired,
  open: T.func.isRequired,
}

export const CardTopSection = ({ title, children }) => (
  <div>
    <h2 className="pt-4 border-solid border-t border-gray-700 mb-4">{title}</h2>
    {children}
  </div>
)

export const CardBottom = ({ children, className, ...props }) => (
  <div className={cx("relative bg-white p-4", className)} data-cy="cardBottom" {...props}>
    {children}
  </div>
)

export const CardBottomHeader = ({ children, className, ...props }) => (
  <div
    {...props}
    className={cx(
      className,
      "flex justify-between items-center",
      "border-b border-gray-300 border-solid",
      "-mx-4 -mt-4 mb-4 p-4",
    )}
  >
    {children}
  </div>
)

export const CardBottomSection = ({ title, children }) => (
  <div>
    <div className="border-t border-solid border-gray-400 mt-16 mb-8">
      <h2 className="-mt-4 uppercase text-gray-500 font-normal">
        <span className="bg-white inline-block pt-1 pr-4">{title}</span>
      </h2>
    </div>
    {children}
  </div>
)

export const CardBottomTable = ({ children, className }) => (
  <div className={cx("-mx-4", className)}>{children}</div>
)

export const CardBottomFooter = ({ children, className, ...props }) => (
  <div
    {...props}
    className={cx(
      className,
      "border-t border-solid border-gray-400 bg-gray-200 flex justify-center",
      "items-center gap-4 -mx-4 -mb-4 mt-8 p-2 print:hidden",
    )}
  >
    {children}
  </div>
)
