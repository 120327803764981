import React from "react"
import cx from "classnames"
import is from "ramda/src/is"
import omit from "ramda/src/omit"
import { Link } from "react-router-dom"
import { T, BoundComponent } from "utils/react"

export class Anchor extends BoundComponent {
  onClick(evt) {
    const { isDisabled, stopPropagation } = this.props

    if (stopPropagation) {
      evt.stopPropagation()
    }

    if (isDisabled) {
      evt.preventDefault()
    } else if (is(Function, this.props.onClick)) {
      this.props.onClick()
    }
  }
  render() {
    const { isActive, isDisabled, isUnstyled, isDanger, onClick, children, ...props } = omit(
      ["stopPropagation"],
      this.props,
    )

    const className = cx("anchor overflow-hidden", this.props.className, {
      "font-bold": isActive,
      "opacity-50": isDisabled,
      "anchor-unstyled no-underline": isDisabled || isUnstyled,
      "text-danger": isDanger,
    })

    const to = is(String, onClick) ? onClick : undefined
    const Wrapper = is(String, onClick) ? Link : "span"

    return (
      <Wrapper {...props} className={className} to={to} onClick={this.onClick} data-cy="anchor">
        {children}
      </Wrapper>
    )
  }
}

Anchor.defaultProps = {
  stopPropagation: true,
}

Anchor.propTypes = {
  stopPropagation: T.bool.isRequired,
  isActive: T.bool,
  isDisabled: T.bool,
  isUnstyled: T.bool,
  isDanger: T.bool,
  onClick: T.oneOfType([T.string, T.func]),
  children: T.children,
}
