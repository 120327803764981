import React from "react"
import { Link } from "react-router-dom"
import prop from "ramda/src/prop"
import isNil from "ramda/src/isNil"
import pipe from "ramda/src/pipe"
import always from "ramda/src/always"
import getPath from "ramda/src/path"
import is from "ramda/src/is"
import last from "ramda/src/last"
import map from "ramda/src/map"
import toPairs from "ramda/src/toPairs"
import keys from "ramda/src/keys"
import filter from "ramda/src/filter"
import lt from "ramda/src/lt"
import omit from "ramda/src/omit"
import merge from "ramda/src/merge"
import values from "ramda/src/values"
import sum from "ramda/src/sum"
import ellipsize from "ellipsize"
import {
  toTitle,
  switcherFn,
  displayList,
  updateIn,
  toCamel,
  ensurePlural,
  defmulti,
} from "hurdak/src/core"
import { Dt } from "utils/dt"
import Currency from "hurdak/src/currency"
import Percent from "hurdak/src/percent"
import { buildEntityUrl } from "utils/docusign"
import StatusIndicator from "partials/StatusIndicator"
import { ExternalLink } from "partials/ExternalLink"
import { PERMS, hasPerm, isVendor } from "modules/permissions"
import Display from "modules/domain/display"

let _model = {}

const modifyDataTypeCase = dataType => ({
  ...dataType,
  name: toCamel(dataType.name),
  to: dataType.to ? toCamel(dataType.to) : null,
  of: dataType.of ? modifyDataTypeCase(dataType.of) : null,
  backref: dataType.backref ? toCamel(dataType.backref) : null,
})

export const buildModel = attrs => {
  _model = {}

  for (const { entity, name, label, dataType, opts } of attrs) {
    const entityName = toCamel(entity.name)
    const attrName = toCamel(name)

    _model[entityName] = _model[entityName] || {
      entity: updateIn("identifier", toCamel, {
        ...entity,
        name: entityName,
      }),
      attrs: {},
    }

    _model[entityName].attrs[attrName] = {
      label,
      name: attrName,
      entity: entityName,
      fullName: `${entityName}.${attrName}`,
      dataType: modifyDataTypeCase(dataType),
      opts,
    }
  }

  window.app = window.app || {}
  window.app.model = _model
}

export const getAttrs = entityName => {
  const attrs = getPath([entityName, "attrs"], _model)

  if (!attrs) {
    throw new Error(`Invalid entity: ${entityName}`)
  }

  return attrs
}

export const getEntity = entityName => {
  const entity = getPath([entityName, "entity"], _model)

  if (!entity) {
    throw new Error(`Invalid entity: ${entityName}`)
  }

  return entity
}

export const getAttr = (entityName, attrName) => {
  const attr = getPath([entityName, "attrs", attrName], _model)

  if (!attr) {
    throw new Error(`Invalid attr: ${entityName}.${attrName}`)
  }

  return attr
}

export const attrExists = (entityName, attrName) =>
  Boolean(getPath([entityName, "attrs", attrName], _model))

// Convenience functions so we don't have to use getAttr/getEntity all over the place
const ensureEntity = entity => (is(String, entity) ? getEntity(entity) : entity)
const ensureAttr = attr => (is(String, attr) ? getAttr(...attr.split(".")) : attr)

export const getAtomicType = dataType => (dataType.of ? getAtomicType(dataType.of) : dataType)

export const traceAttr = (entityName, attrPath) => {
  if (is(String, attrPath)) {
    attrPath = attrPath.split(".")
  }

  const path = []
  for (const attrName of attrPath.slice(0, -1)) {
    const attr = getAttr(entityName, attrName)

    entityName = getAtomicType(attr.dataType).to

    path.push(attr)
  }

  return { path, attr: getAttr(entityName, last(attrPath)) }
}

export const getTracedAttr = pipe(traceAttr, prop("attr"))

export const isSingularRel = dataType => dataType.name === "rel" && !dataType.backref

export const isPluralRel = dataType =>
  getPath(["of", "name"], dataType) === "rel" || dataType.backref

export const isRel = dataType => isSingularRel(dataType) || isPluralRel(dataType)

// ============================================================================
// Labeling

const _displayEntityName = defmulti("_displayEntityName", prop("name"))

export const displayEntityName = entity => _displayEntityName(ensureEntity(entity))

_displayEntityName.addDefaultMethod(prop("label"))
_displayEntityName.addMethod("transaction", always("Sale"))

const _displayEntityPluralName = defmulti("_displayEntityPluralName", prop("name"))

export const displayEntityPluralName = entity => _displayEntityPluralName(ensureEntity(entity))

_displayEntityPluralName.addDefaultMethod(entity => `${displayEntityName(entity)}s`)
_displayEntityPluralName.addMethod("category", always("Categories"))
_displayEntityPluralName.addMethod("tax", always("Taxes"))

const _displayAttrName = defmulti("_displayAttrName", prop("fullName"))

export const displayAttrName = attr => _displayAttrName(ensureAttr(attr))

_displayAttrName.addDefaultMethod(prop("label"))
_displayAttrName.addMethod("event.created", always("Date"))

// ============================================================================
// Display

const displayDate = v => {
  try {
    return v ? Dt.parse(v).display() : ""
  } catch (e) {
    return ""
  }
}

export const displayDataType = defmulti("displayDataType", prop("name"))

displayDataType.addDefaultMethod((t, x) => x)

displayDataType.addMethod("dateTime", (t, x) => displayDate(x))
displayDataType.addMethod("date", (t, x) => displayDate(x))
displayDataType.addMethod("text", (t, x) => ellipsize(x || "", 50))
displayDataType.addMethod("percent", (t, x) => Percent.format(x, { precision: 4 }))
displayDataType.addMethod("currency", (t, x) => Currency.format(x))
displayDataType.addMethod("boolean", (t, x) => (x ? "Yes" : "No"))
displayDataType.addMethod("enum", (t, x) => toTitle(x || ""))
displayDataType.addMethod("rel", (t, x) => displayEntity(t.to, x))

displayDataType.addMethod("list", (t, xs) =>
  displayList(ensurePlural(xs || []).map(x => displayDataType(t.of, x))),
)

const _displayAttr = defmulti("_displayAttr", prop("fullName"))

export const displayAttr = (attr, x) => _displayAttr(ensureAttr(attr), x)

_displayAttr.addDefaultMethod((attr, x) => displayDataType(attr.dataType, x ? x[attr.name] : null))

_displayAttr.addMethod("item.weight", (attr, { weight, weightUnit }) =>
  weightUnit ? `${weight} ${weightUnit}` : weight,
)

_displayAttr.addMethod("item.split", (attr, { split }) =>
  split === null ? "Calculated at sale" : displayDataType(attr.dataType, split),
)

_displayAttr.addMethod("item.status", (attr, { status, quantity }) => {
  // For filter box
  if (is(String, status)) {
    return toTitle(status)
  }

  status = filter(lt(0), omit(["recountShrink"], status))

  if (isNil(quantity)) {
    status = merge(status, { active: "∞" })
  }

  if (values(status).length === 0) {
    return "Inactive"
  }

  if (sum(values(status)) === 1) {
    return toTitle(keys(status)[0])
  }

  return toPairs(status)
    .map(([key, q]) => `${q} ${toTitle(key)}`)
    .join(", ")
})

_displayAttr.addMethod(
  "contact.consignorContractStatus",
  (attr, { consignorContractStatus, docusignConsignorContract }) => {
    if (!consignorContractStatus) {
      return null
    }

    const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)
    let text = capitalize(consignorContractStatus)

    if (consignorContractStatus?.endsWith("_docusign")) {
      text = `${text.replace("_docusign", "")} on DocuSign`
    }

    if (docusignConsignorContract) {
      return (
        <ExternalLink href={buildEntityUrl("document", docusignConsignorContract)}>
          {text}
        </ExternalLink>
      )
    } else {
      return text
    }
  },
)

_displayAttr.addMethod("event.data", (attr, { data, entityType, entityId, name }) =>
  switcherFn(name, {
    default: () => toTitle(name).replace("Transaction", "Sale"),
    TRANSACTION_FINALIZED: () => `Sale #${data.number} Finalized`,
    TRANSACTION_PARKED: () => `Sale #${data.number} Parked`,
    GIFT_CARD_CREATED: () => `Gift Card #${data.barcode} Created`,
    ACCOUNT_CREATED: () => `Account #${data.number} Created`,
    ACCOUNT_UNSUBSCRIBED: () => `Account unsubscribed from emails`,
    BALANCE_ADJUSTED_MANUALLY: () => `Account Balance Adjusted`,
    LABEL_CREATED: () => `${data.name} Created`,
    ITEM_CREATED: () => `Item Created`,
    USER_CREATED: () => `${data.username} Created`,
    ITEM_UPDATED: () => {
      // recounted as default because there was a bug that prevented reason from being passed
      const { quantity, quantityChangeReason } = { quantityChangeReason: "recounted", ...data }

      if (typeof quantity === "number" && quantityChangeReason === "stocked") {
        return `New inventory received`
      }

      // Because of old data, recounts are best represented as regular updates
      if (typeof quantity === "number" && quantityChangeReason !== "recounted") {
        return `Status changed to ${toTitle(quantityChangeReason)}`
      }

      const attrNames = map(toTitle, keys(omit(["quantityChangeReason", "id"], data)))

      if (attrNames.length > 10) {
        return `Item Updated`
      }

      return `Item ${displayList(attrNames, "and", 3)} changed`
    },
  }),
)

_displayAttr.addMethod("webhookDelivery.responseStatus", (attr, { responseStatus }) => {
  const statusIsSuccess = responseStatus >= 200 && responseStatus < 300

  return responseStatus ? (
    <StatusIndicator status={statusIsSuccess ? "success" : "danger"}>
      {responseStatus}
    </StatusIndicator>
  ) : null
})

_displayAttr.addMethod(
  "webhookSubscription.sevenDaySuccessRate",
  (attr, { sevenDaySuccessRate }) => {
    if (sevenDaySuccessRate === null) {
      return null
    }

    return Percent.format(sevenDaySuccessRate, {
      precision: 2,
    })
  },
)

const percentOrCurrency = (attr, { value, valueType }) =>
  valueType === "fixed" ? Currency.format(value) : Percent.format(value)

_displayAttr.addMethod("surcharge.value", percentOrCurrency)
_displayAttr.addMethod("discount.value", percentOrCurrency)
_displayAttr.addMethod("tax.value", percentOrCurrency)

_displayAttr.addMethod("tillReportEntry.type", (attr, { type }) => toTitle(type))

const _displayEntity = defmulti("_displayEntity", prop("name"))

export const displayEntity = (entity, x) => _displayEntity(ensureEntity(entity), x)

_displayEntity.addDefaultMethod((entity, x) => Display[toCamel(entity.name)](x))

const _getEntityIcon = defmulti("_getEntityIcon", prop("name"))

export const getEntityIcon = entity => _getEntityIcon(ensureEntity(entity))

_getEntityIcon.addMethod("item", always("tag"))
_getEntityIcon.addMethod("note", always("pencil"))
_getEntityIcon.addMethod("contact", always("user-circle"))
_getEntityIcon.addMethod("category", always("folder"))
_getEntityIcon.addMethod("balanceEntry", always("bank"))
_getEntityIcon.addMethod("user", always("user"))
_getEntityIcon.addMethod("transaction", always("credit-card"))
_getEntityIcon.addMethod("giftCard", always("gift"))

// ============================================================================
// Search

const _getSearchKeys = defmulti("_getSearchKeys", prop("name"))

export const getSearchKeys = entity => _getSearchKeys(ensureEntity(entity))

_getSearchKeys.addDefaultMethod(always(["name"]))
_getSearchKeys.addMethod("user", always(["username"]))

// ============================================================================
// Numeric value for charts

export const getNumericValue = defmulti("getNumericValue", prop("name"))

getNumericValue.addDefaultMethod((t, x) => {
  throw new Error(`${t.name} is not numeric.`)
})

getNumericValue.addMethod("dateTime", (t, x) => new Date(x))
getNumericValue.addMethod("date", (t, x) => new Date(x))
getNumericValue.addMethod("percent", (t, x) => x * 100)
getNumericValue.addMethod("currency", (t, x) => x / 100)
getNumericValue.addMethod("integer", (t, x) => x)
getNumericValue.addMethod("number", (t, x) => x)

// ============================================================================
// Links/routing/permissions

const linkPermissions = {
  giftCard: PERMS.GIFT_CARD_DETAIL,
  transaction: PERMS.TRANSACTION_DETAIL,
  user: PERMS.SETTINGS,
  item: PERMS.ITEM_DETAIL,
  contact: PERMS.ACCOUNT_DETAIL,
}

const hasDetailPerm = entity => {
  const perm = linkPermissions[entity.name]

  return !perm || hasPerm(perm)
}

export const getEntityPath = defmulti("getEntityPath", (e, type) => `${e}:${type}`)

const pathBuilder =
  root =>
  (e, type, ...args) =>
    ["", root, ...args].join("/")

getEntityPath.addDefaultMethod(() => null)
getEntityPath.addMethod("item:list", pathBuilder("inventory"))
getEntityPath.addMethod("item:detail", pathBuilder("inventory"))
getEntityPath.addMethod("batch:list", pathBuilder("inventory/batch"))
getEntityPath.addMethod("batch:detail", pathBuilder("inventory/batch"))
getEntityPath.addMethod("item:new", pathBuilder("inventory/new"))
getEntityPath.addMethod("contact:list", pathBuilder("accounts"))
getEntityPath.addMethod("contact:detail", pathBuilder("accounts"))
getEntityPath.addMethod("contact:new", pathBuilder("accounts/new"))
getEntityPath.addMethod("transaction:list", pathBuilder("sales"))
getEntityPath.addMethod("transaction:detail", pathBuilder("sales"))
getEntityPath.addMethod("transaction:new", pathBuilder("register"))
getEntityPath.addMethod("giftCard:list", pathBuilder("gift-cards"))
getEntityPath.addMethod("giftCard:detail", pathBuilder("gift-cards"))
getEntityPath.addMethod("balanceEntry:list", pathBuilder("accounts/history"))
getEntityPath.addMethod("statusChange:list", pathBuilder("status-changes"))
getEntityPath.addMethod("till:list", pathBuilder("settings/register/tills"))
getEntityPath.addMethod("till:detail", pathBuilder("settings/register/tills"))
getEntityPath.addMethod("shelf:list", pathBuilder("settings/operations/shelves"))
getEntityPath.addMethod("recurringFee:list", pathBuilder("settings/operations/recurring-fees"))
getEntityPath.addMethod("label:list", pathBuilder("settings/printing/labels"))
getEntityPath.addMethod("label:detail", pathBuilder("settings/printing/labels"))
getEntityPath.addMethod("label:new", pathBuilder("settings/printing/labels/new"))
getEntityPath.addMethod("emailTemplate:list", pathBuilder("settings/emails/templates"))
getEntityPath.addMethod("emailTemplate:detail", pathBuilder("settings/emails/templates"))
getEntityPath.addMethod("user:list", pathBuilder("settings/operations/users"))
getEntityPath.addMethod("user:detail", pathBuilder("settings/operations/users"))
getEntityPath.addMethod("category:list", pathBuilder("settings/policy/categories"))
getEntityPath.addMethod("discount:list", pathBuilder("settings/policy/discounts"))
getEntityPath.addMethod("surcharge:list", pathBuilder("settings/policy/surcharges"))
getEntityPath.addMethod("tax:list", pathBuilder("settings/policy/taxes"))
getEntityPath.addMethod("webhookSubscription:detail", pathBuilder("settings/developer/webhooks"))

export const asEntityLink = (entityType, data, display) => {
  if (!data) {
    return display
  }

  const entity = ensureEntity(entityType)
  let path = getEntityPath(entity.name, "detail", data.id)

  if (entityType === "vendorUser") {
    if (data.isVendor) {
      path = getEntityPath("contact", "detail", data.id)
    } else {
      path = getEntityPath("user", "detail", data.id)
    }
  }

  if (!path || !hasDetailPerm(entity) || (isVendor() && entityType !== "item")) {
    return display
  }

  return (
    <Link key={data.id} to={path}>
      {display}
    </Link>
  )
}

export const displayEntityLink = (entityType, data) => {
  const entity = ensureEntity(entityType)

  return asEntityLink(entity, data, displayEntity(entity, data))
}
