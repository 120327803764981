import "resources/css/components/checkbox-input.css"

import React from "react"
import cx from "classnames"
import { noop } from "hurdak/src/core"
import { T, BoundComponent } from "utils/react"
import { decorate } from "modules/inputs/utils"

class _CheckboxInput extends BoundComponent {
  onChange(evt) {
    const isShift = evt.nativeEvent.shiftKey

    this.props.onChange(Boolean(evt.target.checked), { isShift })
  }
  onFocus(evt) {
    this.props.onFocus()
  }
  onBlur(evt) {
    this.props.onBlur()
  }
  render() {
    const { LabelComponent, label, value, inputRef, ...props } = this.props

    return (
      <LabelComponent className={cx("h-8 flex gap-2 items-center relative top-1")}>
        <input
          {...props}
          type="checkbox"
          checked={value}
          ref={inputRef}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        {label && <div>{label}</div>}
      </LabelComponent>
    )
  }
}

_CheckboxInput.defaultProps = {
  LabelComponent: "label",
  onFocus: noop,
  onBlur: noop,
}

_CheckboxInput.propTypes = {
  name: T.string.isRequired,
  label: T.children,
  LabelComponent: T.component.isRequired,
  value: T.bool.isRequired,
  inputRef: T.func,
  onChange: T.func.isRequired,
  onFocus: T.func,
  onBlur: T.func,
}

export const CheckboxInput = decorate(_CheckboxInput, {
  format: Boolean,
  parse: Boolean,
  withAddons: true,
  defaultValue: false,
})
