import autobind from "class-autobind"
import {
  map,
  pick,
  merge,
  is,
  prop,
  allPass,
  test,
  all,
  when,
  identity,
  path as getPath,
  objOf,
  filter,
  keys,
} from "ramda"
import { noop, toCamel, bytes, updateIn, replaceValues, doPipe } from "hurdak/src/core"
import { Dt } from "utils/dt"
import { displayBytes, toSnake, poll } from "utils/misc"
import Files from "utils/files"
import { getClient } from "modules/client"
import { getAttrs } from "modules/domain/model"

const emptyStringToNull = replaceValues(allPass([is(String), test(/^ *$/)]), null)

export const modifyWhere = ({ conditions, field, ..._where }) => {
  return conditions
    ? { ..._where, conditions: conditions.map(modifyWhere) }
    : { ..._where, field: toSnake(field) }
}

const select = (field, label) => ({ field, label })

const where = (field, operator, value = null) => {
  // Identify date filters and format them
  if (is(Dt, value)) {
    value = value.format()
  } else if (operator === "between" && all(is(Dt), value)) {
    value = value.map(dt => dt.format())
  }

  return { field, operator, value }
}

const or = conditions => ({ operator: "or", conditions })

const orderBy = (field, order = "DESC") => ({ field, order })

class Endpoint {
  base = null
  autoTrim = true
  defaultParams = {
    limit: 20,
    offset: 0,
    where: [],
    orderBy: [orderBy("created")],
    select: ["*"],
  }

  constructor(opts = {}) {
    Object.assign(this, opts)

    autobind(this)
  }

  // Utilities

  url(...parts) {
    const path = [this.base, ...parts].filter(identity).join("/")

    return `/${path}/`
  }

  trim(k, data) {
    return pick(keys(filter(getPath(["opts", k]), getAttrs(toCamel(this.base)))), data)
  }

  // General purpose api methods

  req(method, path, { ret = ["data"], ...opts } = {}) {
    return dispatch =>
      dispatch(getClient()[method](this.url(path), opts)).then(getPath(ret), e =>
        Promise.reject(e.errors || e),
      )
  }
  get(path, opts = {}) {
    return this.req("get", path, opts)
  }
  put(path, body = {}, opts = {}) {
    return this.req("put", path, { ...opts, body })
  }
  patch(path, body = {}, opts = {}) {
    return this.req("patch", path, { ...opts, body })
  }
  post(path, body = {}, opts = {}) {
    return this.req("post", path, { ...opts, body })
  }
  delete(path, opts = {}) {
    return this.req("del", path, opts)
  }

  // Special purpose api methods

  list(opts = {}) {
    return this.get(null, opts)
  }
  detail(id, opts = {}) {
    return this.get(id, opts)
  }
  create(data, opts = {}) {
    if (this.autoTrim) {
      data = this.trim("canInsert", data)
    }

    return this.post(null, emptyStringToNull(data))
  }
  update(id, data, opts = {}) {
    if (this.autoTrim) {
      data = this.trim("canUpdate", data)
    }

    return this.put(id, emptyStringToNull(data))
  }
  updateMulti({ query, updates = {}, actions = [] }, opts = {}) {
    if (this.autoTrim) {
      updates = this.trim("canUpdate", updates)
    }

    return this.patch(null, {
      query: updateIn("where", map(modifyWhere), query),
      updates: emptyStringToNull(updates),
      actions: actions.map(emptyStringToNull),
    })
  }
}

export default {
  select,
  where,
  or,
  orderBy,
  apiKey: new Endpoint({ base: "api_key" }),
  purchaseOrder: new Endpoint({ base: "purchase_order" }),
  account: new Endpoint({ base: "contact" }),
  contact: new Endpoint({ base: "contact" }),
  artifact: new Endpoint({ base: "artifact" }),
  balanceEntry: new Endpoint({ base: "balance_entry" }),
  billing: new Endpoint({ base: "billing" }),
  batch: new Endpoint({ base: "batch" }),
  batchEntry: new Endpoint({
    base: "batch_entry",
    reject(id) {
      return this.post(`${id}/rejection`)
    },
    removeRejection(id) {
      return this.delete(`${id}/rejection`)
    },
  }),
  category: new Endpoint({ base: "category" }),
  checkbook: new Endpoint({ base: "checkbook" }),
  discount: new Endpoint({ base: "discount" }),
  emailTemplate: new Endpoint({
    base: "email_template",
    download(name) {
      return getClient().get(this.url(name, "download"), {
        headers: { Accept: "text/plain" },
      })
    },
    preview(name, data = {}) {
      return getClient().post(this.url(name, "preview"), {
        body: data,
        headers: { Accept: "text/html" },
      })
    },
  }),
  event: new Endpoint({ base: "event" }),
  giftCard: new Endpoint({ base: "gift_card" }),
  item: new Endpoint({ base: "item" }),
  itemField: new Endpoint({ base: "item_field" }),
  accountField: new Endpoint({ base: "account_field" }),
  statusChange: new Endpoint({ base: "status_change" }),
  task: new Endpoint({
    base: "task",
    monitorProgress(opts = {}) {
      return async dispatch => {
        opts = { onProgress: noop, onFailure: noop, onCompletion: noop, ...opts }

        const promise = poll({
          makeRequest: async () => {
            const data = await dispatch(this.detail(opts.taskId))

            dispatch(opts.onProgress(data))

            return data
          },
          isComplete: prop("completed"),
          isError: t => t.failure || t.aborted,
        })

        try {
          return opts.onCompletion(await promise)
        } catch (data) {
          throw opts.onFailure(data)
        }
      }
    },
  }),
  label: new Endpoint({ base: "label" }),
  lineItem: new Endpoint({ base: "line_item" }),
  location: new Endpoint({ base: "location" }),
  note: new Endpoint({ base: "note" }),
  payment: new Endpoint({ base: "payment" }),
  payout: new Endpoint({ base: "payout" }),
  portal: new Endpoint({ base: "portal" }),
  refund: new Endpoint({ base: "refund" }),
  report: new Endpoint({ base: "report" }),
  sale: new Endpoint({ base: "transaction", autoTrim: false }),
  search: new Endpoint({
    base: "search",
    defaultParams: {
      limit: 20,
      offset: 0,
      where: [],
      orderBy: [],
      select: ["*"],
    },
    entity(entityType, params, opts = {}) {
      return this.post(
        toSnake(entityType),
        doPipe(params, [
          when(is(Array), objOf("where")),
          merge(this.defaultParams),
          updateIn("where", map(modifyWhere)),
          updateIn("select", map(toSnake)),
          updateIn("orderBy", map(updateIn("field", toSnake))),
        ]),
        { ret: [], ...opts },
      )
    },
    entityExport(entityType, params) {
      return this.post(
        `${toSnake(entityType)}/export`,
        doPipe(params, [
          when(is(Array), objOf("where")),
          merge(pick(["where"], this.defaultParams)),
          // Select here is export select, so {field, label}
          updateIn("where", map(modifyWhere)),
          updateIn("select", map(updateIn("field", toSnake))),
          updateIn("orderBy", map(updateIn("field", toSnake))),
        ]),
      )
    },
  }),
  session: new Endpoint({ base: "session" }),
  setting: new Endpoint({ base: "setting" }),
  shelf: new Endpoint({ base: "shelf" }),
  recurringFee: new Endpoint({ base: "recurring_fee" }),
  recurringFeeSubscription: new Endpoint({ base: "recurring_fee_subscription" }),
  shopify: new Endpoint({ base: "shopify" }),
  square: new Endpoint({ base: "square" }),
  docusign: new Endpoint({ base: "docusign" }),
  store: new Endpoint({ base: "store" }),
  surcharge: new Endpoint({ base: "surcharge" }),
  swipe: new Endpoint({ base: "swipe" }),
  tax: new Endpoint({ base: "tax" }),
  till: new Endpoint({ base: "till" }),
  tillReport: new Endpoint({
    base: "till_report",
    adjustEndingBalance(id, data) {
      return this.post(`${id}/adjust_ending_balance`, data)
    },
  }),
  tillReportEntry: new Endpoint({ base: "till_report_entry" }),
  upload: new Endpoint({
    base: "upload",
    deref(id) {
      return dispatch => (id ? dispatch(this.get(id)) : null)
    },
    contents(id) {
      return async dispatch => {
        const { url } = await dispatch(this.get(id))
        const res = await window.fetch(url)

        return res.text()
      }
    },
    save({ uploadId, fileKey, fileNs, resize, formKey, limit = bytes(25, "mb") }) {
      return async dispatch => {
        // If it's not a file object, but it is an object, it's an upload object, no change
        if (!is(File, fileKey) && is(Object, fileKey)) {
          return uploadId
        }

        // Check if they are deleting the image
        if (!fileKey) {
          return null
        }

        const fileObj = is(File, fileKey) ? fileKey : Files.getFile(fileNs, fileKey)

        // If no file object, we're clearing out the upload.
        if (!fileObj) {
          return null
        }

        // Validate the file itself
        if (fileObj.size > limit) {
          return Promise.reject([
            {
              code: "file_size_limit_exceeded",
              description: `Files must be no larger than ${displayBytes(limit)}`,
              path: [formKey],
            },
          ])
        }

        const url = uploadId ? `/upload/${uploadId}/` : "/upload/"
        const method = uploadId ? getClient().put : getClient().post
        const formData = new FormData()

        formData.append("file", fileObj)

        if (resize) {
          formData.append("resize", resize)
        }

        const {
          data: { id },
        } = await dispatch(
          method(url, {
            body: formData,
            headers: {
              "Content-Type": false,
              Accept: "application/json",
            },
          }),
        )

        return id
      }
    },
  }),
  user: new Endpoint({ base: "user" }),
  webhookSubscription: new Endpoint({ base: "webhook_subscription" }),
}
