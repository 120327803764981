import posthog from "posthog-js"
import path from "ramda/src/path"
import startsWith from "ramda/src/startsWith"
import trim from "ramda/src/trim"
import is from "ramda/src/is"
import { parse } from "qs"
import { createSelector } from "reselect"
import { updateIn } from "hurdak/src/core"
import storage from "hurdak/src/storage"
import gtm from "utils/gtm"
import { guessTimezone } from "utils/dt"
import { compileBindle, asyncBindle } from "modules/bindles"
import { formBindle } from "modules/forms"
import { hasPerm, PERMS } from "modules/permissions"
import { INVENTORY_TYPE } from "modules/domain"
import Api from "modules/domain/api"
import Toast from "app/toast/state"
import Core from "app/core/state"
import history from "utils/history"

const browserUpgradeModalOpen = storage.cursor("main/browserUpgradeModalOpen", true)

export const signupFormStages = ["store", "location", "policy"]

export const getSignupFormStage = (stage, direction = "next") => {
  const currentIdx = signupFormStages.indexOf(stage)
  const idxChange = direction === "next" ? 1 : -1

  return signupFormStages[currentIdx + idxChange]
}

const Bindle = compileBindle("main", {
  initialState: {
    sideNavOpen: false,
    openSideNavSection: null,
    signupFormStage: "store",
    browserUpgradeModalOpen: browserUpgradeModalOpen.get(),
  },
  loginUserForm: formBindle({
    onSubmit: Core.loginUser,
    errorConfig: [{ matchAll: true, key: "password" }],
  }),
  sendVendorOTPForm: formBindle({
    getInitialValues: () => () => {
      const { storeSlug } = parse(history.location.search.slice(1))

      return { storeSlug, initialStoreSlug: storeSlug }
    },
    onSubmit:
      ({ storeSlug, email }) =>
      async dispatch =>
        dispatch(
          Core.sendVendorOTP({
            storeSlug,
            email,
          }),
        ),
    errorConfig: [{ matchAll: true, key: "email" }],
  }),
  loginVendorForm: formBindle({
    getInitialValues: () => () => {
      const {
        storeSlug,
        email,
        passwordIsSet: _passwordIsSet,
      } = parse(history.location.search.slice(1))

      const passwordIsSet = _passwordIsSet === "true"

      return { storeSlug, email, passwordIsSet }
    },
    onSubmit:
      ({ storeSlug, email, oneTimePassword, password }) =>
      async dispatch =>
        dispatch(
          Core.loginVendor({
            storeSlug,
            email,
            oneTimePassword,
            password,
          }),
        ),
    errorConfig: [{ matchAll: true }],
  }),
  loginStoreForm: formBindle({
    getInitialValues: () => (dispatch, getState) => {
      const { email } = Bindle.requestPasswordResetForm.select.values(getState())

      return { email: email || "", password: "" }
    },
    onSubmit: values => async dispatch => {
      dispatch(Core.setAuth(await dispatch(Api.session.post(null, values))))

      // Wait for store data to load so we have email
      setTimeout(() => {
        gtm.push({ event: "login" })
      }, 5000)
    },
  }),
  requestPasswordResetForm: formBindle({
    getInitialValues: () => (dispatch, getState) => {
      const { email } = Bindle.loginStoreForm.select.values(getState())

      return { email: email || "" }
    },
    onSubmit:
      ({ email }) =>
      async dispatch => {
        await dispatch(Api.store.post("password/reset/request", { email }))

        dispatch(
          Toast.push({
            type: "storePasswordReset",
            message: `We've sent an email to ${email} with instructions for resetting your password.`,
          }),
        )
      },
  }),
  resetPasswordForm: formBindle({
    getInitialValues: () => () => {
      const { email, code } = parse(history.location.search.slice(1))

      if (!email || !code) {
        history.replace("/")
      }

      return { email, code, password: "" }
    },
    onSubmit:
      ({ email, code, password }) =>
      async dispatch => {
        try {
          await dispatch(Api.store.post("password/reset", { code, password }))
        } catch (errors) {
          if (path([0, "key"], errors) === "code") {
            dispatch(Toast.push({ type: "passwordResetFailure", theme: "failure" }))
          }

          throw errors
        }

        // We know they know their email and password, log 'em in
        dispatch(Core.setAuth(await dispatch(Api.session.post(null, { email, password }))))

        history.push("/")

        dispatch(
          Toast.push({
            message: `Your password has been reset successfully!`,
          }),
        )
      },
  }),
  signupForm: formBindle({
    errorConfig: [
      {
        matchPath: "acceptedPrivacy",
        message: "Please accept our Privacy Policy before continuing.",
      },
      {
        matchPath: "acceptedTerms",
        message: "Please accept our Terms of Service before continuing.",
      },
      { matchAll: true },
    ],
    getInitialValues: () => dispatch => {
      // These come from the marketing site or from Shopify
      // Gotta default email to a string because we try to trim it
      const {
        storeName,
        email = "",
        distro = "full",
        referral,
        coupon,
        next = "/",
      } = parse(window.location.search.replace("?", ""))

      return {
        next,
        email,
        coupon,
        // Force full version if coming from Shopify or demo
        distro:
          referral === "shopify" ||
          referral === "demo" ||
          posthog.getFeatureFlag("hide-free-version") === "test"
            ? "full"
            : distro,
        referral,
        password: "",
        country: "US",
        name: storeName,
        timezone: guessTimezone(),
        inventoryType: INVENTORY_TYPE.CONSIGNMENT,
      }
    },
    onSubmit:
      ({ next, ...values }) =>
      async (dispatch, getState) => {
        const stage = Bindle.select.signupFormStage(getState())
        const nextStage = getSignupFormStage(stage, "next")

        // If they submit prematurely, just advance them to the next stage
        if (nextStage) {
          return dispatch(Bindle.advanceSignupForm("next"))
        }

        if (!values.acceptedPrivacy) {
          return Promise.reject([{ code: "schema_enum", path: ["acceptedPrivacy"] }])
        }

        if (!values.acceptedTerms) {
          return Promise.reject([{ code: "schema_enum", path: ["acceptedTerms"] }])
        }

        let data
        try {
          // Don't use `create`, it depends on model, which we don't have yet
          data = await dispatch(Api.store.post(null, updateIn("email", trim, values)))
        } catch (e) {
          if (is(Array, e) && e[0].path) {
            const [field] = e[0].path

            // Navigate to the part of the form where there's an error
            if (["username", "phone", "email", "name", "password"].includes(field)) {
              dispatch(Bindle.setState({ signupFormStage: "store" }))
            } else if (["country", "timezone"].includes(field)) {
              dispatch(Bindle.setState({ signupFormStage: "location" }))
            }
          } else {
            dispatch(Toast.push({ type: "failure" }))
          }

          throw e
        }

        posthog.capture("store_created", { store_id: data.id })

        dispatch(Core.setAuth({ sessionId: data.sessionId }))

        // Wait for store data to load so we have email
        setTimeout(() => {
          gtm.push({ event: "sign_up" })
        }, 5000)
      },
  }),
  unsubscribe: asyncBindle({
    initialValue: { value: false, loading: true },
    load: ({ email }) => Api.account.post("unsubscribe", { email }),
  }),
  createSelectors: ({ select }) => {
    const pinnedSection = () => {
      const { pathname } = window.location

      if (startsWith("/settings", pathname) && hasPerm(PERMS.SETTINGS)) {
        return "/settings"
      }
    }

    const openSection = createSelector(
      select.openSideNavSection,
      pinnedSection,
      (open, pinned) => open || pinned,
    )

    return { pinnedSection, openSection }
  },
  createActions: ({ select, actions, signupForm, loginVendorForm }) => ({
    requestVendorPasswordReset: () => async (dispatch, getState) => {
      const { storeSlug, email } = loginVendorForm.select.values(getState())
      await dispatch(
        Core.sendVendorOTP({
          storeSlug,
          email,
          isReset: true,
        }),
      )

      history.push(
        `/login/vendor/confirm?storeSlug=${storeSlug}&email=${email}&passwordIsSet=false`,
      )
      window.location.reload()
    },
    closeSideNav: () => actions.setState({ sideNavOpen: false, openSideNavSection: null }),
    advanceSignupForm: direction => (dispatch, getState) => {
      const stage = select.signupFormStage(getState())
      const newStage = getSignupFormStage(stage, direction)
      const advance = () => dispatch(actions.setState({ signupFormStage: newStage }))
      const err = (k, m) => dispatch(signupForm.failSubmission({ errors: { [k]: m } }))
      const values = signupForm.select.values(getState())

      if (direction === "prev") {
        advance()
      } else if (stage === "store" && !values.name) {
        err("name", "Please provide a name for your store.")
      } else if (stage === "store" && !(values.email || "").match(/.+@.+\..+/)) {
        err("email", "Please provide a valid email address.")
      } else if (stage === "store" && !values.username) {
        err("username", "Please provide a user name.")
      } else if (stage === "store" && (values.password || "").length < 12) {
        err("password", "Your password must be at least 12 characters long.")
      } else {
        advance()
      }
    },
  }),
})

export default Bindle
