import React from "react"
import { connect } from "react-redux"
import { BoundComponent } from "utils/react"
import State from "app/toast/state"
import Toast from "app/toast/Toast"

const enhanceToastContainer = connect(
  state => ({
    toast: State.select.messages(state),
  }),
  {
    pop: State.pop,
  },
)

export default enhanceToastContainer(
  class ToastContainer extends BoundComponent {
    componentDidUpdate() {
      const root = document.getElementById("root")

      root.className = this.props.toast.length ? "toast-on" : ""
    }
    render() {
      const { toast, pop } = this.props

      return (
        <div className="toast-container mb-12 md:mb-0 z-toast">
          {toast.map(x => (
            <Toast key={x.id} pop={pop} {...x} />
          ))}
        </div>
      )
    }
  },
)
