import { applyMiddleware } from "redux"
import { prop, filter, identity } from "ramda"
import withThunks from "redux-thunk"
import { dtCtx } from "utils/dt"
import { loggerMiddleware } from "modules/logging/middleware"
import { autoLogoutMiddleware, impersonateMiddleware } from "modules/auth"
import { intercomMiddleware } from "modules/intercom"
import { syncInstancesMiddleware } from "modules/sync-instances"
import Db from "modules/db/state"
import Core from "app/core/state"
import { coreHackMiddleware } from "app/core/middleware"
import Toast from "app/toast/state"
import { CLEAR_ROOT_STATE } from "app/core/constants"

const withLogging = loggerMiddleware({
  notifyUser: params => Toast.push({ type: "failure", duration: 30000, ...params }),
  selectMeta: state => ({ store: Core.select.store(state), user: Core.select.user(state) }),
})

const withIntercom = intercomMiddleware({
  appId: process.env.INTERCOM_APP_ID,
  source: "consigncloud-client",
  syncOn: [Core.actionConstants.setSynced, CLEAR_ROOT_STATE],
  selectPayload: state => {
    // Don't mark chats as read if we're impersonating
    if (Core.select.impersonating(state)) {
      return {}
    }

    const billing = Core.select.billing(state)
    const whoami = Core.select.whoami(state)
    const user = Core.select.user(state)

    const intercomData = {}

    if (whoami && whoami.store) {
      // Chat should be anonymous on the demo account
      if (whoami.store.id === "29a36875-6918-4a79-a970-c5ca855deb73") {
        return {}
      }

      intercomData.company = {
        id: whoami.store.id,
        name: whoami.store.name,
        email: whoami.store.email,
        phone: whoami.store.phone,
        createdAt: whoami.store.created,
        canceled: whoami.store.canceled,
        customer: whoami.store.stripeId,
        active: whoami.store.active,
        freeModules: whoami.store.freeModules,
        distro: whoami.store.distro,
        schema: whoami.store.schema,
        status: prop("status", billing),
        hasRegister: whoami.store.modules.includes("register"),
        hasPortal: whoami.store.modules.includes("portal"),
        hasShopify: whoami.store.modules.includes("shopify"),
        hasUsers: whoami.store.modules.includes("users"),
        hasLocations: whoami.store.modules.includes("locations"),
        hasEmails: whoami.store.modules.includes("emails"),
        hasReports: whoami.store.modules.includes("reports"),
        hasNearst: whoami.store.modules.includes("nearst"),
        hasEmergepay: whoami.store.emergepayConfig.length > 0,
        checkbookLinked: whoami.store.checkbookLinked,
        portalSubdomain: whoami.store.portalSubdomain,
      }
    }

    if (user) {
      Object.assign(intercomData, {
        name: user.username,
        email: user.email,
        role: user.role,
        isOwner: user.isOwner,
        isVendor: user.isVendor,
        userId: user.id,
        userHash: user.intercomHash,
      })
    }

    return intercomData
  },
})

const withMultipleTabs = syncInstancesMiddleware({
  actionTypes: [Core.actionConstants.setSynced, Db.actionConstants.setState, CLEAR_ROOT_STATE],
})

const withDtSynchronization = store => {
  Object.assign(dtCtx, {
    getTimezone: () => Core.select.timezone(store.getState()),
    getLocale: () => Core.select.locale(store.getState()),
  })

  return identity
}

export default applyMiddleware(
  ...filter(identity, [
    withLogging,
    withThunks,
    coreHackMiddleware,
    autoLogoutMiddleware,
    impersonateMiddleware,
    withIntercom,
    withMultipleTabs,
    withDtSynchronization,
  ]),
)
