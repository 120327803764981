import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { compose } from "ramda"
import { parse } from "qs"
import { Modal } from "partials/Modal"
import { CardTop, CardBottom, CardBottomFooter } from "partials/Card"
import { BoundComponent } from "utils/react"
import { StringInput } from "modules/inputs/StringInput"
import Api from "modules/domain/api"
import Core from "app/core/state"
import State from "app/main/state"

const { Form, Field, SubmitButton } = State.sendVendorOTPForm.components

const enhance = compose(
  withRouter,
  connect(
    state => ({
      initialStoreSlug: State.sendVendorOTPForm.select.values(state).initialStoreSlug,
    }),
    {
      sendPasswordReset: () => Api.user.post("reset_pin"),
      sendVendorOTP: Core.sendVendorOTP,
    },
  ),
)

export default enhance(
  class SendVendorOTP extends BoundComponent {
    state = {
      passwordResetSent: false,
    }
    componentDidMount() {
      const {
        location: { search },
      } = this.props
      const { one_time_access_key: key } = parse(search.slice(1))

      if (key) {
        this.props.sendVendorOTP({ oneTimeAccessKey: key })
      }
    }
    async sendPasswordReset() {
      this.setState({ passwordResetSent: true })

      await this.props.sendPasswordReset()
    }
    render() {
      const { initialStoreSlug } = this.props

      return (
        <Modal isOpen size="xl">
          <Form>
            <CardTop>
              <h1>Vendor Login</h1>
            </CardTop>
            <CardBottom>
              <div className="flex flex-col gap-2">
                {!initialStoreSlug && (
                  <Field
                    autoFocus
                    name="storeSlug"
                    Input={StringInput}
                    inputProps={{ className: "w-full" }}
                    placeholder="Store slug"
                  />
                )}
                <Field
                  autoFocus
                  name="email"
                  Input={StringInput}
                  inputProps={{ className: "w-full" }}
                  placeholder="Email"
                />
              </div>
              <CardBottomFooter>
                <SubmitButton>Next</SubmitButton>
              </CardBottomFooter>
            </CardBottom>
          </Form>
        </Modal>
      )
    }
  },
)
